import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ChatServiceService } from 'src/app/services/chat-service/chat-service.service';

@Component({
  selector: 'app-brodcastlist',
  templateUrl: './brodcastlist.component.html',
  styleUrls: ['./brodcastlist.component.scss']
})
export class BrodcastlistComponent implements OnInit {
  broadCastList: any = [];
  broadcastMsg: any;

  constructor(
    private chatService: ChatServiceService,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {
    let data = {
      senderId: localStorage.getItem("_id"),
    };
    this.getCMSList(data)
  }
  getCMSList(data: any) {
    this.chatService.getBroadcastChatData(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.broadCastList = res.data
        // this.totaldocs = res.data.totalDocs;
        // this.page = res.data.page
        this.toastr.success(res.message, "Success")
      } else {
        this.toastr.error(res.message)
      }
    })
  }
  userFor(data: any) {
    console.log("data", data)
    if (data.length > 1) {
      return `Everyone`
    } else {
      if (data[0] == 2) {
        return `Customer`
      } else {
        return 'Caregiver'
      }
    }
  }
  getCaregiverStatus(data: any) {
    console.log("data", data);
    this.broadcastMsg = data.text
    // this.caregiverName = data.firstName + " " + data.lastName

  }
  closeModal(){
    let element: HTMLElement = document.getElementById('closeBroadcastModal') as HTMLElement;
    element.click();
  }
}
