import { getLocaleFirstDayOfWeek } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { AdminServiceService } from 'src/app/services/admin-service/admin-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.scss']
})
export class EditServiceComponent implements OnInit {
  _id: any = '';
  serviceAdd!: FormGroup | any;
  submitted: boolean = false;
  fileData: any = false;
  default: boolean = false;
  isLocalCaregiver: boolean = false;
  image: any = '';
  constructor(private actRoute: ActivatedRoute,private fb: FormBuilder, private AdminServiceService: AdminServiceService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {

    this.serviceAdd = this.fb.group({
      name: [''],
      description: [''],
      productId:['']
    })

    this.servicegets();
  }

 
  get f() {
    return this.serviceAdd.controls;
  }

  servicegets() {
    this._id = this.actRoute.snapshot.paramMap.get('id');
    this.AdminServiceService.serviceget(this._id).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        console.log(res.data, "data.data");

        this.serviceAdd.patchValue({
          name: res.data.name,
          description: res.data.description,
          productId:res.data.stripeProductId
        })
      
    }

  })
}
onSubmit(){
  this.submitted = true;

  if (this.serviceAdd.invalid) {
    return
  } else {


    const dataObject={
      productId:this.f.productId.value,
      name:this.f.name.value,
      description: this.f.description.value,
      active: true
    }

    console.log(dataObject, "data object >>>>");

    
    this.AdminServiceService.serviceEdit(dataObject).subscribe((res: any) => {

      if (res.messageID == 200 ) {
        this.toastr.success(res.message, res.status)
        this.servicegets();
      
      } else {
        this.toastr.error(res.message);
      }
    })
  }


}
 
}