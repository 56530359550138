<section class="login_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="login_card">
                    <div class="login_form">
                        <ul>
                            <li class="active">Forget Password</li>
                        </ul>
                        <form [formGroup]="forgetPass" (submit)="onSubmit()">
                            <!-- [formGroup]="forgetPass" (submit)="onSubmit()" -->
                            <div class="form-group input-icon">
                                <input type="text" class="form-control" formControlName="email" placeholder="Email"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />

                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                    <div *ngIf="f.email.errors.email">
                                        Email must be a valid email address
                                    </div>
                                </div>
                                <em class="fa fa-envelope-o"></em>
                            </div>
                            <div class="form-group"></div>
                            <button class="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>