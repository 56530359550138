<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <div class="container-fluid mb-4">
        <div class="row">
            <div class="col-12 col-md-5 col-lg-3 text-start">
                <h4 class="fs-28 fw-bold">
                    Caregivers List
                </h4>
            </div>

            <!-- filter -->
            <div class="col-12 col-md-5 col-lg-3">
                <select class="form-select me-3" (change)="selectOption($event)">
                    <option value="">All</option>
                    <option value="blocked">Blocked</option>
                    <option value="unblocked">UnBlocked</option>
                </select>
            </div>

            <div class="col-12 col-md-7 col-lg-3 text-end">
                <div class="input-group">
                    <input type="text" class="form-control border-end-0 form-control-lg" (keyup)="searchUser($event)"
                        placeholder="Search" style="font-size: 13px" />
                    <span class="input-group-text bg-white border-start-0"><i class="bi bi-search curser"></i>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-3 text-start">

                <button [routerLink]="['/admin-add-caregiver']" class="btn btn-primary dark ">
                    <i class="fas fa-plus"></i>Add
                    Caregivers</button>
            </div>
        </div>
    </div>
    <div class="card border-0 shadow">
        <div class="card-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 table-responsive">
                        <table class="table">
                            <thead class="d-lg-block-thead">
                                <tr>
                                    <th>
                                        First Name
                                    </th>
                                    <th>
                                        Last Name
                                    </th>
                                    <th>
                                        Experience
                                    </th>
                                    <th>

                                        Email

                                    </th>
                                    <th>

                                        isDeleted

                                    </th>
                                    <th>
                                        Blocked
                                    </th>
                                    <th>
                                        Action
                                    </th>

                                </tr>
                            </thead>

                            <tbody>
                                <ng-container *ngIf="caregiverlist.length > 0; else nodata">

                                    <tr *ngFor="let caregiver of caregiverlist| paginate
                                    : {itemsPerPage: limit,currentPage: page,id: 'second',totalItems: totaldocs}">

                                        <td data-label="First Name">{{caregiver.firstName}}</td>
                                        <td data-label="Last Name">{{caregiver.lastName}}</td>


                                        <td data-label="Contact No.">{{caregiver.experience}} years</td>
                                        <td data-label="Email">{{caregiver.email}}</td>
                                        <td data-label="isDelete">{{caregiver.isDelete}}</td>
                                        <td data-label="Status">
                                            <!-- <div (click)="getCaregiverStatus(caregiver._id,caregiver.isBlocked)" -->
                                            <div (click)="getCaregiverStatus(caregiver)"
                                                class="form-check form-switch text-start mb-4 d-flex justify-content-end justify-content-lg-start">
                                                <input class="form-check-input" data-target="#block_confirmation"
                                                    data-toggle="modal" type="checkbox" [checked]="caregiver.isBlocked"
                                                    role="switch" id="flexSwitchCheckChecked" />
                                            </div>
                                        </td>
                                        <td data-label="Action">
                                            <p class="d-flex justify-content-end justify-content-lg-start">
                                                <button class="btn btn-primary rounded-circle btn-rounded shadow me-2"
                                                    routerLink="/admin-caregiver-edit/{{caregiver._id}}">
                                                    <i class="bi bi-pencil" title="Edit"></i>
                                                </button>
                                                <button
                                                    class="btn btn-info rounded-circle btn-rounded shadow me-2 text-white"
                                                    routerLink="/admin-caregiver-profile/{{caregiver._id}}">
                                                    <i class="bi bi-eye" title="View"></i>
                                                </button>
                                                <button  (click)="getCaregiverStatus(caregiver)" data-target="#delete"
                                                data-toggle="modal"
                                                    class="btn btn-danger rounded-circle btn-rounded shadow me-2 text-white">
                                                    <i class="bi bi-trash" title="Delete"
                                                        (click)="getCaregiverStatus(caregiver)" data-target="#delete"
                                                        data-toggle="modal"></i>
                                                </button>
                                            </p>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-template #nodata>
                                    <tr>
                                        <td colspan="6" class="text-center">No Data Found</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                        <div class="paginator" style="float: right;">
                            <pagination-controls (pageChange)="pageChange($event)" id="second"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal -->

    <!-- <div class="edit_nurshHome_modal">
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            EDIT CAREGIVER
                        </h5>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
                            <i class="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="cardBox">

                            <div class="form-group">
                                <label for="">Name</label>
                                <input type="text" class="form-control" placeholder="Enter Name">
                            </div>

                            <div class="form-group">
                                <label for="">Email</label>
                                <input type="email" class="form-control" placeholder="Enter Email">
                            </div>

                            <div class="form-group">
                                <label for="">Password</label>
                                <input type="text" class="form-control" placeholder="Enter Password">
                            </div>

                            <div class="form-group">
                                <label for="">Date Of Birth</label>
                                <input type="date" class="form-control" placeholder="Enter D.O.B">
                            </div>

                            <div class="form-group">
                                <label for="">Experience</label>
                                <input type="number" class="form-control" placeholder="Enter Experience">
                            </div>

                            <div class="form-group">
                                <label for="">Address</label>
                                <input type="text" class="form-control" placeholder="Enter Address">
                            </div>
                        </div>
                    </div>
                    <div class="pay_submit_box">
                        <button type="button" class="cancel_btn" data-dismiss="modal">
                            Cancel
                        </button>
                        <button type="button" class="save_btn">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!--Block Modal -->
    <div class="modal fade" id="block_confirmation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"><b>Confirmation</b></h5>
                    <button type="button" (click)="closeModal()" id="closeCaregiverBlockModal" class="close"
                        data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" *ngIf="isBlocked == true">
                    Are you sure you want to block the <b>{{caregiverName}}?</b>
                </div>
                <div class="modal-body" *ngIf="isBlocked == false">
                    Are you sure you want to Unblock the <b>{{caregiverName}}?</b>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="closeModal()"
                        data-dismiss="modal">No</button>
                    <button type="button" class="btn btn-primary" (click)="blockUser()">Yes</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Delete Modal -->
    <div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"><b>Confirmation</b></h5>
                    <button type="button" (click)="closeModal()" id="closeUserDeleteModal" class="close"
                        data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    Are you sure you want to Delete the <b>{{caregiverName}}?</b>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="closeModal()"
                        data-dismiss="modal">No</button>
                    <button type="button" class="btn btn-primary" (click)="deleteUser()">Yes</button>
                </div>
            </div>
        </div>
    </div>

</main>