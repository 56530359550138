import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  titlle = environment.title;
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, private router: Router) { }

  logout() {
    delete localStorage.Token;
    delete localStorage.checkoutData;
    delete localStorage.logOut;
    this.router.navigate(['/']);
  }

  isAuth() {
    try {
      if (localStorage.Token) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }

  }

  login(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/login`, data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }


  forgetPassword(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/forgetpassword`, data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  verifyOtp(data: any, token: any): Observable<any> {
    let header = new HttpHeaders().set('Authorization', token);
    return this.http.post(`${this.baseUrl}/api/v1/verifyotp`, data, { headers: header })
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  resendOtp(token: any): Observable<any> {
    let header = new HttpHeaders().set('Authorization', token);
    return this.http.post(`${this.baseUrl}/api/v1/resendotp`, {}, { headers: header })
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  changePassword(data: any, token: any): Observable<any> {
    let header = new HttpHeaders().set('Authorization', token);
    return this.http.post(`${this.baseUrl}/api/v1/resetpassword`, data, { headers: header })
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error("error", error.error, result); // log to console instead
      if (error.error.messageID == 401) {
        this.logout()
        return of(error.error)
      }
      return of(error.error);
    };
  }
}
