import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminProfileComponent } from './admin/admin-profile/admin-profile.component';
import { AdminSidebarComponent } from './admin/admin-sidebar/admin-sidebar.component';
import { AddCaregiverComponent } from './admin/caregivers/add-caregiver/add-caregiver.component';
import { CaregiverEditComponent } from './admin/caregivers/caregiver-edit/caregiver-edit.component';
import { CaregiverProfileComponent } from './admin/caregivers/caregiver-profile/caregiver-profile.component';
import { CaregiversComponent } from './admin/caregivers/caregivers.component';
import { AllChatsComponent } from './admin/chat-management/all-chats/all-chats.component';
import { ContentManagementComponent } from './admin/content-management/content-management.component';
import { ForgetPasswordComponent } from './admin/forget-password/forget-password.component';
import { LoginComponent } from './admin/login/login.component';
import { ResetPasswordComponent } from './admin/reset-password/reset-password.component';
import { SubscriberInfoComponent } from './admin/subscribers-list/subscriber-info/subscriber-info.component';
import { SubscribersListComponent } from './admin/subscribers-list/subscribers-list.component';
import { SurveyInputsComponent } from './admin/survey-inputs/survey-inputs.component';
import { UserEditComponent } from './admin/users/user-edit/user-edit.component';
import { UserProfileComponent } from './admin/users/user-profile/user-profile.component';
import { UsersComponent } from './admin/users/users.component';
import { VerifyOtpComponent } from './admin/verify-otp/verify-otp.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { PageNotFoundComponent } from './admin/page-not-found/page-not-found.component';
import { SubscriptionPlansComponent } from './admin/subscription-plans/subscription-plans.component';
import { ConsultationFeedbackComponent } from './admin/consultation-feedback/consultation-feedback.component';
import { EducationalArticleComponent } from './admin/educational-article/educational-article.component';
import { AddEducationalArticleComponent } from './admin/educational-article/add-educational-article/add-educational-article.component';
import { EditEducationalArticleComponent } from './admin/educational-article/edit-educational-article/edit-educational-article.component';
import { BroadcastMessagingComponent } from './admin/broadcast-messaging/broadcast-messaging.component';
import { ConsultationFeedbackDetailsComponent } from './admin/consultation-feedback/consultation-feedback-details/consultation-feedback-details.component';
import { EducationalVideosComponent } from './admin/educational-videos/educational-videos.component';
import { AddEducationalVideoComponent } from './admin/educational-videos/add-educational-video/add-educational-video.component';
import { DetailsEducationalVideoComponent } from './admin/educational-videos/details-educational-video/details-educational-video.component';
import { ChatTestComponent } from './admin/chat-test/chat-test/chat-test.component';
import { MobileAppFeedbackComponent } from './admin/mobile-app-feedback/mobile-app-feedback.component';
import { MobileAppFeedbackDetailsComponent } from './admin/mobile-app-feedback/mobile-app-feedback-details/mobile-app-feedback-details.component';
import { EditCmsComponent } from './admin/content-management/edit-cms/edit-cms.component';
import { FaqComponent } from './admin/faq/faq.component';
import { EditFaqComponent } from './admin/faq/edit-faq/edit-faq.component';
import { AddFaqComponent } from './admin/faq/add-faq/add-faq.component';
import { MindfullnessSessionComponent } from './admin/mindfullness-session/mindfullness-session.component';
import { AddMindfullnessSessionComponent } from './admin/mindfullness-session/add-mindfullness-session/add-mindfullness-session.component';
import { EditMindfullnessSessionComponent } from './admin/mindfullness-session/edit-mindfullness-session/edit-mindfullness-session.component';
import { AssignedCaregiverComponent } from './admin/users/assigned-caregiver/assigned-caregiver.component';
import { BrodcastlistComponent } from './admin/broadcast-messaging/brodcastlist/brodcastlist.component';
import { AdminServiceComponent } from './admin/admin-service/admin-service.component';
import { AddServiceComponent } from './admin/admin-service/add-service/add-service.component';
import { EditServiceComponent } from './admin/admin-service/edit-service/edit-service.component';
import { QuizComponent } from './admin/quiz/quiz.component';
import { AddQuizComponent } from './admin/quiz/add-quiz/add-quiz.component';
import { EditQuizComponent } from './admin/quiz/edit-quiz/edit-quiz.component';
import { StatisPagesComponent } from './admin/statis-pages/statis-pages.component';
import { AboutUsComponent } from './admin/statis-pages/about-us/about-us.component';
import { TermsConditionComponent } from './admin/statis-pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './admin/statis-pages/privacy-policy/privacy-policy.component';
import { HowWorksComponent } from './admin/statis-pages/how-works/how-works.component';
import { FindQuesAnsComponent } from './admin/statis-pages/find-ques-ans/find-ques-ans.component';
import { UrlComponent } from './admin/url/url.component';
import { EditUrlComponent } from './admin/url/edit-url/edit-url.component';
import { BabyStagesComponent } from './admin/baby-stages/baby-stages.component';
import { AddStagesComponent } from './admin/baby-stages/add-stages/add-stages.component';
import { EditStagesComponent } from './admin/baby-stages/edit-stages/edit-stages.component';
import { PolicyCaregiverComponent } from './admin/statis-pages/policy-caregiver/policy-caregiver.component';

const routes: Routes = [

  { path: "", redirectTo: "login", pathMatch: 'full' },

  {
    path: 'dashboard',
    component: AdminDashboardComponent, canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'admin-forget-password',
    component: ForgetPasswordComponent
  },
  {
    path: 'admin-verify-otp/:token',
    component: VerifyOtpComponent
  },
  {
    path: 'admin-reset-password/:token',
    component: ResetPasswordComponent
  },
  {
    path: 'admin-sidebar',
    component: AdminSidebarComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-caregiver-profile/:id',
    component: CaregiverProfileComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-profile',
    component: AdminProfileComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-caregiver-edit/:id',
    component: CaregiverEditComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-caregivers',
    component: CaregiversComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-add-caregiver',
    component: AddCaregiverComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-users',
    component: UsersComponent, canActivate: [AuthGuard]
  },
  {
    path: 'baby-stages',
    component: BabyStagesComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-user-profile/:id',
    component: UserProfileComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-user-assigned/:id',
    component:AssignedCaregiverComponent , canActivate: [AuthGuard]
  },
  {
    path: 'admin-user-edit/:id',
    component: UserEditComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-allchats',
    component: AllChatsComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-users-subscribers',
    component: SubscribersListComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-subscriber-info',
    component: SubscriberInfoComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-content-management',
    component: ContentManagementComponent, canActivate: [AuthGuard]
  },
  {
    path: 'edit-cms/:id',
    component: EditCmsComponent, canActivate: [AuthGuard]
  },
  {
    path: 'faq',
    component: FaqComponent, canActivate: [AuthGuard]
  },
  {
    path: 'edit-faq/:id',
    component: EditFaqComponent, canActivate: [AuthGuard]
  },
  {
    path: 'add-faq',
    component: AddFaqComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-surver-inputs',
    component: SurveyInputsComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-subscription-plans',
    component: SubscriptionPlansComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-consultation-feedback',
    component: ConsultationFeedbackComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-consultation-feedback-details/:id',
    component: ConsultationFeedbackDetailsComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-mobile-app-feedback-details/:id',
    component: MobileAppFeedbackDetailsComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-educational-blog',
    component: EducationalArticleComponent, canActivate: [AuthGuard]
  },
  {
    path: 'add-stages',
    component: AddStagesComponent, canActivate: [AuthGuard]
  },
  {
    path: 'edit-stages/:id',
    component: EditStagesComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-educational-videos',
    component: EducationalVideosComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-details-educational-videos/:id',
    component: DetailsEducationalVideoComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-add-educational-video',
    component: AddEducationalVideoComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-add-educational-blog',
    component: AddEducationalArticleComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-edit-educational-blog/:id',
    component: EditEducationalArticleComponent, canActivate: [AuthGuard]
  },
  {
    path: 'add-broadcast-messaging',
    component: BroadcastMessagingComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-broadcast-messaging',
    component: BrodcastlistComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-mobile-app-feedback',
    component: MobileAppFeedbackComponent, canActivate: [AuthGuard]
  },
  {
    path: 'chat-test',
    component: ChatTestComponent, canActivate: [AuthGuard]
  },
  {
    path: 'mindfullnesslist',
    component: MindfullnessSessionComponent, canActivate: [AuthGuard]
  },
  {
    path: 'add-mindfullness-session',
    component: AddMindfullnessSessionComponent, canActivate: [AuthGuard]
  },
  {
    path: 'admin-service',
    component: AdminServiceComponent, canActivate: [AuthGuard]
  },
  {
    path: 'Quiz',
    component: QuizComponent, canActivate: [AuthGuard]
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'terms-condition',
    component: TermsConditionComponent
  },
   {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'privacy-policy-caregiver',
    component: PolicyCaregiverComponent
  },
  {
    path: 'how-works',
    component: HowWorksComponent
  },
  {
    path: 'findQA',
    component: FindQuesAnsComponent, canActivate: [AuthGuard]
  },
  {
    path: 'url',
    component: UrlComponent, canActivate: [AuthGuard]
  },
  {
    path: 'edit-url/:page',
    component: EditUrlComponent, canActivate: [AuthGuard]
  },

  {
    path: 'edit-Quiz/:id',
    component: EditQuizComponent, canActivate: [AuthGuard]
  },
  {
    path: 'static-pages',
    component: StatisPagesComponent, canActivate: [AuthGuard]
  },
  {
    path: 'add-Quiz',
    component: AddQuizComponent, canActivate: [AuthGuard]
  },
  {
    path: 'edit-mindfullness-session/:id',
    component: EditMindfullnessSessionComponent, canActivate: [AuthGuard]
  },
  {
    path: 'edit-service/:id',
    component: EditServiceComponent, canActivate: [AuthGuard]
  },
  {
    path: 'add-service',
    component: AddServiceComponent, canActivate: [AuthGuard]
  },
  { path: "pageNotFound", component: PageNotFoundComponent },
  { path: "**", redirectTo: "/pageNotFound", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],

})
export class AppRoutingModule { }
