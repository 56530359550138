import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BabyStagesService } from 'src/app/services/baby-stages/baby-stages.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-add-stages',
  templateUrl: './add-stages.component.html',
  styleUrls: ['./add-stages.component.scss']
})
export class AddStagesComponent implements OnInit {
  addStages!: FormGroup | any;
  title = 'angular';
  public Editor = ClassicEditor;
  fileData: any = false;
  default: boolean = false;
  image: any = '';
  constructor(
      private fb: FormBuilder,
      private BabyStagesService : BabyStagesService,
      private toastr: ToastrService,
      private router: Router
  ) { }

  ngOnInit(): void {
      this.addStages = this.fb.group({
          blogTitle: [''],
          blogContent: [''],
          image: [''],
      })
  }
  fileProgress(fileInput: any) {
      this.fileData = <File>fileInput.target.files[0];
      console.log(this.fileData, "image_path");

      this.addStages.get('image').setValue(fileInput.target.files[0]);
      this.default = true;
      this.preview();
  }

  preview() {
      // Show preview
      var mimeType = this.fileData.type;

      if (mimeType.match(/image\/*/) == null) {
          return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = (_event) => {
          this.image = reader.result;
          console.log(_event, "eventtt");

      };
  }
  removeImage() {
      this.image = '';
      this.f.image.value = this.image;
  }

  get f() {
      return this.addStages.controls;
  }

  onSubmit() {
      const formData = new FormData();
      formData.append('image', this.f.image.value);
      formData.append('blogTitle', this.f.blogTitle.value);
      formData.append('blogContent', this.f.blogContent.value);

      this.addStages.addEducationalBlog(formData).subscribe((res: any) => {
          if (res.messageID == 200 && res.data) {
              this.toastr.success(res.message, "Success");
              this.router.navigateByUrl("/admin-educational-blog");
          } else {
              this.toastr.error(res.message, "Error")
          }
      })
  }


}

