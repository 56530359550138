<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3><b>Add Caregiver</b> </h3>
                        </div>
                    </div>
                    <div class="card shadow border-0 mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-lg-3 text-center mt-3">
                                    <div class="profile_pic">
                                        <img *ngIf="!image" src="../../../../assets/images/user_preg.jpeg" width="170"
                                            height="170" class="rounded m-auto " />
                                        <img *ngIf="image" [src]="image" width="170" height="170"
                                            class="rounded m-auto  " />
                                    </div>

                                    <div class="m-width-50">
                                        <div class="btn upload_btn btn-black btn-block form-group text-white"><i
                                                class="fa fa-upload" aria-hidden="true"></i>
                                            <input class="form-control form-control-lg" (change)="fileProgress($event)"
                                                id="formFileLg" type="file" />
                                            Upload
                                        </div>

                                        <!-- <button class="btn btn-white btn-block" (click)="removeImage()"
                                            data-toggle="modal" data-target="#exampleModal">
                                            <i class="fa fa-times" aria-hidden="true"></i> Remove
                                        </button> -->
                                    </div>

                                </div>

                                <div class="col-12 col-md-9">
                                    <div class="container-fluid">
                                        <form [formGroup]="caregiverSignup" (submit)="onSubmit()">
                                            <div class="row">

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>First Name</span>
                                                    <input type="text" class="form-control" formControlName="firstName"
                                                        placeholder="First Name">
                                                </div>

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>Last Name</span>
                                                    <input type="text" class="form-control" formControlName="lastName"
                                                        placeholder="Last Name">
                                                </div>

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>Date Of Birth</span>
                                                    <input type="date" class="form-control" formControlName="dob"
                                                        placeholder="D.O.B">
                                                </div>

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>Email</span>
                                                    <input type="email" class="form-control" formControlName="email"
                                                        placeholder="Email" required
                                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                        <div *ngIf="f.email.errors.required">
                                                            Email is required
                                                        </div>
                                                        <div *ngIf="f.email.errors">
                                                            Email must be a valid email address
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group input-icon" class="col-12 col-lg-6 mb-3">
                                                    <span>Password</span>
                                                    <input type="text" class="form-control" formControlName="password"
                                                        placeholder="Password"
                                                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                                    <div *ngIf="submitted && f.password.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.password.errors.required">New Password is
                                                            required</div>
                                                        <div *ngIf="f.password.errors?.['pattern']">
                                                            Password must contain at least one number, one uppercase , 1 special character (@,#,!) and
                                                            a
                                                            lowercase letter and at least 8 characters<br />Password
                                                            cannot
                                                            contain whitespace
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group input-icon" class="col-12 col-lg-6 mb-3">
                                                    <span>Confirm Password</span>
                                                    <input type="text" class="form-control"
                                                        formControlName="confirmPasswword"
                                                        placeholder="Confirm Password"
                                                        [ngClass]="{ 'is-invalid': submitted && f.confirmPasswword.errors }" />
                                                    <div *ngIf="submitted && f.confirmPasswword.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.confirmPasswword.errors.required">Confirm Password
                                                            is required</div>
                                                    </div>
                                                    <div *ngIf="f.confirmPasswword.errors?.['confirmedValidator']"
                                                        style="color: red;">
                                                        Passwords did not match
                                                    </div>
                                                </div>

                                                <div class="col-12 col-lg-4 mb-3">
                                                    <span>Contact Number </span>
                                                    <input type="number" class="form-control" formControlName="phone"
                                                        placeholder="Mobile">
                                                    <div *ngIf="submitted && f.phone.errors"
                                                        style="color: red;">
                                                        Invalid phone number
                                                    </div>
                                                    <div *ngIf="submitted && f.phone.errors && (''+f.phone.value).length < 10"
                                                        style="color: red;">
                                                        Phone number should be minimum 10 digits
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-4 mb-3">
                                                    <span>Experience</span>
                                                    <input type="number" class="form-control"
                                                        formControlName="experience" placeholder="Experience">
                                                </div>
                                                <div class="col-12 col-lg-4 mb-3">
                                                    <span>Designation</span>
                                                    <!-- <input type="number" class="form-control"
                                                        formControlName="experience" placeholder="Experience"> -->
                                                        <select class="form-control" formControlName="designation" >
                                                            <option value="Nurse">Nurse</option>
                                                            <option value="Doula">Doula</option>
                                                            
                                                            <option value="Midwife">Midwife</option>
                                                        </select>
                                                </div>
                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>City</span>
                                                    <input type="text" class="form-control" formControlName="city"
                                                        placeholder="City">
                                                </div>
                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>State</span>
                                                    <input type="text" class="form-control" formControlName="state"
                                                        placeholder="State">
                                                </div>
                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Bio</span>
                                                    <input type="text" class="form-control" formControlName="description"
                                                        placeholder="Bio of caregiver">
                                                </div>
                                                <div class="col-12 col-lg-6 mb-3">
                                                    <input type="checkbox" formControlName="isLocalCaregiver"
                                                        (change)="localCaregiverAction(isLocalCaregiver)">
                                                    <label for="vehicle1">Local Caregiver</label>
                                                </div>
                                            </div>
                                            <button class="save_btnn">
                                                ADD
                                            </button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>