// import { Component, OnInit } from '@angular/core';
// import { Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
// import { EducationalArticleService } from 'src/app/services/educational-article/educational-article.service';


// @Component({
//     selector: 'app-educational-article',
//     templateUrl: './educational-article.component.html',
//     styleUrls: ['./educational-article.component.scss']
// })
// export class EducationalArticleComponent implements OnInit {
//     educationalBlogList: any = [];
//     page: number = 1;
//     limit: number = 5;
//     blogTitle: string = "";
//     totaldocs: any = 0;
//     filter: any = '';
//     articleId: any;
//     constructor(
//         private educationalBlogService: EducationalArticleService,
//         private toastr: ToastrService,
//         private router: Router
//     ) { }

//     ngOnInit(): void {
//         let data = {
//             filter: this.filter,
//             page: this.page,
//             limit: this.limit,
//         };
//         this.getEducationalBlogList(data)
//     }

//     getEducationalBlogList(data: any) {
//         this.educationalBlogService.educationalBlogList(data).subscribe((res: any) => {
//             if (res.messageID == 200 && res.data) {
//                 this.educationalBlogList = res.data.docs
//                 this.totaldocs = res.data.totalDocs;
//                 this.page = res.data.page
//                 this.toastr.success(res.message, "Success")
//             } else {
//                 this.toastr.error(res.message)
//             }
//         })
//     }

//     searchBlog(event: any) {
//         let data = {
//             page: this.page,
//             filter: event.target.value,
//             limit: this.limit
//         };
//         this.getEducationalBlogList(data)

//     }

//     formatDate(isoDateString:any) {
//         const date = new Date(isoDateString);
//         const month = date.getMonth() + 1;
//         const day = date.getDate();
//         const year = date.getFullYear();
//         return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
//       }

      
//     pageChange(page: any) {
//         let data = {
//             filter: this.filter,
//             page: page,
//             limit: this.limit
//         };
//         this.getEducationalBlogList(data)
//     }
//     getBlogStatus(data: any) {
//         this.articleId = data._id;
//     }
//     closeModal() {
//         let data = {
//             filter: this.filter,
//             page: this.page,
//             limit: this.limit
//         };

//         this.getEducationalBlogList(data);
//     }
//     deleteBlog() {
//         let dataa = {
//             filter: this.filter,
//             page: this.page,
//             limit: this.limit
//         };
//         let data = {
//             _id: this.articleId,
//             isDelete: true
//         };

//         this.educationalBlogService.deleteEducationalBlog(data).subscribe((res: any) => {
//             if (res.messageID == 200) {
//                 this.toastr.success(res.message, res.status)
//                 let element: HTMLElement = document.getElementById('closeUserDeleteModal') as HTMLElement;
//                 element.click();
//                 this.getEducationalBlogList(dataa);
//             } else {
//                 this.toastr.error(res.message)
//             }
//         })
//     }
// }






import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EducationalArticleService } from 'src/app/services/educational-article/educational-article.service';

@Component({
  selector: 'app-educational-article',
  templateUrl: './educational-article.component.html',
  styleUrls: ['./educational-article.component.scss']
})
export class EducationalArticleComponent implements OnInit {
  educationalBlogList: any = [];
  page: number = 1;
  limit: number = 5;
  blogTitle: string = "";
  totaldocs: any = 0;
  filter: any = '';
  articleId: any;

  constructor(
    private educationalBlogService: EducationalArticleService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    let data = {
      filter: this.filter,
      page: this.page,
      limit: this.limit,
    };
    this.getEducationalBlogList(data);
  }

  getEducationalBlogList(data: any) {
    this.educationalBlogService.educationalBlogList(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.educationalBlogList = res.data.docs.map((blog: any) => {
          // Extract the date part (YYYY-MM-DD) without the time
          const datePart = blog.createdAt.split('T')[0];
          
          // Update the blog item's createdAt field with the date part
          blog.createdAt = datePart;

          return blog;
        });

        this.totaldocs = res.data.totalDocs;
        this.page = res.data.page;
        this.toastr.success(res.message, "Success");
      } else {
        this.toastr.error(res.message)
      }
    });
  }

  searchBlog(event: any) {
    let data = {
      page: this.page,
      filter: event.target.value,
      limit: this.limit
    };
    this.getEducationalBlogList(data);
  }

  pageChange(page: any) {
    let data = {
      filter: this.filter,
      page: page,
      limit: this.limit
    };
    this.getEducationalBlogList(data);
  }

  getBlogStatus(data: any) {
    this.articleId = data._id;
  }

  closeModal() {
    let data = {
      filter: this.filter,
      page: this.page,
      limit: this.limit
    };
    this.getEducationalBlogList(data);
  }

  deleteBlog() {
    console.log("deleteBlog method called");
    let dataa = {
      filter: this.filter,
      page: this.page,
      limit: this.limit
    };
    let data = {
      blogId: this.articleId, 
      isDelete: true
    };

    this.educationalBlogService.deleteEducationalBlog(data).subscribe((res: any) => {
      console.log("Delete Blog Response:", res); 
      if (res.messageID == 200) {
        this.toastr.success(res.message, res.status);
        let element: HTMLElement = document.getElementById('closeUserDeleteModal') as HTMLElement;
        element.click();
        this.getEducationalBlogList(dataa);
      } else {
        this.toastr.error(res.message);
      }
    });
  }
}