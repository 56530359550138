<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3><b>Broadcast Message</b> </h3>
                        </div>
                    </div>
                    <div class="card shadow border-0 mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-lg-3 text-center mt-3">
                                </div>

                                <div class="col-12 col-md-9">
                                    <div class="container-fluid">
                                        <!-- <form> -->
                                        <form [formGroup]="broadcastMessageFormGroup" (submit)="onSubmit()">
                                            <div class="row">
                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Select User Type</span>
                                                    <select class="form-select me-3" (change)="selectOption($event)">
                                                        <option value="1">To Everyone</option>
                                                        <option value="2">To All Customer</option>
                                                        <option value="3">To All Caregiver</option>
                                                    </select>
                                                </div>

                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Broadcast Message</span>
                                                    <textarea formControlName="broadcastMessage" class="form-control"
                                                        placeholder="Write broadcast message here" rows="10"
                                                        cols="30"></textarea>
                                                </div>
                                            </div>
                                            <button class="btn btn-primary dark ">
                                                <i class="fa-solid fa-paper-plane"></i> Send</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>