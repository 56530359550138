<app-header></app-header>
<section class="login_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="login_card">
                    <div class="login_form">
                        <h3 style="text-align: center;padding-top:10px">Login</h3>
                        <form [formGroup]="loginForm" (submit)="login()">
                            <div class="sign_form_area">
                                <div class="form-group">
                                    <div class="profile_image">
                                    </div>
                                </div>
                                <div class="form-group input-icon">
                                    <input type="text" class="form-control" formControlName="email" placeholder="Email"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />

                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">
                                            Email is required
                                        </div>
                                        <div *ngIf="f.email.errors.email">
                                            Email must be a valid email address
                                        </div>
                                    </div>

                                    <img src="../../assets/img/envelope-green.png" alt="" class="input-image">
                                </div>

                                <div class="form-group input-icon">
                                    <input type="password" class="form-control" placeholder="Password"
                                        formControlName="password"
                                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">
                                            Password is required
                                        </div>
                                    </div>
                                    <img src="../../assets/img/padlock-green.png" alt="" class="input-image">
                                </div>

                                <div class="form-group">
                                    <div class="remember">
                                        <label class="container_c">
                                        </label>
                                        <a [routerLink]="['/admin-forget-password']" class="text-blue forgot-pwd">Forgot
                                            Password?</a>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <button class="btn btn-primary">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>