<nav class="navbar navbar-light bg-light headersCustomize">
    <!-- <div class="title">
        Simply Birthed
    </div> -->
    <div class="d-flex align-items-center">

        <img class="mx-2" style="width: 40px" src="../../../assets/images/simplyBirthed_logo.jpg" alt="logo">
        <h3 style="font-size: 25px;" [routerLink]="['']">SimplyBirthed</h3>


    </div>

    <form class="form-inline">
        <button type="button" id="btn" class="btn btn-outline-dark" data-mdb-ripple-color="dark"
            style="margin-right: 10px;" routerLink="/admin-profile"><i class="fa fa-user"
                aria-hidden="true"></i></button>
        <button type="button" id="btn" class="btn btn-outline-dark" data-mdb-ripple-color="dark" (click)="logout();"><i
                class="fa fa-sign-out" aria-hidden="true"></i></button>
    </form>
</nav>