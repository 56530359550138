<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3>User <strong class="fs-28 me-3"> <a class="fw-bold text-dark back-btn"
                                        routerLink="/admin-users"><i class="bi bi-chevron-left"></i></a>
                                    {{userDetail.firstName}} {{userDetail.lastName}}</strong> </h3>
                        </div>
                    </div>
                    <div class="card shadow border-0 mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-lg-3 text-center mt-3 profile_pic"> <img
                                        src="../../../../assets/images/user_preg.jpeg" width="170" height="170"
                                        class="rounded m-auto" /> </div>
                                <div class="col-12 col-md-9">
                                    <div class="container-fluid">
                                        <div class="row">

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>First Name</span>
                                              
                                                <input type="text" class="form-control" value="{{userDetail.docs.firstName}}"
                                                    placeholder="First Name" readonly>
                                            </div>

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Last Name</span>
                                                <input type="text" class="form-control" value="{{userDetail.docs.lastName}}"
                                                    placeholder="Last Name" readonly>
                                            </div>

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Designation</span>
                                                <input type="text" class="form-control" value="{{userDetail.docs.designation}}" placeholder="designation"
                                                    readonly>
                                            </div>

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Email</span>
                                                <input type="text" class="form-control" value="{{userDetail.docs.email}}"
                                                    placeholder="Email" readonly>
                                            </div>

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Contact Number </span>
                                                <input type="number" class="form-control" value="{{userDetail.docs.phone}}"
                                                    placeholder="Mobile" readonly>
                                            </div>
                                            <!-- <div class="col-12 col-lg-6 mb-3">
                                                <span>Live Births</span>
                                                <input type="text" class="form-control" value=""
                                                    placeholder="Live Births" readonly>
                                            </div>
                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Child Name</span>
                                                <input type="text" class="form-control" value=""
                                                    placeholder="Child Name" readonly>
                                            </div>
                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Child Sex</span>
                                                <input type="text" class="form-control" value="" placeholder="Child Sex"
                                                    readonly>
                                            </div>

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Child Losses</span>
                                                <input type="text" class="form-control" value=""
                                                    placeholder="Child Losses" readonly>
                                            </div>
                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Birth Type</span>
                                                <input type="text" class="form-control" value=""
                                                    placeholder="Birth Type" readonly>
                                            </div>
                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Estimated Due Date</span>
                                                <input type="text" value="" class="form-control"
                                                    placeholder="Estimated Due Date" readonly>
                                            </div>
                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Previous pregnancies</span>
                                                <input type="text" value="" class="form-control"
                                                    placeholder="Previous Pregnancies" readonly>
                                            </div>  

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Pregnancy Complications</span>
                                                <input type="text" value="" class="form-control"
                                                    placeholder="Pregnancy Complications" readonly>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<!-- <main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3>User <strong class="fs-28 me-3"> <a class="fw-bold text-dark back-btn"
                                        routerLink="/admin-user-management"><i class="bi bi-chevron-left"></i></a>
                                    Selena</strong> </h3>
                        </div>
                    </div>
                    <div class="card shadow border-0 mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-lg-3 text-center mt-3"> <img
                                        src="../../../../assets/images/user_preg.jpeg" width="170" height="170"
                                        class="rounded m-auto" /> </div>
                                <div class="col-12 col-md-9">
                                    <div class="container-fluid">
                                        <div class="row">

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>First Name</span>
                                                <input type="text" class="form-control" placeholder="First Name">
                                            </div>

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Last Name</span>
                                                <input type="text" class="form-control" placeholder="Last Name">
                                            </div>

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Date Of Birth</span>
                                                <input type="text" class="form-control" placeholder="D.O.B">
                                            </div>

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Email</span>
                                                <input type="text" class="form-control" placeholder="Email">
                                            </div>

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Contact Number </span>
                                                <input type="number" class="form-control" placeholder="Mobile">
                                            </div>
                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Child Name</span>
                                                <input type="text" class="form-control" placeholder="Child Name">
                                            </div>
                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Child Sex</span>
                                                <input type="text" class="form-control" placeholder="Child Sex">
                                            </div>
                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Live Births</span>
                                                <input type="text" class="form-control" placeholder="Live Births">
                                            </div>

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Child Losses</span>
                                                <input type="text" class="form-control" placeholder="Child Losses">
                                            </div>
                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Birth Type</span>
                                                <input type="text" class="form-control" placeholder="Birth Type">
                                            </div>
                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Estimated Due Date</span>
                                                <input type="text" class="form-control"
                                                    placeholder="Estimated Due Date">
                                            </div>
                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Previous pregnancies</span>
                                                <input type="text" class="form-control"
                                                    placeholder="Previous Pregnancies">
                                            </div>

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Pregnancy Complications</span>
                                                <input type="text" class="form-control"
                                                    placeholder="Pregnancy Complications">
                                            </div>
                                            <button class="btn btn-primary">Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main> -->