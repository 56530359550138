<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3><b>Add Mindfullness Session</b> </h3>
                        </div>
                    </div>
                    <div class="card shadow border-0 mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-lg-3 text-center mt-3">
                                    <div class="profile_pic">
                                        <img *ngIf="!image" src="../../../../assets/images/user_preg.jpeg" width="170"
                                            height="170" class="rounded m-auto " />
                                        <img *ngIf="image" [src]="image" width="170" height="170"
                                            class="rounded m-auto  " />
                                    </div>

                                    <div class="m-width-50">
                                        <div class="btn upload_btn btn-black btn-block form-group text-white"><i
                                                class="fa fa-upload" aria-hidden="true"></i>
                                            <input class="form-control form-control-lg" (change)="fileProgress($event)"
                                                id="formFileLg" type="file" />
                                            Upload
                                        </div>

                                        <!-- <button class="btn btn-white btn-block" (click)="removeImage()"
                                            data-toggle="modal" data-target="#exampleModal">
                                            <i class="fa fa-times" aria-hidden="true"></i> Remove
                                        </button> -->
                                    </div>

                                </div>

                                <div class="col-12 col-md-9">
                                    <div class="container-fluid">
                                        <form [formGroup]="addMindfullFormGroup" (submit)="onSubmit()">
                                            <div class="row">
                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Title</span>
                                                    <input type="text" class="form-control" formControlName="title"
                                                        placeholder="Enter title">
                                                </div>
                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Link</span>
                                                    <input type="url" class="form-control" formControlName="link"
                                                        placeholder="https://example.com">
                                                </div>

                                                <div class="col-12 col-lg-4 mb-3">
                                                    <span>Start Date</span>
                                                    <input type="text" class="form-control" formControlName="startDate"
                                                        placeholder="YYYY-MM-DD">
                                                </div>

                                                <div class="col-12 col-lg-4 mb-3">
                                                    <span>Start Time</span>
                                                    <input type="text" class="form-control" formControlName="startTime"
                                                        placeholder="HH:MM">
                                                </div>

                                                <div class="col-12 col-lg-4 mb-3">
                                                    <span>Duration (in minutes)</span>
                                                    <input type="text" class="form-control" formControlName="duration"
                                                        placeholder="60">
                                                </div>


                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Description</span>

                                                    <ckeditor [editor]="Editor" formControlName="description">
                                                    </ckeditor>
                                                </div>



                                            </div>
                                            <button class="btn btn-primary dark ">
                                                <i class="fas fa-plus"></i>Add</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>