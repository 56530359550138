<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3><b>Content Management</b> </h3>
                        </div>
                    </div>
                    <div class="card shadow border-0 mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-lg-2 text-center mt-2">
                                </div>

                                <div class="col-12 col-md-8">
                                    <div class="container-fluid">
                                        <form [formGroup]="cmsFormGroup" (submit)="onSubmit()">
                                            <div class="row">
                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Title</span>
                                                    <input type="text" class="form-control" formControlName="title"
                                                        placeholder="Content Title">
                                                </div>
                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Title</span>
                                                    <input type="text" class="form-control" formControlName="subTitle"
                                                        placeholder="Content Sub Title">
                                                </div>
                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Article Content</span>
                                                    <ckeditor [editor]="Editor" formControlName="description" >
                                                    </ckeditor>

                                                </div>
                                            </div>
                                            <button class="btn btn-primary dark ">
                                                <i class="fas fa-edit"></i> Update</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>