import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EducationalArticleService } from "src/app/services/educational-article/educational-article.service";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-edit-educational-article",
  templateUrl: "./edit-educational-article.component.html",
  styleUrls: ["./edit-educational-article.component.scss"],
})
export class EditEducationalArticleComponent implements OnInit {
  editEducationalFormGroup!: FormGroup | any;
  _id: any = "";
  title = "angular";
  public Editor = ClassicEditor;

  fileData: any = "";
  default: boolean = false;
  image: any = "";
  baseUrl = environment.baseUrl;
  removeurl = "";
  imageurl = "";
  constructor(
    private actRoute: ActivatedRoute,
    private fb: FormBuilder,
    private educationalBlogService: EducationalArticleService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.editEducationalFormGroup = this.fb.group({
      blogTitle: [""],
      blogContent: [""],
      image: [""],
    });
    this.getEducationalBlogInfo();
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.editEducationalFormGroup
      .get("image")
      .setValue(fileInput.target.files[0]);
    this.default = true;
    this.preview();
    if (this.imageurl) {
      this.removeurl = this.imageurl;
    }
  }

  preview() {
    // Show preview
    var mimeType = this.fileData.type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.image = reader.result;
    };
  }

  removeImage() {
    this.removeurl = this.imageurl;
    this.image = "";
  }

  get f() {
    return this.editEducationalFormGroup.controls;
  }

  onSubmit() {
    let data = {
      articleId: this.actRoute.snapshot.paramMap.get("id"),
      blogTitle: this.f.blogTitle.value,
      blogContent: this.f.blogContent.value,
    };
    const formData = new FormData();
    formData.append("blogId", this._id);
    formData.append("image", this.f.image.value);
    formData.append("blogTitle", this.f.blogTitle.value);
    formData.append("blogContent", this.f.blogContent.value);

    this.educationalBlogService
      .editEducationalBlog(formData)
      .subscribe((res: any) => {
        if (res.messageID == 200 && res.data) {
          this.toastr.success(res.message, res.status);
          this.router.navigateByUrl("/admin-educational-blog");
        } else {
          this.toastr.error(res.message, "Error");
        }
      });
  }

  getEducationalBlogInfo() {
    this._id = this.actRoute.snapshot.paramMap.get("id");
    let data = {
      blogId: this._id,
    };
    console.log("datat", data);
    this.educationalBlogService
      .educationalBlogDetails(data)
      .subscribe((res: any) => {
        if (res.messageID == 200 && res.data) {
          if (res.data.coverImage) {
            (this.imageurl = `/public/caregiver_picture/${res.data.coverImage}`),
              (this.image = res.data.coverImage);
            // this.image = `${this.baseUrl}/public/caregiver_picture/${res.data.coverImage}`
            console.log("image", this.imageurl, this.image);
          }
          this.editEducationalFormGroup.patchValue({
            blogTitle: res.data.blogTitle,
            blogContent: res.data.blogContent,
          });
        } else {
          this.toastr.error(res.message);
        }
      });
  }
}
