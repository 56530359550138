import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StaticPagesService } from 'src/app/services/static/static-pages.service';

@Component({
  selector: 'app-url',
  templateUrl: './url.component.html',
  styleUrls: ['./url.component.scss']
})
export class UrlComponent implements OnInit {
  urlList: any = [];
  constructor(
    private actRoute: ActivatedRoute,private StaticPagesService:StaticPagesService ,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.geturl({})
  }

  geturl(data: any) {
    this.StaticPagesService.getUrl(data).subscribe((res: any) => {
        if (res.messageID == 200 && res.data) {
            this.urlList = res.data
            console.log("thissssssssssss",this.urlList)
            
        } else {
            this.toastr.error(res.message)
        }
    })
}


}
