import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { CmsService } from 'src/app/services/cms-service/cms.service';

@Component({
  selector: 'app-edit-cms',
  templateUrl: './edit-cms.component.html',
  styleUrls: ['./edit-cms.component.scss']
})
export class EditCmsComponent implements OnInit {
  cmsFormGroup!: FormGroup | any;
  _id: any = '';
  title = 'angular';
  public Editor = ClassicEditor;
  constructor(private actRoute: ActivatedRoute,
    private fb: FormBuilder,
    private cmsService: CmsService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.cmsFormGroup = this.fb.group({
      title: [''],
      subTitle: [''],
      description: ['']
    })
    this.getCMS();

  }

  get f() {
    return this.cmsFormGroup.controls;
  }

  onSubmit() {
    let data = {
      id: this.actRoute.snapshot.paramMap.get('id'),
      title: this.f.title.value,
      subTitle: this.f.subTitle.value,
      description: this.f.description.value
    }
    this.cmsService.editCMS(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.toastr.success(res.message, res.status);
        this.router.navigateByUrl("/admin-content-management");
      } else {
        this.toastr.error(res.message, "Error")
      }
    });
  }

  getCMS() {
    let data = {
      id: this.actRoute.snapshot.paramMap.get('id')
    }
    this.cmsService.cmsDetails(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.cmsFormGroup.patchValue({
          title: res.data.title,
          subTitle: res.data.subTitle,
          description: res.data.description
        })
      } else {
        this.toastr.error(res.message)
      }

    })

  }

}
