import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PaymentServiceService } from 'src/app/services/payment/payment-service.service';

@Component({
    selector: 'app-subscribers-list',
    templateUrl: './subscribers-list.component.html',
    styleUrls: ['./subscribers-list.component.scss']
})
export class SubscribersListComponent implements OnInit {
    products: any = [];
    planType: any = '';
    searchTextValue: any = '';
    monthlyPrice: number = 0;
    yearlyPrice: number = 0;
    submitted: boolean = false;
    getPrices!: FormGroup;
    productDetail: any = '';
    updatedPlanPriceId: any = '';
    updatedPlanPrice: any = '';
    subscriberList: any = []
    selectedPlan: any = '';
    selectedPlanType: any = '';


    constructor(
        private paymentService: PaymentServiceService,
        private fb: FormBuilder,
    ) { }

    ngOnInit(): void {
        this.getPrices = this.fb.group({
            monthlyPrice: [],
            yearlyPrice: []
        })
        let data = {
            status: "",
            planName: this.selectedPlan,
            planType: this.selectedPlanType,
        }
        this.getSubscribersListFunction(data)
    }

    planDetail() {
        this.paymentService.getPlanDetails().subscribe((data: any) => {
            this.products = data.data.data;
        })
    }

    getSubscribersListFunction(data:any) {
        this.paymentService.getSubscribersList(data).subscribe((data: any) => {
            console.log(data, "this.subscriberList.return");
            this.subscriberList = data.data;
        })
    }

    downloadSubscribersCsv() {
        let data = {
            status: "",
            planName: ""
        }
        console.log(this.paymentService.downloadSubscribersCsv(data) , "return");
        // window.location.href = this.paymentService.downloadSubscribersCsv(data)
    }

    getPlan(event: any) {
        this.planType = event.target.value;
        this.updatedPlanPriceId = '';
        this.updatedPlanPrice = '';
        this.products.map((data: any) => {
            if (data.name == this.planType) {
                this.productDetail = data;
                this.yearlyPrice = data.images.prices[0].unit_amount / 100;
                this.monthlyPrice = data.images.prices[1].unit_amount / 100;
            };
            this.getPrices.patchValue({
                yearlyPrice: this.yearlyPrice,
                monthlyPrice: this.monthlyPrice
            })
        })
    }
    get f() {
        return this.getPrices.controls
    }
    onSubmit() {
        this.submitted = true;
        if (this.getPrices.invalid) {
            return
        } else {
            let updatedMonthlyPrice = this.f.monthlyPrice.value * 100;
            let updatedYearlyPrice = this.f.yearlyPrice.value * 100;

            if (this.productDetail.images.prices[0].unit_amount != updatedYearlyPrice) {
            
                this.updatedPlanPriceId = this.productDetail.images.prices[0].id;
                this.updatedPlanPrice = updatedYearlyPrice;
            }
            if (this.productDetail.images.prices[1].unit_amount != updatedMonthlyPrice) {
               
                this.updatedPlanPriceId = this.productDetail.images.prices[1].id;
                this.updatedPlanPrice = updatedMonthlyPrice;
            }

            let data = {
                name: this.productDetail.name,
                _id: this.productDetail.id,
                price: this.updatedPlanPrice,
                priceId: this.updatedPlanPriceId
            }
            // console.log(data, "updateedddValue");
        }
    }

    searchText(event:any) {
        this.searchTextValue = event.target.value
    }

     formatDate(timestamp:any) {
        const date = new Date(timestamp);
        const month = date.getMonth() + 1; // Months are 0-indexed
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
      }
    choosePlan(event: any) {
        console.log(event.target.value, "event.target.value");
        this.selectedPlan = event.target.value
        let data = {
            status: "",
            planName: this.selectedPlan,
            planType: this.selectedPlanType,
        }
        this.getSubscribersListFunction(data)
    }

    choosePlanType(event: any) {
        console.log(event.target.value, "event.target.value");
        this.selectedPlanType = event.target.value
        let data = {
            status: "",
            planName: this.selectedPlan,
            planType: this.selectedPlanType,
        }
        this.getSubscribersListFunction(data)
    }
}
