import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, catchError, of } from 'rxjs';
import { AuthServiceService } from '../auth/auth-service.service';


@Injectable({
  providedIn: 'root'
})
export class ChatServiceService {
  titlle = environment.title;
  baseUrl = environment.baseUrl;
  accessToken: any = localStorage.getItem('Token');


  constructor(private http: HttpClient, private authService: AuthServiceService) { }

  getChatData(data: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/all-chat-Admin?sender=${data.senderId}&receiver=${data.receiverId}&page=${data.page}&limit=${data.limit}`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  getBroadcastChatData(data: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/all-broadcast-Admin?sender=${data.senderId}`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("error", error.error, result); // log to console instead
      if (error.error.messageID == 401) {
        this.authService.logout()
        return of(error.error)
      }
      return of(error.error);
    };
  }
}
