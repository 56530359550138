import { Component, OnInit } from '@angular/core';
import { SubscriptionPlanService } from 'src/app/services/subscription-plan/subscription-plan.service';


@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss']
})
export class SubscriptionPlansComponent implements OnInit {
    subscriptionPlanList: any = [];
    page: number = 1;
    limit: number = 5;
    status: any = '';
    subscriptionPlanData: any = '';
    subscriptionPlanPrice: any = '';


    constructor(
        private subscriptionPlanService : SubscriptionPlanService,
  ) { }

    ngOnInit(): void {
        let data = {
            status: this.status,
            page: this.page,
            limit: this.limit
        };
        this.subscriptionPlanListFunction(data)
    //     this.subscriptionPlanList = [
    //         {
    //             "planName": "Basic",
    //             "duration": "Monthly",
    //             "price": "$16"
    //         },
    //         {
    //             "planName": "Basic",
    //             "duration": "Yearly",
    //             "price": "$150"
    //         }
    //   ]
    }
    
    subscriptionPlanListFunction(data: any) {
        this.subscriptionPlanService.subscriptionPlanList(data).subscribe((data: any) => {
            // console.log(data.data.data,"this.subscriptionPlanList");
            this.subscriptionPlanList = data.data.data
        })
    }

    choosePlan(event: any) {        
        this.subscriptionPlanList.map((subscriptionPlan: any) => {            
            if (subscriptionPlan.id == event.target.value) {
                this.subscriptionPlanData = subscriptionPlan
                console.log(this.subscriptionPlanData, "this.subscriptionPlanData");
                return
            }
        })
    }

    chooseInterval(event: any) {
        this.subscriptionPlanData.images.prices.map((price: any) => {
            if (price.id == event.target.value) {
                this.subscriptionPlanPrice = price
                console.log(this.subscriptionPlanPrice, "this.subscriptionPlanPrice");
                return
            }
        })
    }

}
