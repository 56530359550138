import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CmsService } from 'src/app/services/cms-service/cms.service';

@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.scss']
})
export class AddFaqComponent implements OnInit {
  addFaqFormGroup!: FormGroup | any;
  title = 'angular';


  constructor(
    private fb: FormBuilder,
    private cmsService: CmsService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.addFaqFormGroup = this.fb.group({
      question: [''],
      answer: [''],
    })
  }

  get f() {
    return this.addFaqFormGroup.controls;
  }

  onSubmit() {
    let data = {
      userId: "6433dd8f1e693202005c7691",
      question: this.f.question.value,
      answer: this.f.answer.value
    }
    this.cmsService.createFAQ(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.toastr.success(res.message, "Success");
        this.router.navigateByUrl("/faq");
      } else {
        this.toastr.error(res.message, "Error")
      }
    })
  }


}
