import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from '../auth/auth-service.service';
import { Observable, catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  accessToken: any = localStorage.getItem('Token');
  titlle = environment.title;
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient,
    private authService: AuthServiceService
  ) { }


  cmsList(data: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/cms/getCMSList?page=${data.page}&limit=${data.limit}&filter=${data.filter}`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  cmsDetails(data: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/cms/getCMSDetails?id=${data.id}`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  editCMS(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/cms/editCMS`, data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  deleteCMS(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/cms/deleteCMS`, data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  faqList(data: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/cms/getFAQList?page=${data.page}&limit=${data.limit}&filter=${data.filter}`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  faqDetails(data: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/cms/getFAQDetails?id=${data.id}`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  editFAQ(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/cms/editFAQ`, data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  deleteFAQ(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/cms/deleteFAQ`, data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  createFAQ(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/cms/createFAQ`, data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("error", error.error, result); // log to console instead
      if (error.error.messageID == 401) {
        this.authService.logout()
        return of(error.error)
      }
      return of(error.error);
    };
  }
}