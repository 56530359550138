import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsultationFeedbackService {
    accessToken: any = localStorage.getItem('Token');
    titlle = environment.title;
    baseUrl = environment.baseUrl;

    constructor(private http: HttpClient) { }

    consultationFeedbackList(data: any) {
        let header = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(`${this.baseUrl}/api/v1/admin/appointment-feedback-list?page=${data.page}&limit=${data.limit}`, { headers: header });
    }

    consultationFeedbackDetails(data: any) {
        let header = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(`${this.baseUrl}/api/v1/admin/appointment-feedback-details?appointmentId=${data.appointmentId}`, { headers: header });
    }
}
