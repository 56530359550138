<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3><b>Update Subscription</b></h3>
                        </div>
                    </div>
                    <div class="card shadow border-0 mt-3">
                        <div class="card-body">
                            <div class="row">
                                <!-- <div class="col-12 col-lg-3 text-center mt-3">
                                    <div class="profile_pic">
                                        <img *ngIf="!image" src="../../../../assets/images/user_preg.jpeg" width="170"
                                            height="170" class="rounded m-auto " />
                                        <img *ngIf="image" [src]="image" width="170" height="170"
                                            class="rounded m-auto  " />
                                    </div>

                                  


                                </div> -->
                                <div class="col-12 col-md-12">
                                    <div class="container-fluid">
                                        <form [formGroup]="serviceAdd" (submit)="onSubmit()">
                                            <div class="row">
                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Name</span>
                                                    <input type="text" class="form-control" formControlName="name"
                                                        placeholder="Name">
                                                </div>

                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Description</span>
                                                    <textarea type="text" class="form-control" formControlName="description"
                                                        placeholder="Description" style="height:100px"></textarea>
                                                </div>

                                               
                                            

                                               
                                               

                                               
                                                

                                                
                                            </div>
                                            <button class="save_btnn">
                                                Update
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
