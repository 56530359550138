import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CmsService } from 'src/app/services/cms-service/cms.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  faqList: any = [];
  page: number = 1;
  limit: number = 5;
  blogTitle: string = "";
  totaldocs: any = 0;
  filter: any = '';
  articleId: any;
  constructor(
    private cmsService: CmsService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    let data = {
      filter: this.filter,
      page: this.page,
      limit: this.limit,
    };
    this.getFAQList(data)
  }


  truncateText(text: string, wordCount: number): string {
    const words = text.split(' ');
    if (words.length <= wordCount) {
      return text;
    }
    const truncatedText = words.slice(0, wordCount).join(' ');
    return `${truncatedText} ......`;
  }


  formatDate(isoDateString:any) {
    const date = new Date(isoDateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
  }
  getFAQList(data: any) {
    this.cmsService.faqList(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.faqList = res.data.docs
        this.totaldocs = res.data.totalDocs;
        this.page = res.data.page
        this.toastr.success(res.message, "Success")
      } else {
        this.toastr.error(res.message)
      }
    })
  }

  searchFAQ(event: any) {
    let data = {
      page: this.page,
      filter: event.target.value,
      limit: this.limit
    };
    this.getFAQList(data)

  }
  pageChange(page: any) {
    let data = {
      filter: this.filter,
      page: page,
      limit: this.limit
    };
    this.getFAQList(data)
  }
  getFAQStatus(data: any) {
    this.articleId = data._id;
  }
  closeModal() {
    let data = {
      filter: this.filter,
      page: this.page,
      limit: this.limit
    };

    this.getFAQList(data);
  }
  deleteFAQ() {
    let dataa = {
      filter: this.filter,
      page: this.page,
      limit: this.limit
    };
    let data = {
      _id: this.articleId,
      isDelete: true
    };

    this.cmsService.deleteFAQ(data).subscribe((res: any) => {
      if (res.messageID == 200) {
        this.toastr.success(res.message, res.status)
        let element: HTMLElement = document.getElementById('closeUserDeleteModal') as HTMLElement;
        element.click();
        this.getFAQList(dataa);
      } else {
        this.toastr.error(res.message)
      }
    })
  }

}
