import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { io } from "socket.io-client";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CaregiverServiceService } from 'src/app/services/caregiver/caregiver-service.service';
import { UserServiceService } from 'src/app/services/user/user-service.service';
import { ChatServiceService } from 'src/app/services/chat-service/chat-service.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';

@Component({
    selector: 'app-all-chats',
    templateUrl: './all-chats.component.html',
    styleUrls: ['./all-chats.component.scss']
})
export class AllChatsComponent implements OnInit {
    chatMessageFormGroup!: FormGroup | any;
    selectedUserType = 2
    userlist: any = []
    chatReceiversList: any
    chatData: any = []
    totaldocs: any = 0;
    page: number = 1;
    limit: number = 6;
    pageChat: number = 1;
    limitChat: number = 50;
    filter: any = '';
    status: any = '';
    selectedUserName: any = 'Sipmly';
    caregiverId: any;
    localavatar: any = "../../../../assets/images/avatar.jpg";


    socket: any;
    userId: any;
    @ViewChild('scrollMe', { static: false }) scrollMe!: ElementRef;
    route: any;
    constructor(
        private fb: FormBuilder,
        private caregiverService: CaregiverServiceService,
        private userService: UserServiceService,
        private chatService: ChatServiceService,
        private toastr: ToastrService,
    ) {
        this.userId = localStorage.getItem('_id');

    }
    routerurl: any = '';
    originalUserlist: any = []; // (1) 
    ngOnInit(): void {
        this.routerurl = this.route?.snapshot?.url[0]?.path;
        this.socket = io(environment.baseUrl, { transports: ['websocket', 'polling', 'flashsocket'] });
        this.chatMessageFormGroup = this.fb.group({
            message: ['', [Validators.required]],
        })
        this.socket.emit('connectUser', { userId: localStorage._id, });
        let data = {
            filter: this.filter,
            status: this.status,
            page: this.page,
            limit: this.limit
        };
        this.customerList(data);
        this.socket.on('msgAcknowledgement', (res: any) => {
            console.log("msgAcknowledgement", res)
            if (res) {
                console.log("res.data[0]", res.data[0])
                this.chatData.push(res.data[0])
                setTimeout(() => {
                    this.scrollToElement();
                    this.pageChat = 1;
                    this.limitChat = 50
                }, 100);
                this.chatMessageFormGroup.reset()
            }
        });
        this.socket.on('messageReceived', (res: any) => {
            console.log("messageReceived", res)
            if (res) {
                console.log("res.data[0]", res)
                this.chatData.push(res[0])
                setTimeout(() => {
                    this.scrollToElement();
                    this.pageChat = 1;
                    this.limitChat = 50
                }, 100);

            }
        });
    }
 


    ngAfterViewInit(): void {
        this.scrollToElement();
    }
    scrollToElement(): void {
        if (this.scrollMe) {
            try {
                const element = this.scrollMe.nativeElement;
                element.scrollIntoView({ behavior: 'smooth', block: 'end' });
            } catch (err) {
                console.error('Error while scrolling to the element:', err);
            }
        }
    }
    get f() {
        return this.chatMessageFormGroup.controls;
    }

    public chatMessageSend() {

        if (this.chatMessageFormGroup.invalid) {
            this.toastr.warning("Please enter message")
            return
        }
        let data = {
            message: this.f.message.value,
            sender: localStorage.getItem("_id"),
            receiver: this.chatReceiversList
        }
        let message = data
        this.socket.emit('sendMessage', message);
        
        setTimeout(() => {
            this.scrollToElement();
            this.pageChat = 1;
            this.limitChat = 50
        }, 100);

  

        let chatParams = {
            senderId: localStorage.getItem("_id"),
            receiverId: this.chatReceiversList,
            page: 1,
            limit: 50
        }
        this.getChatData(chatParams)
    }

    loadMore() {
        if (Math.ceil(this.chatData.totalMessageCount / this.limitChat) != this.pageChat) {
            this.pageChat += 1;
        }
        if (Math.ceil(this.chatData.totalMessageCount / this.limitChat) == this.pageChat) {
            return;
        } else {
            let chatParams = {
                senderId: localStorage.getItem("_id"),
                receiverId: this.caregiverId,
                page: this.pageChat,
                limit: this.limitChat
            }
      
            this.chatService.getChatData(chatParams).subscribe((data: any) => {
                if (data.messageID == 200 && data.data) {
                    let chatNewdata = data.data
                    chatNewdata.reverse();
                    
                    this.chatData.unshift(...chatNewdata);
                }
            })
        }


    }
    onScrollDown() { }
    selectUserType(event: any) {
        this.selectedUserType = event.target.value
        this.caregiverId = ''
        this.page=1
        let params = {
            filter: this.filter,
            status: this.status,
            page: this.page,
            limit: this.limit
        };
        if (event.target.value == 3) {
            this.caregiversList(params);
        } else if (event.target.value == 2) {
            this.customerList(params);
        }
    }

    caregiversList(data: any) {
        this.caregiverService.getCaregiverListChat(data).subscribe((data: any) => {
            this.userlist = data.data.docs;
            this.originalUserlist = [...data.data.docs]; //  
            this.totaldocs = data.data.totalDocs;
            this.page = data.data.page
        })
    }

 

     // search by first name whith accending order 

  searchUser(event: any) {
    const searchTerm = event.target.value.trim().toLowerCase(); 
  
    // Check if the search input is empty or only contains whitespace
    if (!searchTerm || searchTerm.length === 0) {
      // If the search input is empty, restore the original user list
      this.userlist = [...this.originalUserlist];
      return;
    }
  
    // Perform a new search request to fetch users that match the search criteria
    let data = {
      filter: searchTerm, 
      status: this.status,
      page: 1, 
      limit: 1000 
    };
  
    this.userService.userList(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.toastr.success(res.message);
  
        // Combine users from all pages into a single array
        const allUsers = res.data.docs;
  
        // Filter users based on the search term 
        const filteredUsers = allUsers.filter((user: any) =>
          user.firstName.toLowerCase().startsWith(searchTerm)
        );
  
        // Sort the filtered users in ascending order based on first name
        filteredUsers.sort((a: any, b: any) => {
          const firstNameA = a.firstName.toLowerCase();
          const firstNameB = b.firstName.toLowerCase();
          return firstNameA.localeCompare(firstNameB);
        });
  
        this.userlist = filteredUsers;
      } else {
        this.toastr.error("Data Not found");
      }
    });
  }

    customerList(data: any) {
        this.userService.userListChat(data).subscribe((data: any) => {
            this.userlist = data.data.docs;
            this.originalUserlist = [...data.data.docs]; // (4) 
            this.totaldocs = data.data.totalDocs;
            this.page = data.data.page

            console.log(this.userlist , "users data >>>>>>>>")

        })
    }

    selectUserForChat(data: any) {
        this.selectedUserName = data.firstName
        this.caregiverId = data._id
        this.chatReceiversList = data._id
        setTimeout(() => {
            this.scrollToElement();
            this.pageChat = 1;
            this.limitChat = 50
        }, 1000);
        let chatParams = {
            senderId: localStorage.getItem("_id"),
            receiverId: data._id,
            page: 1,
            limit: 50
        }
        this.getChatData(chatParams)
    }

    getChatData(data: any) {
        const sub = this.chatService.getChatData(data)
        .subscribe((data: any) => {
            if (data.messageID == 200 && data.data) {
                this.chatData = data.data
                console.log(this.chatData[0].allDates, "chatData >>>>>>>")
                this.chatData.reverse();
                setTimeout(() => {
                    this.scrollToElement();
                    this.pageChat = 1;
                    this.limitChat = 50
                }, 1000);
            }
            sub.unsubscribe()
        })
    }

    pageChange(page: any) {
        let data = {
            filter: this.filter,
            status: this.status,
            page: page,
            limit: this.limit
        };
        if (this.selectedUserType == 3) {
            this.caregiversList(data);
        } else if (this.selectedUserType == 2) {
            this.customerList(data);
        }
    }
}
