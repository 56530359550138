import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from '../auth/auth-service.service';

@Injectable({
    providedIn: 'root'
})
export class EducationalArticleService {

    accessToken: any = localStorage.getItem('Token');
    titlle = environment.title;
    baseUrl = environment.baseUrl;

    constructor(private http: HttpClient,
        private authService: AuthServiceService
    ) { }


    educationalBlogList(data: any): Observable<any> {
        return this.http.get(`${this.baseUrl}/api/v1/get-blog-list?page=${data.page}&limit=${data.limit}&filter=${data.filter}`)
            .pipe(
                catchError(this.handleError('getUser', []))
            );
    }
    educationalBlogDetails(data: any): Observable<any> {
        return this.http.get(`${this.baseUrl}/api/v1/get-blog-details?blogId=${data.blogId}`)
            .pipe(
                catchError(this.handleError('getUser', []))
            );
    }

    addEducationalBlog(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/api/v1/create-blog`, data)
            .pipe(
                catchError(this.handleError('getUser', []))
            );
    }
    editEducationalBlog(data: any): Observable<any> {
        return this.http.put(`${this.baseUrl}/api/v1/edit-blog`, data)
            .pipe(
                catchError(this.handleError('getUser', []))
            );
    }
    deleteEducationalBlog(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/api/v1/delete-blog`, data)
            .pipe(
                catchError(this.handleError('getUser', []))
            );
    }
    educationalVideoList(data: any): Observable<any> {
        return this.http.get(`${this.baseUrl}/api/v1/get-video-list?page=${data.page}&limit=${data.limit}&filter=${data.filter}`)
            .pipe(
                catchError(this.handleError('getUser', []))
            );
    }
    educationalVideoUpload(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/api/v1/upload-video`, data)
            .pipe(
                catchError(this.handleError('getUser', []))
            );
    }
    educationalVideoDetails(data: any): Observable<any> {
        return this.http.get(`${this.baseUrl}/api/v1/get-video-details?videoId=${data.videoId}`)
            .pipe(
                catchError(this.handleError('getUser', []))
            );
    }
    deleteEducationalVideo(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/api/v1/delete-video`, data)
            .pipe(
                catchError(this.handleError('getUser', []))
            );
    }
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.log("error", error.error, result); // log to console instead
            if (error.error.messageID == 401) {
                this.authService.logout()
                return of(error.error)
            }
            return of(error.error);
        };
    }
}
