import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from 'express';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss']
})
export class AdminSidebarComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }
  routerurl: any = '';
  showClr: any = '';

  ngOnInit(): void {
    this.routerurl = this.route?.snapshot?.url[0]?.path;
  }


  toggleSubMenu(subMenuId:any) {
    var subMenu = document.getElementById(subMenuId);
    if (subMenu) {
        if (subMenu.style.display === "block") {
            subMenu.style.display = "none";
        } else {
            subMenu.style.display = "block";
        }
    }
}
}
