import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from '../auth/auth-service.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root'
})
export class AdminServiceService {

  titlle = environment.title;
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, private authService: AuthServiceService) { }



  serviceList(data: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/Service/listProducts`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }


  serviceAdd(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/service/createProduct`,data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  serviceDelete(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/service/deleteProduct`,data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }


  serviceget(id: any): Observable<any> {
  
    return this.http.get(`${this.baseUrl}/api/v1/service/get-product?id=${id}`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  serviceEdit(data: any): Observable<any> {
  
    return this.http.put(`${this.baseUrl}/api/v1/service/updateProduct`,data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("error", error.error); // log to console instead
      if (error.error.messageID == 401) {
        this.authService.logout()
        return of(error.message)
      }
      return of(result as T);
    };
  }
}
