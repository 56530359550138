import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from '../auth/auth-service.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root'
})
export class QuizService {

  titlle = environment.title;
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, private authService: AuthServiceService) { }


  squizList(data: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/get-quiz-list`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }


  addQuiz(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/create-quiz`, data)
        .pipe(
            catchError(this.handleError('getUser', []))
        );
}

deleteQuiz(data: any): Observable<any> {
  return this.http.post(`${this.baseUrl}/api/v1/delete-quiz`, data)
      .pipe(
          catchError(this.handleError('getUser', []))
      );
} 


quizDetail(data: any): Observable<any> {
  
  return this.http.get(`${this.baseUrl}/api/v1/get-quiz-details?quizId=${data.quizId}`)
  
    .pipe(
      catchError(this.handleError('getUser', []))
    );
}

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("error", error.error); // log to console instead
      if (error.error.messageID == 401) {
        this.authService.logout()
        return of(error.message)
      }
      return of(result as T);
    };
  }



  quizEdit(data: any): Observable<any> {
  
    return this.http.put(`${this.baseUrl}/api/v1/edit-quiz`,data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
}
