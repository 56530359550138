import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentServiceService {

  titlle = environment.title;
  baseUrl = environment.baseUrl;

    constructor(private http: HttpClient) { }

  getPlanDetails() {
    return this.http.get(`${this.baseUrl}/api/v1/total-products`)
    }

    getSubscribersList(data:any) {
        return this.http.get(`${this.baseUrl}/api/v1/admin/subscriber-list?status=${data.status}&planName=${data.planName}`)
    }
    
    downloadSubscribersCsv(data: any) {
        let url = this.baseUrl+`/api/v1/admin/csv-download-subscriber-list?status=`+data.status+`&planName=`+data.planName
        return url
        // return this.http.get(`${this.baseUrl}/api/v1/admin/csv-download-subscriber-list?status=${data.status}&planName=${data.planName}`)
    }

}
