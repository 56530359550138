import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MindfullnessService } from 'src/app/services/mindfull/mindfullness.service';

@Component({
  selector: 'app-mindfullness-session',
  templateUrl: './mindfullness-session.component.html',
  styleUrls: ['./mindfullness-session.component.scss']
})
export class MindfullnessSessionComponent implements OnInit {
  page: number = 1;
  limit: number = 6;
  filter: any = '';
  mindFullnesslist: any = [];
  mindFullness_id: any = '';
  isDelete: boolean = false;
  mindFullnessName: any = '';

  status: any = '';
  totaldocs: any = 0;
  constructor(private mindFullnessService: MindfullnessService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    let data = {
      filter: this.filter,
      status: this.status,
      page: this.page,
      limit: this.limit
    };
    this.mindFullnesssList(data);
  }
  mindFullnesssList(data: any) {
    this.mindFullnessService.getmindFullnessList(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
       
        this.toastr.success(res.message);
        this.mindFullnesslist = res.data.docs;
        this.totaldocs = res.data.totalDocs;
      } else {
      
      }
    })
  }

  truncateText(text: string, wordCount: number): string {
    const words = text.split(' ');
    if (words.length <= wordCount) {
      return text;
    }
    const truncatedText = words.slice(0, wordCount).join(' ');
    return `${truncatedText} ......`;
  }

  searchSession(event: any) {
    let data = {
      filter: event.target.value,
      status: this.status,
      page: this.page,
      limit: this.limit
    };
    this.mindFullnessService.getmindFullnessList(data).subscribe((res: any) => {
      this.mindFullnesslist = res.data.docs;
    });
  }

  // selectOption(value: any) {
  //   this.status = (value.target as HTMLInputElement).value;
  //   console.log(this.status, "check");
  //   let data = {
  //     filter: this.filter,
  //     status: this.status,
  //     page: this.page,
  //     limit: this.limit
  //   };
  //   this.mindFullnesssList(data);
  // }


  pageChange(page: any) {
    let data = {
      filter: this.filter,
      status: this.status,
      page: page,
      limit: this.limit
    };
    this.mindFullnessService.getmindFullnessList(data).subscribe((res: any) => {
      if (res.status === "Success" && res.messageID === 200) {
        this.mindFullnesslist = res.data.docs;
        console.log("insideIfff");

        this.totaldocs = res.data.totalDocs;
        this.page = page
      } else {
        console.log("ousideIfff", res);

      }
    });
  }


  getmindfullStatus(data: any) {
    console.log("data", data);
    this.mindFullness_id = data._id;
    this.isDelete = data.isDelete;
    if (this.isDelete == true) {
      this.isDelete = false
    } else {
      this.isDelete = true;
    }
  }

  // blockUser() {
  //   let data = {
  //     _id: this.mindFullness_id,
  //     isDelete: this.isDelete
  //   };

  //   let dataa = {
  //     filter: this.filter,
  //     status: this.status,
  //     page: this.page,
  //     limit: this.limit
  //   };

  //   this.userService.blockUser(data).subscribe((data: any) => {
  //     this.toastr.success(data.message, data.status)
  //     let element: HTMLElement = document.getElementById('closemindFullnessBlockModal') as HTMLElement;
  //     element.click();
  //     this.mindFullnesssList(dataa);
  //   })
  // }

  closeModal() {
    let data = {
      filter: this.filter,
      status: this.status,
      page: this.page,
      limit: this.limit
    };

    this.mindFullnesssList(data);
  }
  deleteSession() {
    let dataa = {
      filter: this.filter,
      status: this.status,
      page: this.page,
      limit: this.limit
    };
    let data = {
      _id: this.mindFullness_id,
      isDelete: true
    };

    this.mindFullnessService.deteleSession(data).subscribe((res: any) => {
      if (res.messageID == 200) {
        this.toastr.success(res.message, res.status)
        let element: HTMLElement = document.getElementById('closeSessionDeleteModal') as HTMLElement;
        element.click();
        this.mindFullnesssList(dataa);
      } else {
        this.toastr.error(res.message)
      }

    })
  }
}
