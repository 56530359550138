
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3><b>FAQ's</b> </h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <h3><b>FAQ's</b> </h3>
                        </div>
                    </div>
                    <!-- <div class="card shadow border-0 mt-3">
                        <div class="card-body">
                            <div class="row">
                            

                                <div class="col-12 col-md-9">
                                    <div class="container-fluid">
                                        <form [formGroup]="addQuiz" (submit)="onSubmit()">
                                            <div class="row">
                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span> Title</span>
                                                    <input type="text" class="form-control" formControlName="quizTitle"
                                                        placeholder="quizTitle">
                                                </div>
                                               
                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Add Content</span>
                                                   
                                                    <ckeditor [editor]="Editor" formControlName="uri">
                                                    </ckeditor>
                                                   </div>
                                            </div>
                                            <button class="btn btn-primary dark " >
                                                <i class="fas fa-plus"></i>Add</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
</main>


