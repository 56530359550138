<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <div class="container-fluid mb-4">
        <div class="row">
            <div class="col-12 col-md-5 col-lg-3 text-start">
                <h4 class="fs-28 fw-bold"> Subscription Plans
                </h4>
            </div>
            <div class="col-12 col-md-5 col-lg-3"> <select class="form-select me-3">
                    <option value="">Purchase Plan</option>
                    <option value="false">Monthly</option>
                    <option value="true">Yearly</option>
                </select> </div>
            <div class="col-12 col-md-7 col-lg-3 text-end">
                <div class="input-group"> <input type="text" class="form-control border-end-0 form-control-lg"
                        placeholder="Search" style="font-size: 13px" /> <span
                        class="input-group-text bg-white border-start-0"><i class="bi bi-search curser"></i> </span>
                </div>
            </div>
        </div>
    </div>
    <div class="card border-0 shadow">
        <div class="card-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 table-responsive">
                        <table class="table">
                            <thead class="d-lg-block-thead">
                                <tr>
                                    <th> Plan Name
                                    </th>
                                    <th> Duration
                                    </th>
                                    <th> Price
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-label=" First Name">planName</td>
                                    <td data-label="Last Name">duration</td>
                                    <td data-label="Contact No.">price</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="paginator" style="float: right;">
                            <pagination-controls></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- //Modal -->
    <!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"><b>Update Plan Price</b></h5> <button type="button"
                        class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="col-12 col-md-5 col-lg-5"> <select class="form-select me-3" (click)="getPlan($event)">
                            <option disabled selected value="">Select Plan</option>
                            <option *ngFor="let product of products; index as i " value="{{product.name}}">
                                {{product.name}}</option>
                        </select> </div>
                    <form [formGroup]="getPrices" (submit)="onSubmit()">
                        <div class="form-group"> <label for="recipient-name" class="col-form-label">Monthly
                                Plan:</label> <input type="text" class="form-control" formControlName="monthlyPrice"
                                id="recipient-name" placeholder="$"> </div>
                        <div class="form-group"> <label for="message-text" class="col-form-label">Yearly Plan:</label>
                            <input type="text" class="form-control" formControlName="yearlyPrice" id="recipient-name"
                                placeholder="$">
                        </div>
                        <div class="modal-footer"> <button class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button class="btn btn-primary">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div> -->
</main>