import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { QuizService } from 'src/app/services/quiz/quiz.service';
@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  QuizList: any = [];
    page: number = 1;
    limit: number = 5;
    blogTitle: string = "";
    totaldocs: any = 0;
    filter: any = '';
    articleId: any;
    constructor(
      private actRoute: ActivatedRoute,private QuizService:QuizService ,
      private router: Router,
      private toastr: ToastrService
    ) { }

    ngOnInit(): void {
        this.getQuizList({})
    }

    getQuizList(data: any) {
        this.QuizService.squizList(data).subscribe((res: any) => {
            if (res.messageID == 200 && res.data) {
                this.QuizList = res.data.docs
                this.totaldocs = res.data.totalDocs;
                this.page = res.data.page
                console.log("thissssssssssss",this.QuizList)
                this.toastr.success(res.message, "Success")
            } else {
                this.toastr.error(res.message)
            }
        })
    }
    formatDate(isoDateString:any) {
      const date = new Date(isoDateString);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    }
    deleteQuizs(_id: string) {
   
      let data = {
        quizId: _id,
        isDelete: true,
      };
  
      this.QuizService.deleteQuiz(data).subscribe((res: any) => {

        if (res.messageID == 200) {
          this.toastr.success(res.message, res.status)
          let element: HTMLElement = document.getElementById('closeUserDeleteModal') as HTMLElement;
          element.click();
         
          this.getQuizList({});
         
          
        } else {
          this.toastr.error(res.message)
        }
  
      })
    }
    
   
}
