import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, catchError, of } from 'rxjs';
import { AuthServiceService } from '../auth/auth-service.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root'
})
export class UserServiceService {
  accessToken: any = localStorage.getItem('Token');
  titlle = environment.title;
  baseUrl = environment.baseUrl;
  public loginData = new Subject();
  constructor(private http: HttpClient, private authService: AuthServiceService) { }

  userList(data: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/user/list?page=${data.page}&limit=${data.limit}&filter=${data.filter}&status=${data.status}`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  assignCaregiverList(data: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/user/assigned-caregiver-list?caregiverName=${data.caregiverName}&userId=${data.userId}`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  assignCaregiver(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/admin/assign-caregivers-to-patient`,data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  userCount(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/countUser`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  caregiverCount(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/countCaregiver`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  consultationCount(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/countConsultation`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  totalEarningDailyCount(): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/api/v1/admin/total-earning-daily`, httpOptions)
      .pipe(catchError(this.handleError('getUser', [])));
  }
  totalEarningWeeklyCount(): Observable<any> {
    return this.http.
      get(`${this.baseUrl}/api/v1/admin/total-earning-weekly`, httpOptions)
      .pipe(catchError(this.handleError('getUser', [])));
  }
  totalEarningMonthlyCount(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/admin/total-earning-monthly`, httpOptions)
      .pipe(catchError(this.handleError('getUser', [])));
  }

  usersDetail(_id: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/user/details?_id=${_id}`, httpOptions)
      .pipe(catchError(this.handleError('getUser', [])));
  }

  blockUser(data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/api/v1/update/status`, data, httpOptions)
      .pipe(catchError(this.handleError('getUser', [])));
  }

  deteleUser(data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/api/v1/deleteUser`, data, httpOptions)
      .pipe(catchError(this.handleError('getUser', [])));
  }
  removeAssignCaregiver(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/admin/remove-caregivers-to-patient`, data, httpOptions)
      .pipe(catchError(this.handleError('getUser', [])));
  }
  adminInfo(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/admin/profile`, httpOptions)
      .pipe(catchError(this.handleError('getUser', [])));
  }
  updateAdminProfile(data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/api/v1/admin/update-profile`, data)
      .pipe(catchError(this.handleError('getUser', [])));
  }

  uploadVideos(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/upload-video`, data)
      .pipe(catchError(this.handleError('getUser', [])));
  }
  userListChat(data: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/user/list-chat?page=${data.page}&limit=${data.limit}&filter=${data.filter}&status=${data.status}`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("error", error.error); // log to console instead
      if (error.error.messageID == 401) {
        this.authService.logout()
        return of(error.message)
      }
      return of(result as T);
    };
  }

}
