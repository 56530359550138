<div class="container-fluid">
    <div class="row h-100">
        <div class="col-md-6 sliderection centerMid">
            <div class="sliderContainer">
                <div class="logo">
                    <img src="../../../assets/images/logo_psalm.png" alt="" />
                </div>
                <div class="sliderImg">
                    <img src="../../../assets/images/enterOtp.png" alt="" />
                    <h1>Welcome To SimplyBirthed</h1>
                    <p>
                        Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt
                        qui esse pariatur duis deserunt mollit dolore cillum minim tempor
                        enim. Elit aute irure tempor.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 centerMid">
            <div class="formContainer">
                <div class="logoIcon">
                    <img src="../../../assets/images/keyIcon.png" alt="" />
                </div>
                <h1>Enter OTP</h1>
                <p>A 6 digit code has been sent to <b>Your mailID</b></p>
                <div class="row">
                    <div class="col p-0">
                        <div class="inputField">
                            <ng-otp-input (onInputChange)="onOtpChange($event)"
                                [config]="{ length: 6, allowNumbersOnly: true }">
                            </ng-otp-input>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" (click)="sendOtpAgain()">
                        <p>I didn't receive code. <a style="cursor:pointer"> <b>Resend code</b>
                            </a></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-4">
                        <button class="btn btn-primary" (click)="verifyOTP()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>