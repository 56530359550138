import { Component, OnInit } from '@angular/core';
import { ConsultationFeedbackService } from 'src/app/services/consultation-feedback/consultation-feedback.service';

@Component({
  selector: 'app-consultation-feedback',
  templateUrl: './consultation-feedback.component.html',
  styleUrls: ['./consultation-feedback.component.scss']
})
export class ConsultationFeedbackComponent implements OnInit {
    consultationFeedbackList: any = [];
    page: number = 1;
    limit: number = 1;

    constructor(
        private consultationFeedbackService: ConsultationFeedbackService,
  ) { }

    ngOnInit(): void {
        let data = {
            page: this.page,
            limit: this.limit
        };
        this.consultationFeedbackListFunction(data)
    }
    consultationFeedbackListFunction(data: any) {
        this.consultationFeedbackService.consultationFeedbackList(data).subscribe((data: any) => {
            // console.log(data, "data");
            this.consultationFeedbackList = data.data
            console.log(this.consultationFeedbackList,"this.consultationFeedbackList");
        })
    }

}
