import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EducationalArticleService } from 'src/app/services/educational-article/educational-article.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-details-educational-video',
    templateUrl: './details-educational-video.component.html',
    styleUrls: ['./details-educational-video.component.scss']
})
export class DetailsEducationalVideoComponent implements OnInit {
    videoDetails: any = {}
    videoURL: any = ''
    baseUrl = environment.baseUrl;
    constructor(
        private educationalArticleService: EducationalArticleService,
        private actRoute: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        console.log("videoURL");

        let data = {
            videoId: this.actRoute.snapshot.paramMap.get('id')
        };
        this.educationalVideoDetailsFunction(data);
        // console.log(this.videoURL, "videoURL ngOnInit");
    }
     formatDate(isoDateString:any) {
        const date = new Date(isoDateString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
      }
    educationalVideoDetailsFunction(data: any) {
        this.educationalArticleService.educationalVideoDetails(data).subscribe(async (data: any) => {
            this.videoDetails = await data.data
            // this.videoURL = `http://localhost:4000/public/education_videos/` + this.videoDetails.video
            this.videoURL = `${this.baseUrl}/public/education_videos/${this.videoDetails.video}`

        })
    }

}
