<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="dashboard_section">
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-md-4">
                <div class="box nurshing">
                    <div class="circle"></div>
                    <div class="lBox">
                        <h5>Total Users</h5>
                        <p>{{users.length}}</p>

                    </div>
                    <div class="rBox">
                        <img src="../../assets/images/nurshing_card.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box nurse">
                    <div class="circle"></div>
                    <div class="lBox">
                        <h5>Total Caregivers</h5>
                        <p>{{caregivers.length}}</p>
                    </div>
                    <div class="rBox">
                        <img src="../../assets/images/nurse_card.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-3">
                <div class="box nurse">
                    <div class="circle"></div>
                    <div class="lBox">
                        <h5>Total Earnings</h5>
                        <p>150K</p>
                    </div>
                    <div class="rBox">
                        <img src="../../assets/images/nurshing_card.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div> -->
            <div class="col-md-4">

                <div class="box physician">
                    <div class="circle"></div>
                    <div class="lBox">
                        <h5>Consultations</h5>
                        <p>{{totalConsultation}}</p>
                    </div>
                    <div class="rBox">
                        <img src="../../assets/images/doc_card.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <div class="box nurse">
                    <div class="circle"></div>
                    <div class="lBox">
                        <h5>Total Earnings (Daily)</h5>
                        <p>{{totalEarningDaily / 100 | number: '1.2-2'}}</p>
                    </div>
                    <div class="rBox">
                        <img src="../../assets/images/nurshing_card.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box nurse">
                    <div class="circle"></div>
                    <div class="lBox">
                        <h5>Total Earnings (Weekly)</h5>
                        <p>{{totalEarningWeekly/ 100 | number: '1.2-2'}}</p>
                    </div>
                    <div class="rBox">
                        <img src="../../assets/images/nurshing_card.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box nurse">
                    <div class="circle"></div>
                    <div class="lBox">
                        <h5>Total Earnings (Monthly)</h5>
                        <p>{{totalEarningMonthly / 100 | number: '1.2-2'}}</p>
                    </div>
                    <div class="rBox">
                        <img src="../../assets/images/nurshing_card.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-6">
                <div class="title">
                    <h4>Users List</h4>
                    <a [routerLink]="['/admin-users']">View All</a>
                </div>
                <div class="physician_box">
                    <div class="table-responsive border-1 dashboard_physicain_table">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr *ngFor="let user of users">
                                    <td>
                                        <div class="imgBox">

                                            <span>{{user.firstName}} {{user.lastName}}</span>
                                        </div>
                                    </td>
                                    <td>{{user.email}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="title">
                    <h4>Caregivers List</h4>
                    <a [routerLink]="['/admin-caregivers']">View All</a>
                </div>
                <div class="physician_box">
                    <div class="table-responsive border-1 dashboard_physicain_table">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr *ngFor="let caregiver of caregivers">
                                    <td>
                                        <div class="imgBox">

                                            <span>{{caregiver.firstName}} {{caregiver.lastName}}</span>
                                        </div>
                                    </td>
                                    <td>{{caregiver.email}}</td>

                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>