import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EducationalArticleService } from 'src/app/services/educational-article/educational-article.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
    selector: 'app-add-educational-article',
    templateUrl: './add-educational-article.component.html',
    styleUrls: ['./add-educational-article.component.scss']
})
export class AddEducationalArticleComponent implements OnInit {
    addEducationalFormGroup!: FormGroup | any;
    title = 'angular';
    public Editor = ClassicEditor;
    fileData: any = false;
    default: boolean = false;
    image: any = '';
    constructor(
        private fb: FormBuilder,
        private educationalBlogService: EducationalArticleService,
        private toastr: ToastrService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.addEducationalFormGroup = this.fb.group({
            blogTitle: [''],
            blogContent: [''],
            image: [''],
        })
    }
    fileProgress(fileInput: any) {
        this.fileData = <File>fileInput.target.files[0];
        console.log(this.fileData, "image_path");

        this.addEducationalFormGroup.get('image').setValue(fileInput.target.files[0]);
        this.default = true;
        this.preview();
    }

    preview() {
        // Show preview
        var mimeType = this.fileData.type;

        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(this.fileData);
        reader.onload = (_event) => {
            this.image = reader.result;
            console.log(_event, "eventtt");

        };
    }
    removeImage() {
        this.image = '';
        this.f.image.value = this.image;
    }

    get f() {
        return this.addEducationalFormGroup.controls;
    }

    onSubmit() {
        const formData = new FormData();
        formData.append('image', this.f.image.value);
        formData.append('blogTitle', this.f.blogTitle.value);
        formData.append('blogContent', this.f.blogContent.value);

        this.educationalBlogService.addEducationalBlog(formData).subscribe((res: any) => {
            if (res.messageID == 200 && res.data) {
                this.toastr.success(res.message, "Success");
                this.router.navigateByUrl("/admin-educational-blog");
            } else {
                this.toastr.error(res.message, "Error")
            }
        })
    }


}
