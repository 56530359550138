import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { MindfullnessService } from 'src/app/services/mindfull/mindfullness.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-mindfullness-session',
  templateUrl: './edit-mindfullness-session.component.html',
  styleUrls: ['./edit-mindfullness-session.component.scss']
})
export class EditMindfullnessSessionComponent implements OnInit {
  editMindfullFormGroup!: FormGroup | any;
  _id: any = '';
  public Editor = ClassicEditor;
  fileData: any = '';
  default: boolean = false;
  image: any = '';
  baseUrl = environment.baseUrl;
  removeurl = ''
  imageurl = ''
  constructor(private actRoute: ActivatedRoute,
    private fb: FormBuilder,
    private mindfullnessService: MindfullnessService,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
    this.editMindfullFormGroup = this.fb.group({
      title: [''],
      description: [''],
      link: [''],
      image: ['']
    })
    this.getEducationalBlogInfo();

  }


  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.editMindfullFormGroup.get('image').setValue(fileInput.target.files[0]);
    this.default = true;
    this.preview();
    if (this.imageurl) {
      this.removeurl = this.imageurl;
    }
  }

  preview() {
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.image = reader.result;

    };
  }

  removeImage() {
    this.removeurl = this.imageurl;
    this.image = '';
  }


  get f() {
    return this.editMindfullFormGroup.controls;
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('sessionId', this._id);
    formData.append('image', this.f.image.value);
    formData.append('title', this.f.title.value);
    formData.append('description', this.f.description.value);
    formData.append('link', this.f.link.value);

    this.mindfullnessService.editMindfullnessSession(formData).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.toastr.success(res.message, res.status);
        this.router.navigateByUrl("/mindfullnesslist");
      } else {
        this.toastr.error(res.message, "Error")
      }
    });
  }

  getEducationalBlogInfo() {
    this._id = this.actRoute.snapshot.paramMap.get('id');
    let data = {
      blogId: this._id
    }
    console.log("datat", data)
    this.mindfullnessService.mindfullnessSessionDetials(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        if (res.data.coverImage) {
          this.image = res.data.coverImage
          this.imageurl = res.data.coverImage
          // this.imageurl = `/public/mindful/${res.data.coverImage}`
          // this.image = `${this.baseUrl}/public/mindful/${res.data.coverImage}`
          console.log("image", this.imageurl, this.image)
        }
        this.editMindfullFormGroup.patchValue({
          title: res.data.title,
          description: res.data.description,
          link: res.data.link
        })
      } else {
        this.toastr.error(res.message)
      }

    })

  }

}
