import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from '../auth/auth-service.service';
import { Observable, catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MindfullnessService {
  titlle = environment.title;
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, private authService: AuthServiceService) { }
  caregiverSignup(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/caregiver/signup`, data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  getmindFullnessList(data: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/caregiver/getMindfulnessSessionList?page=${data.page}&limit=${data.limit}&filter=${data.filter}&status=${data.status}`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }


  addMindfullnessSession(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/caregiver/create-mindfulness-session`, data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  editMindfullnessSession(data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/api/v1/caregiver/edit-mindfulness-session`, data)
        .pipe(
            catchError(this.handleError('getUser', []))
        );
}
mindfullnessSessionDetials(data: any): Observable<any> {
  return this.http.get(`${this.baseUrl}/api/v1/caregiver/get-mindfulness-session-details?sessionId=${data.blogId}`)
      .pipe(
          catchError(this.handleError('getUser', []))
      );
}
deteleSession(data: any): Observable<any> {
  return this.http.post(`${this.baseUrl}/api/v1/caregiver/delete-mindfulness-session`, data)
      .pipe(
          catchError(this.handleError('getUser', []))
      );
}

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("error", error.error, result); // log to console instead
      if (error.error.messageID == 401) {
        this.authService.logout()
        return of(error.error)
      }
      return of(error.error);
    };
  }

}
