<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3> Caregiver<strong class="fs-28 me-3"> <a class="fw-bold text-dark back-btn"
                                        routerLink="/admin-caregivers"><i class="bi bi-chevron-left"></i></a>
                                    {{caregiverDetail.firstName}} {{caregiverDetail.lastName}}</strong> </h3>
                        </div>
                    </div>
                    <div class="card shadow border-0 mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-lg-3 text-center mt-3">

                                    <div class="profile_pic">
                                        <img *ngIf="!image" src="../../../../assets/images/noImageUser.png" width="170"
                                            class="rounded m-auto " />
                                        <img *ngIf="image" [src]="image" width="170" height="170"
                                            class="rounded m-auto  " />
                                    </div>

                                </div>
                                <div class="col-12 col-md-9">
                                    <div class="container-fluid">
                                        <div class="row">

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>First Name</span>
                                                <input type="text" class="form-control"
                                                    value="{{firstName}}" placeholder="First Name" readonly>
                                            </div>

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Last Name</span>
                                                <input type="text" class="form-control"
                                                    value="{{lastName}}" placeholder="Last Name" readonly>
                                            </div>

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Date Of Birth</span>
                                                <input type="text" class="form-control"
                                                    value="{{dob | date: 'MM/dd/YYYY'}}"
                                                    placeholder="D.O.B" readonly>
                                            </div>

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Email</span>
                                                <input type="email" class="form-control"
                                                    value="{{email}}" placeholder="Email" readonly>
                                            </div>

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Mobile</span>
                                                <input type="number" class="form-control"
                                                    value="{{phone}}" placeholder="Mobile" readonly>
                                            </div>
                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>Experience</span>
                                                <input type="number" class="form-control"
                                                    value="{{experience}}" placeholder="Experience" readonly>
                                            </div>

                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>City</span>
                                                <input type="text" class="form-control" value="{{city}}"
                                                    placeholder="City" readonly>
                                            </div>
                                            <div class="col-12 col-lg-6 mb-3">
                                                <span>State</span>
                                                <input type="text" class="form-control" value="{{state}}"
                                                    placeholder="State" readonly>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>