import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserServiceService } from 'src/app/services/user/user-service.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  userDetail: any = [];

  constructor(private actRoute: ActivatedRoute, private userService: UserServiceService) { }

  ngOnInit(): void {
    this.getUserDetail();
  }
  getUserDetail() {
    let _id = this.actRoute.snapshot.paramMap.get('id');
  
    
    // let _id = "63c53f607b062115be4a0283";
    this.userService.usersDetail(_id).subscribe((data: any) => {
      this.userDetail = data.data;
      console.log(this.userDetail, "userDetailll");

    })
  }
}
