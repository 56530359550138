<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3><b>Add Educational Video</b> </h3>
                        </div>
                    </div>
                    <div class="card shadow border-0 mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-lg-3 text-center mt-3">
                                </div>
                                <div class="col-12 col-md-12">
                                    <div class="container-fluid">
                                        <form [formGroup]="videoUploadFormGroup" (submit)="onSubmit()">
                                            <div class="row">
                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Video Title</span>
                                                    <input type="text" class="form-control" formControlName="videoTitle"
                                                        placeholder="Video Title">
                                                </div>
                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Video Description</span>
                                                    <textarea formControlName="videoDescription" class="form-control"
                                                        placeholder="Write video description here" rows="10"
                                                        cols="30"></textarea>
                                                </div>
                                                <div class="col-12 col-lg-12 mb-3">
                                                    <input id="file-input" type="file" accept="video/*"
                                                        (change)="onFileSelected($event)" />
                                                </div>
                                            </div>
                                            <button class="btn btn-primary dark ">
                                                <i class="fa-solid fa-upload"></i> Upload Video</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>