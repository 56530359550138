import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements OnInit {
  otpValue: any;

  constructor(private authService: AuthServiceService, private router: Router, private autRoute: ActivatedRoute) { }

  ngOnInit(): void {
  }
  onOtpChange(event: any) {
    this.otpValue = event;
    console.log(this.otpValue, "otpValue");
  }

  verifyOTP() {
    let token = this.autRoute.snapshot.paramMap.get('token');
    let data = {
      otp: this.otpValue
    }
    this.authService.verifyOtp(data, token).subscribe((data: any) => {
      console.log(data, "valueee");

      this.router.navigate([`/admin-reset-password/${data.data.token}`])
    })
  }

  sendOtpAgain() {
    let token = this.autRoute.snapshot.paramMap.get('token');
    this.authService.resendOtp(token).subscribe((data: any) => {
    })
  }

}
