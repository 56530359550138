import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MobileAppFeedbackService {
    accessToken: any = localStorage.getItem('Token');
    titlle = environment.title;
    baseUrl = environment.baseUrl;

    constructor(private http: HttpClient) { }

    postMobileAppFeedback(data: any) {
        let header = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.post(`${this.baseUrl}/api/v1/user/mobile-app-feedback`, data, { headers: header });
    }

    getMobileAppFeedbackList(data: any) {
        let header = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(`${this.baseUrl}/api/v1/admin/mobile-app-feedback-list`, { headers: header });
    }

    getMobileAppFeedbackDetails(data: any) {
        let header = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(`${this.baseUrl}/api/v1/admin/mobile-app-feedback-details?mobileAppFeedbackId=${data.mobileAppFeedbackId}`, { headers: header });
    }
}
