<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main h-100">
    <section class="dashboard_section">
        <h1>Chat</h1>
        <div class="chatBox">
            <div class="toggleChatlist">
                <i class="fa-solid fa-bars"></i>
            </div>
            <div class="chatList">
                <div class="searchbar">
                    <input type="text" placeholder="Search" name="name" placeholder="Search by Name"
                        (keyup)="searchUser($event)" />
                    <button class="search">
                        <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                </div>
                <div style="text-align: center" *ngIf="caregiverlist.length == 0">
                    <h5>No Records Found</h5>
                </div>

                <ul>
                    <li class="active" style="margin-bottom: 5px; background-color: rgb(154, 226, 226);"
                        data-toggle="modal" data-target="#exampleModal">
                        <div class="profile" style="padding-bottom:5px; justify-content: center;">
                            <!-- <div class="image"> -->
                            <i class="fa-solid fa-users"></i>
                            <!-- <img src="../../../assets/images/avatar.png" alt="" /> -->
                            <!-- </div> -->
                            <div class="text">
                                <h3>Create Group</h3>
                            </div>
                        </div>
                    </li>
                    <ng-container *ngIf="caregiverlist.length > 0; else nodata">
                        <li class="active" *ngFor="let caregiver of caregiverlist" style="margin-bottom: 5px;">
                            <div class="profile" style="padding-bottom:5px;" (click)="selectUserForChat(caregiver._id)">
                                <div class="image" *ngIf="!caregiver.image">
                                    <img src="../../../assets/images/noImageUser.png" alt="" />
                                </div>
                                <div class="image" *ngIf="caregiver.image">
                                    <img src="{{profilePicUrl+caregiver.image}}" alt="" />
                                </div>
                                <div class="text">
                                    <h3>{{caregiver.firstName}} {{caregiver.lastName}}</h3>
                                    <p>
                                        Hello
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                    <ng-template #nodata>
                        <tr>
                            <td colspan="6" class="text-center">No Data Found</td>
                        </tr>
                    </ng-template>
                </ul>

                <div class="closeChatlist">
                    <i class="fa-solid fa-circle-chevron-left"></i>
                </div>
            </div>

            <div class="chat">
                <h3 class="contactName">{{caregiverName}}</h3>
                <div class="chatMessage" #scrollMe>
                    <ng-container *ngIf="chatData.length > 0">
                        <div class="row" *ngFor="let chat of chatData">
                            <div class="w-100 p-0">
                                <div [ngClass]="chat.sender == caregiverId ? 'receivedMessage' : 'sentMessage'">
                                    <!-- <div class="receivedMessage"> -->
                                    <div class="image" *ngIf="chat.sender == caregiverId">
                                        <img src="../../../assets/images/avatar.png" alt="" />
                                    </div>
                                    <p>{{chat.message}}</p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="sendMessage">
                    <form [formGroup]="chatMessageFormGroup" (submit)="chatMessageSend()" class="sendMessage">
                        <!-- <form> -->
                        <input placeholder="Type here.." type="text" formControlName="message" />
                        <button>
                            <i class="fa-solid fa-paper-plane"></i>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- Model -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"><b>Create Chat Group</b></h5> <button type="button"
                        class="close btn btn-danger" data-dismiss="modal" aria-label="Close"> <span
                            aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!-- <div class="col-12 col-md-5 col-lg-5"> <select class="form-select me-3" (click)="getPlan($event)">
                                <option disabled selected value="">Select Plan</option>
                                <option *ngFor="let product of products; index as i " value="{{product.name}}">
                                    {{product.name}}</option>
                            </select> </div> -->
                    <!-- <form [formGroup]="createGroupFormGroup" (submit)="createGroup()"> -->
                    <div class="form-group">
                        <ng-container *ngIf="caregiverlist.length > 0">
                            <div class="form-check" *ngFor="let caregiver of caregiverlist">
                                <input mdbCheckbox class="form-check-input" type="checkbox" value="{{caregiver._id}}"
                                    id="flexCheckDefault" (change)="selectUser($event)" />
                                <img src="../../../assets/images/avatar.png" alt=""
                                    style="width: 20px; border-radius: 50%;" />
                                <label class="form-check-label" for="flexCheckDefault">
                                    {{caregiver.firstName}} {{caregiver.lastName}}
                                </label>
                            </div>
                        </ng-container>
                    </div>
                    <div class="modal-footer"> <button class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button class="btn btn-primary" (click)="createGroup()" data-dismiss="modal">Create
                            Group</button>
                    </div>
                    <!-- </form> -->
                </div>
            </div>
        </div>
    </div>
</main>