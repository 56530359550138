import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StaticPagesService } from 'src/app/services/static/static-pages.service';
import { FormBuilder,FormGroup } from '@angular/forms';
import { catchError } from 'rxjs';

@Component({
  selector: 'app-edit-url',
  templateUrl: './edit-url.component.html',
  styleUrls: ['./edit-url.component.scss']
})
export class EditUrlComponent implements OnInit {
  urlList!: FormGroup | any;
  page: any = '';
  constructor(
    private actRoute: ActivatedRoute,private StaticPagesService:StaticPagesService ,
    private router: Router,
    private toastr: ToastrService
  ) { }


  ngOnInit(): void {
    
     // Subscribe to route parameter changes
  this.actRoute.paramMap.subscribe(params => {
    // Get the 'aboutus' parameter from the route
      this.page= params.get('page');
     
  });
  this.geturl(this.page)
  }


  url:string="";

  geturl(page:any) {
    
    this.StaticPagesService.getUrls(page).subscribe((res: any) => {
        if (res.messageID == 200 && res.data) {     
                 
          this.url=res.data.url
           
        } else {
            this.toastr.error(res.message)
        }

    })

}

setUrl(e: any) {
  this.url = e.target.value
}

onSubmit(e: any) {
  e.preventDefault()
 let data = {
  page: this.actRoute.snapshot.paramMap.get('page'),
        url: this.url,
      
      }
console.log("-----===-----",data)


      this.StaticPagesService.urlEdit(data)
      .pipe(
        catchError((err) => {
          console.log({err})
          return err
        })
      )
      .subscribe((res: any) => {
        console.log("hghg",res)
          if (res.messageID == 200 && res.data) {
              this.toastr.success(res.message, res.status);
              this.router.navigateByUrl("/url");
          } else {
              this.toastr.error(res.message, "Error")
          }
      });
}
}
