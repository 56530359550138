import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, of } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthServiceService } from "../auth/auth-service.service";

@Injectable({
  providedIn: "root",
})
export class BabyStagesService {
  titlle = environment.title;
  baseUrl = environment.baseUrl;
  constructor(
    private http: HttpClient,
    private authService: AuthServiceService
  ) {}

  babyStagesList(data: any): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/api/v1/baby-stages-list`)
      .pipe(catchError(this.handleError("getUser", [])));
  }

  babyStagesData(data: any): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/api/v1/baby-stages?week=${data}`)
      .pipe(catchError(this.handleError("getUser", [])));
  }

  babyStagesDataUpdate(data: any): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/api/v1/update-baby-stages`, data)
      .pipe(catchError(this.handleError("getUser", [])));
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.log("error", error.error, result); // log to console instead
      if (error.error.messageID == 401) {
        this.authService.logout();
        return of(error.error);
      }
      if (error.error.messageID == 403) {
        this.authService.logout();
        return of(error.error);
      }
      return of(error.error);
    };
  }
}
