import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  submitted: any = false;
  forgetPass!: FormGroup;
  constructor(private fb: FormBuilder, private authService: AuthServiceService, private router: Router) { }

  ngOnInit(): void {

    this.forgetPass = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })

  }

  get f() {
    return this.forgetPass.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.forgetPass.invalid) {
      return
    } else {
      let email = this.f.email.value;
      this.authService.forgetPassword({ email: email }).subscribe((data: any) => {
        this.router.navigate([`/admin-verify-otp/${data.data.token}`]);
      })
    }
  }

}
