<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <div class="container-fluid mb-4">
        <div class="row">
            <div class="col-12 col-md-5 col-lg-6 text-start">
                <h4 class="fs-28 fw-bold">
                    Assigned Caregivers List 
                    <!-- <a class="fw-bold text-dark back-btn" routerLink="/admin-users">
                        <i class="bi bi-chevron-left"><strong>Back</strong></i></a> -->
                </h4>
            </div>
            <div class="col-12 col-md-5 col-lg-3 text-start">
                <button class="btn btn-primary dark" data-target="#assign" data-toggle="modal">
                    <i class="fas fa-plus"></i>Assign Caregivers</button>
            </div>
            <div class="col-12 col-md-7 col-lg-3 text-end">
                <div class="input-group">
                    <input type="text" class="form-control border-end-0 form-control-lg" (keyup)="searchUser($event)"
                        placeholder="Search" style="font-size: 13px" />
                    <span class="input-group-text bg-white border-start-0"><i class="bi bi-search curser"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="card border-0 shadow">
        <div class="card-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 table-responsive">
                        <table class="table">
                            <thead class="d-lg-block-thead">
                                <tr>
                                    <th> First Name </th>
                                    <th> Last Name </th>
                                    <th> Phone Number </th>
                                    <th> Email </th>
                                    <th> Action </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="caregiverlist.length > 0; else nodata">
                                    <tr *ngFor="let caregiver of caregiverlist">
                                        <td data-label="First Name">{{caregiver.firstName}}</td>
                                        <td data-label="Last Name">{{caregiver.lastName}}</td>
                                        <td data-label="Contact No.">{{caregiver.phone}}</td>
                                        <td data-label="Email">{{caregiver.email}}</td>
                                        <td data-label="Action">
                                            <p class="d-flex justify-content-end justify-content-lg-start">
                                                <button
                                                    class="btn btn-danger rounded-circle btn-rounded shadow me-2 text-white">
                                                    <i class="bi bi-trash" title="Remove"
                                                        (click)="getUserStatus(caregiver)" data-target="#delete"
                                                        data-toggle="modal"></i>
                                                </button>

                                            </p>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-template #nodata>
                                    <tr>
                                        <td colspan="6" class="text-center">No Data Found</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Block Modal -->
    <div class="modal fade bd-example-modal-lg" id="assign" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"><b>Assign Caregivers </b></h5>
                    <button type="button" (click)="closeModal()" id="closeAssignModal" class="close"
                        data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card border-0 shadow">
                        <div class="card-body">
                            <div class="container-fluid">
                                <div class="text-end mb-3">
                                    <div class="input-group w-auto d-inline-flex">
                                        <input type="text" class="form-control border-end-0 form-control-lg"
                                            (keyup)="searchCaregiver($event)" placeholder="Search"
                                            style="font-size: 13px" />
                                        <span class="input-group-text bg-white border-start-0"><i
                                                class="bi bi-search curser"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 table-responsive">
                                        <table class="table">
                                            <thead class="d-lg-block-thead">
                                                <tr>
                                                    <th> First Name </th>
                                                    <th> Last Name </th>
                                                    <th> Experience </th>
                                                    <th> Email </th>
                                                    <th> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngIf="caregiverAllList.length > 0; else nodata">
                                                    <tr
                                                        *ngFor="let caregivers of caregiverAllList| paginate
                                                    : {itemsPerPage: limit,currentPage: page,id: 'second',totalItems: totaldocs}">
                                                        <td data-label="First Name">{{caregivers.firstName}}</td>
                                                        <td data-label="Last Name">{{caregivers.lastName}}</td>
                                                        <td data-label="Contact No.">{{caregivers.experience}} years
                                                        </td>
                                                        <td data-label="Email">{{caregivers.email}}</td>
                                                        <td data-label="Action">
                                                            <p
                                                                class="d-flex justify-content-end justify-content-lg-start">
                                                                <button
                                                                    class="btn btn-info rounded-circle btn-rounded shadow me-2 text-white">
                                                                    <i class="bi bi-plus" title="Add"
                                                                        (click)="assignCaregiverToPatient(caregivers)">Assign</i>
                                                                </button>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                <ng-template #nodata>
                                                    <tr>
                                                        <td colspan="6" class="text-center">No Data Found</td>
                                                    </tr>
                                                </ng-template>
                                            </tbody>
                                        </table>
                                        <div class="paginator" style="float: right;">
                                            <pagination-controls (pageChange)="pageChange($event)"
                                                id="second"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="closeModal()"
                        data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Delete Modal -->
    <div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"><b>Confirmation</b></h5>
                    <button type="button" (click)="closeModal()" id="closeUserDeleteModal" class="close"
                        data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    Are you sure you want to remove the <b>{{userName}}?</b>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="closeModal()"
                        data-dismiss="modal">No</button>
                    <button type="button" class="btn btn-primary" (click)="deleteUser()">Yes</button>
                </div>
            </div>
        </div>
    </div>


</main>