<nav class="navbar navbar-expand-lg real-navbar">
  <div class="container">
    <a class="navbar-brand" href="#">
      <img src=".././../assets/img/real5-logo.png" alt="" />
    </a>
    <div class="collapse navbar-collapse">
      <div class="sub_navbar">
        <h3 style="color:aliceblue">SimplyBirthed</h3>
      </div>
      <img style="width: 80px;height:80px" src="../../../assets/images/simplyBirthed_logo.jpg" alt="">
    </div>
  </div>
</nav>