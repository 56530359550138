import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Socket, io } from 'socket.io-client';

@Component({
  selector: 'app-mainheader',
  templateUrl: './mainheader.component.html',
  styleUrls: ['./mainheader.component.scss']
})
export class MainheaderComponent implements OnInit {
  socket: any;
  userId: any;

  constructor(private router: Router) {
    this.userId = localStorage.getItem('_id');

  }

  ngOnInit(): void {
    this.socket = io(environment.baseUrl, { transports: ['websocket', 'polling', 'flashsocket'] });

  }
  logout() {
    this.socket.emit('disconnectUser', { userId: localStorage._id, });

    localStorage.removeItem("Role");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("_id");
    localStorage.removeItem("Token");
    localStorage.removeItem("Name");
    localStorage.removeItem("isLoggedIn");
    this.router.navigate(['./login']);
  }
}
