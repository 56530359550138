import { Component, OnInit } from '@angular/core';
import { ConsultationFeedbackService } from 'src/app/services/consultation-feedback/consultation-feedback.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-consultation-feedback-details',
  templateUrl: './consultation-feedback-details.component.html',
  styleUrls: ['./consultation-feedback-details.component.scss']
})
export class ConsultationFeedbackDetailsComponent implements OnInit {
    consultationFeedbackDetails: any = ''
    constructor(
        private consultationFeedbackService: ConsultationFeedbackService,
        private actRoute: ActivatedRoute,
  ) { }

    ngOnInit(): void {
        let data = {
            appointmentId: this.actRoute.snapshot.paramMap.get('id')
        };
        this.consultationFeedbackDetailsFunction(data);
  }
    
    consultationFeedbackDetailsFunction(data: any) {
        this.consultationFeedbackService.consultationFeedbackDetails(data).subscribe((data: any) => {
            // console.log(data, "data");
            this.consultationFeedbackDetails = data.data
            // console.log(this.consultationFeedbackDetails, "this.consultationFeedbackDetails");
        })
    }

}
