import { getLocaleFirstDayOfWeek } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CaregiverServiceService } from 'src/app/services/caregiver/caregiver-service.service';
import { UserServiceService } from 'src/app/services/user/user-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-caregiver-edit',
  templateUrl: './caregiver-edit.component.html',
  styleUrls: ['./caregiver-edit.component.scss']
})

export class CaregiverEditComponent implements OnInit {
  caregiverForm!: FormGroup | any;
  submitted: boolean = false;

  firstName: any = '';
  _id: any = '';
  fileData: any = '';
  default: boolean = false;
  image: any = '';
  baseUrl = environment.baseUrl;
  removeurl = ''
  imageurl = ''
  description = ''
  designation=''

  constructor(private actRoute: ActivatedRoute,
    private caregiverService: CaregiverServiceService,
    private userService: UserServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.caregiverForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      experience: [''],
      dob: [''],
      city: [''],
      state: [''],
      image: [''],
      description: [''],
      designation:[''],
    })

    this.getCaregiverInfo();
  }

  get f() {
    return this.caregiverForm.controls;
  }

  getCaregiverInfo() {
    this._id = this.actRoute.snapshot.paramMap.get('id');
    this.userService.usersDetail(this._id).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        console.log(res.data.docs.image, "data.data");
        // if (res.data.image) {
        //   this.imageurl = `/public/caregiver_picture/${res.data.image}`
        //   this.image = `${this.baseUrl}/public/caregiver_picture/${res.data.image}`
        // }
        this.image = res.data?.docs?.image,
        this.caregiverForm.patchValue({
          firstName: res.data.docs.firstName,
          lastName: res.data.docs.lastName,
          phone: res.data.docs.phone,
          experience: res.data?.docs.experience,
          dob: res.data?.docs.dob,
          email: res.data?.docs.email,
          city: res.data?.docs.address?.city,
          state: res.data?.docs.address?.state,
          image:res.data?.docs?.image,
          description: res.data?.docs?.description,
          designation: res.data?.docs?.designation,
        })
      } else {
        this.toastr.error(res.message);
      }
    })

  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.caregiverForm.get('image').setValue(fileInput.target.files[0]);
    this.default = true;
    this.preview();
    if (this.imageurl) {
      this.removeurl = this.imageurl;
    }
  }

  preview() {
    // Show preview
    var mimeType = this.fileData.type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.image = reader.result;

    };
  }

  removeImage() {
    this.removeurl = this.imageurl;
    this.image = '';
  }

  onSubmit() {
    this.submitted = true;

    if (this.caregiverForm.invalid) {
      return
    } else {

      const formData = new FormData();

      formData.append('_id', this._id);
      formData.append('image', this.f.image.value);
      formData.append('firstName', this.f.firstName.value);
      formData.append('lastName', this.f.lastName.value);
      formData.append('email', this.f.email.value);
      formData.append('experience', this.f.experience.value);
      formData.append('phone', this.f.phone.value);
      formData.append('dob', this.f.dob.value);
      formData.append('address_city', this.f.city.value);
      formData.append('address_state', this.f.state.value);
      formData.append('removeurl', this.removeurl);
      formData.append('description',  this.f.description.value);
      formData.append('designation',  this.f.designation.value);

      this.caregiverService.caregiverEdit(formData).subscribe((res: any) => {
        if (res.messageID == 200 && res.data) {
          this.toastr.success(res.message, res.status)
          this.getCaregiverInfo();
          this.router.navigate(['/admin-caregivers']);
        } else {
          this.toastr.error(res.message);
        }
      })
    }
  }
}