import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BabyStagesService } from "src/app/services/baby-stages/baby-stages.service";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-edit-stages',
  templateUrl: './edit-stages.component.html',
  styleUrls: ['./edit-stages.component.scss']
})
export class EditStagesComponent implements OnInit {
  babyStage!: FormGroup | any;
  _id: any = "";
  updateId: any = "";
  title = "angular";
  public Editor = ClassicEditor;
  fileData: any = "";
  default: boolean = false;
  image: any = "";
  baseUrl = environment.baseUrl;
  removeurl = "";
  imageurl = "";
  constructor(
    private actRoute: ActivatedRoute,
    private fb: FormBuilder,
    private BabyStagesService: BabyStagesService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.babyStage = this.fb.group({
      heading: [""],
      description: [""],
      image: [""],
    });
    this.getEducationalBlogInfo();
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.babyStage
      .get("image")
      .setValue(fileInput.target.files[0]);
    this.default = true;
    this.preview();
    if (this.imageurl) {
      this.removeurl = this.imageurl;
    }
  }

  preview() {
    // Show preview
    var mimeType = this.fileData.type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.image = reader.result;
    };
  }

 

  get f() {
    return this.babyStage.controls;
  }

  onSubmit() {
    // let data = {
    //   stageId: this.actRoute.snapshot.paramMap.get("id"),
    //   heading: this.f.blogTitle.value,
    //   description: this.f.blogContent.value,
    //   image: this.f.image
    // };
    const formData = new FormData();
    formData.append("stageId", this.updateId);
    formData.append("image", this.f.image.value);
    formData.append("heading", this.f.heading.value);
    formData.append("description", this.f.description.value);

    this.BabyStagesService
      .babyStagesDataUpdate(formData)
      .subscribe((res: any) => {
        if (res.messageID == 200 && res.data) {
          this.toastr.success(res.message, res.status);
          this.router.navigateByUrl("/baby-stages");
        } else {
          this.toastr.error(res.message, "Error");
        }
      });
  }

  getEducationalBlogInfo() {
    this._id = this.actRoute.snapshot.paramMap.get("id");
    // let data = {
    //   babyStages: this._id,
    // };
    // console.log("datat", data);
    this.BabyStagesService
      .babyStagesData(this._id)
      .subscribe((res: any) => {
        
        if (res.messageID == 200 && res.data) {
          this.imageurl = res.data.uri
          this.image = res.data.uri
          this.updateId = res.data._id
          // if (res.data.uri) {
          //   (this.imageurl = `/public/caregiver_picture/${res.data.coverImage}`),
          //     (this.image = res.data.coverImage);
          //   // this.image = `${this.baseUrl}/public/caregiver_picture/${res.data.coverImage}`
          //   console.log("image", this.imageurl, this.image);
          // }
          this.babyStage.patchValue({
            heading: res.data.heading,
            description: res.data.description,
          });
        } else {
          this.toastr.error(res.message);
        }
      });
  }
}