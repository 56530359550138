import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminServiceService } from 'src/app/services/admin-service/admin-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-service',
  templateUrl: './admin-service.component.html',
  styleUrls: ['./admin-service.component.scss']
})
export class AdminServiceComponent implements OnInit {
  _id: any = '';
  service_id:any=[]
  serviceAdd!: FormGroup | any;
  ServiceLists:any =[]
  constructor(private actRoute: ActivatedRoute,private AdminServiceService:AdminServiceService ,
    private router: Router,
    private toastr: ToastrService) { }
    totaldocs: any = 0;
  ngOnInit(): void {

    this.ServiceList({});
  }

  ServiceList(data:any) {
    this.AdminServiceService.serviceList(data).subscribe((res: any) => {
      console.log({res})
      if (res.messageID == 200 && res.data) {
        this.toastr.success(res.message);
        this.ServiceLists = res.data;
      
    
      } else {
        this.toastr.error(res.message);
      }
     
    })
  }

  truncateText(text: string, wordCount: number): string {
    const words = text.split(' ');
    if (words.length <= wordCount) {
      return text;
    }
    const truncatedText = words.slice(0, wordCount).join(' ');
    return `${truncatedText} ......`;
  }

  deleteService(product_id: string) {
   
    let data = {
      product_id: product_id,
    };

    this.AdminServiceService.serviceDelete(data).subscribe((res: any) => {
  
      if (res.messageID == 200 && res.data) {
        this.toastr.success(res.message, res.status)
        let element: HTMLElement = document.getElementById('closeUserDeleteModal') as HTMLElement;
        element.click();
       
        this.ServiceList(data);
        this.ngOnInit()
        
      } else {
        this.toastr.error(res.message)
      }

    })
  }




}
