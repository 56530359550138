<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="dashboard_section">
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-md-4">
                <div class="box nurshing" routerLink="/about-us">
                    <div class="circle"></div>
                    <div class="lBox">
                        <h2>About US</h2>
                        
                    </div>
                    <div class="rBox">
                        <img src="../../assets/images/nurshing_card.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box nurse" routerLink="/terms-condition">
                    <div class="circle"></div>
                    <div class="lBox">
                        <h2>Terms and Conditions</h2>
                        
                    </div>
                    <div class="rBox">
                        <img src="../../assets/images/nurse_card.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
      
            <div class="col-md-4">
                <div class="box physician" routerLink="/findQA" >
                    <div class="circle"></div>
                    <div class="lBox">
                        <h2>fAQ'S</h2>
                        
                    </div>
                    <div class="rBox">
                        <img src="../../assets/images/doc_card.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="box physician" routerLink="/how-works">
                <div class="circle"></div>
                <div class="lBox">
                    <h2>How it work's</h2>
                    
                </div>
                <div class="rBox">
                    <img src="../../assets/images/doc_card.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
    
      
    </section>
</main>