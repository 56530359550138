import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CaregiverServiceService } from 'src/app/services/caregiver/caregiver-service.service';
import { UserServiceService } from 'src/app/services/user/user-service.service';

@Component({
  selector: 'app-assigned-caregiver',
  templateUrl: './assigned-caregiver.component.html',
  styleUrls: ['./assigned-caregiver.component.scss']
})
export class AssignedCaregiverComponent implements OnInit {
  page: number = 1;
  limit: number = 6;
  filter: any = '';
  caregiverlist: any = [];
  isBlocked: boolean = false;
  caregiver_id: any;
  userName: any = '';
  status: any = '';
  totaldocs: any = 0;
  caregiverAllList: any = [];
  constructor(private userService: UserServiceService,
    private toastr: ToastrService,
    private caregiverService: CaregiverServiceService,
    private actRoute: ActivatedRoute,) { }

  ngOnInit(): void {
    let data = {
      userId: this.actRoute.snapshot.paramMap.get('id'),
      caregiverName: this.filter,
    };
    this.caregiverList(data);

    let dataFilter = {
      filter: this.filter,
      status: this.status,
      page: this.page,
      limit: this.limit
    };
    this.caregiversList(dataFilter);
  }

  caregiverList(data: any) {
    this.userService.assignCaregiverList(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.toastr.success(res.message+"Hey bro")
        this.caregiverlist = res.data[0].caregivers;
        console.log("caregiverlist", res.data)
      } else {
        this.toastr.error("Data Not found")
      }
    })
  }
  searchUser(event: any) {
    let data = {
      userId: this.actRoute.snapshot.paramMap.get('id'),
      caregiverName: event.target.value,
    };
    this.caregiverList(data);
  }

  getUserStatus(data: any) {
    console.log("data", data)
    this.userName = data.firstName + " " + data.lastName;
    this.caregiver_id = data._id;
    this.isBlocked = data.isBlocked;
    if (this.isBlocked == true) {
      this.isBlocked = false
    } else {
      this.isBlocked = true;
    }
  }
  closeModal() {
    let data = {
      userId: this.actRoute.snapshot.paramMap.get('id'),
      caregiverName: this.filter,
    };
    this.caregiverList(data);
  }
  deleteUser() {
    let dataa = {
      userId: this.actRoute.snapshot.paramMap.get('id'),
      caregiverName: this.filter,
    };
    let data = {
      userId: this.actRoute.snapshot.paramMap.get('id'),
      caregiversId: this.caregiver_id,
    };
    this.userService.removeAssignCaregiver(data).subscribe((data: any) => {
      this.toastr.success(data.message, data.status)
      let element: HTMLElement = document.getElementById('closeUserDeleteModal') as HTMLElement;
      element.click();
      this.caregiverList(dataa);
    })
  }

  caregiversList(dataFilter: any) {
    this.caregiverService.getCaregiverList(dataFilter).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.toastr.success(res.message);
        this.caregiverAllList = res.data.docs;
        this.totaldocs = res.data.totalDocs;
      } else {
        this.toastr.error(res.message);
      }
    })
  }

  assignCaregiverToPatient(data1: any) {
    console.log("test", data1)
    let dataa = {
      userId: this.actRoute.snapshot.paramMap.get('id'),
      caregiverName: this.filter
    };
    let data = {
      userId: this.actRoute.snapshot.paramMap.get('id'),
      caregiversId: data1._id
    }; this.userService.assignCaregiver(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.toastr.success(res.message, res.status)
        let element: HTMLElement = document.getElementById('closeAssignModal') as HTMLElement;
        element.click();
        this.caregiverList(dataa);
      } else {
        this.toastr.warning(res.message)
      }
    })
  }
  pageChange(page: any) {
    let data = {
      filter: this.filter,
      status: this.status,
      page: page,
      limit: this.limit
    };
    this.caregiverAllList(data)
  }
  searchCaregiver(event: any) {
    let data = {
      filter: event.target.value,
      status: this.status,
      page: this.page,
      limit: this.limit
    };
    this.caregiversList(data)
  }
}
