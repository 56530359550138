import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  MaxLengthValidator,
  MinLengthValidator,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CaregiverServiceService } from "src/app/services/caregiver/caregiver-service.service";

@Component({
  selector: "app-add-caregiver",
  templateUrl: "./add-caregiver.component.html",
  styleUrls: ["./add-caregiver.component.scss"],
})
export class AddCaregiverComponent implements OnInit {
  caregiverSignup!: FormGroup | any;
  submitted: boolean = false;
  fileData: any = false;
  default: boolean = false;
  isLocalCaregiver: boolean = false;

  image: any = "";
  email: any = "";
  password: any = "";
  description: any = "";
  designation:any = "";

  constructor(
    private fb: FormBuilder,
    private caregiverService: CaregiverServiceService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.caregiverSignup = this.fb.group(
      {
        firstName: [""],
        lastName: [""],
        email: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
            ),
          ],
        ],
        dob: [""],
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(
              "(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=.*[!@#$%^&*]).{6,}"
            ),
          ],
        ],
        confirmPasswword: ["", [Validators.required]],
        phone: ["", [Validators.required, Validators.pattern(/^\d{10,13}$/)]],
        experience: [""],
        image: [""],
        city: [""],
        state: [""],
        description: [""],
        isLocalCaregiver: this.isLocalCaregiver,
        designation: [""],
      },
      {
        validator: this.ConfirmedValidator("password", "confirmPasswword"),
      }
    );
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    console.log(this.fileData, "image_path");

    this.caregiverSignup.get("image").setValue(fileInput.target.files[0]);
    this.default = true;
    this.preview();
  }

  preview() {
    // Show preview
    var mimeType = this.fileData.type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.image = reader.result;
      console.log(_event, "eventtt");
    };
  }
  removeImage() {
    this.image = "";
    this.f.image.value = this.image;
  }

  get f() {
    return this.caregiverSignup.controls;
  }
  sendMail(email: any, password: any) {
    console.log(email, "=email", password, "=password");
  }

  onSubmit() {
    this.submitted = true;
    console.log("submit", this.caregiverSignup.value)
    if (this.caregiverSignup.invalid) {
      return;
    } else {
      const formData = new FormData();
      formData.append("image", this.f.image.value);
      formData.append("firstName", this.f.firstName.value);
      formData.append("lastName", this.f.lastName.value);
      formData.append("email", this.f.email.value);
      formData.append("password", this.f.password.value);
      formData.append("experience", this.f.experience.value);
      formData.append("phone", this.f.phone.value);
      formData.append("dob", this.f.dob.value);
      formData.append("city", this.f.city.value);
      formData.append("state", this.f.state.value);
      formData.append("isLocalCaregiver", this.f.isLocalCaregiver.value);
      formData.append("description", this.f.description.value);
      formData.append("role", "3");
      formData.append("designation", this.f.designation.value);

      this.email = this.f.email.value;
      this.password = this.f.password.value;


      this.caregiverService.caregiverSignup(formData).subscribe((res: any) => {
        if (res.messageID == 200 && res.data) {
          this.toastr.success(res.message);
          this.sendMail(this.email, this.password);
          this.toastr.success(res.message, res.status);
          this.router.navigate(["/admin-caregivers"]);
        } else {
          this.toastr.error(res.message);
        }
      });
    }
  }

  localCaregiverAction(event: any) {
    if (event == true) {
      this.isLocalCaregiver = false;
    } else {
      this.isLocalCaregiver = true;
    }
  }
}
