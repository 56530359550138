import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  submitted: boolean = false;
  changePass!: FormGroup | any;
  token: any;

  constructor(private fb: FormBuilder,
    private authRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthServiceService) { }

  ngOnInit(): void {
    this.token = this.authRoute.snapshot.paramMap.get('token');

    this.changePass = this.fb.group({
      newPassword: ['', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=.*[!@#\$%\^&\*]).{6,}')]],
      confirmPasswword: ['', [Validators.required]]
    }, {
      validator: this.ConfirmedValidator('newPassword', 'confirmPasswword'),
    })
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  get f() {
    return this.changePass.controls
  }

  onSubmit() {
    this.submitted = true;
    if (this.changePass.invalid) {
      console.log("invalid");

      return
    } else {
      console.log("success");
      let newPassword = {
        password: this.f.newPassword.value
      }
      this.authService.changePassword(newPassword, this.token).subscribe((res: any) => {
        if (res.responseCode == 200 && res.data) {
          this.toastr.success(res.message, res.status)
          localStorage.setItem("Token", res.data.token)
          localStorage.setItem("Role", res.data.role)
          localStorage.setItem("_id", res.data._id)
          localStorage.setItem("isLoggedIn", "true");
          this.router.navigate(['/dashboard']);
        } else {
          this.toastr.error(res.message)
        }
      })
    }
  }

}
