import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-find-ques-ans',
  templateUrl: './find-ques-ans.component.html',
  styleUrls: ['./find-ques-ans.component.scss']
})
export class FindQuesAnsComponent implements OnInit {


  addQuiz!: FormGroup | any;
  title = 'angular';
  public Editor = ClassicEditor;
  fileData: any = false;
  default: boolean = false;
  image: any = '';
  constructor(
      private fb: FormBuilder,
      private QuizService: QuizService,
      private toastr: ToastrService,
      private router: Router
  ) { }

  ngOnInit(): void {
      this.addQuiz = this.fb.group({
        quizTitle: [''],
        quizContent: [''],
          image: [''],
          uri:['']
      })
  }
  fileProgress(fileInput: any) {
      this.fileData = <File>fileInput.target.files[0];
      console.log(this.fileData, "image_path");

      this.addQuiz.get('image').setValue(fileInput.target.files[0]);
      this.default = true;
      this.preview();
  }

  preview() {
      // Show preview
      var mimeType = this.fileData.type;

      if (mimeType.match(/image\/*/) == null) {
          return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = (_event) => {
          this.image = reader.result;
          console.log(_event, "eventtt");

      };
  }
  removeImage() {
      this.image = '';
      this.f.image.value = this.image;
  }

  get f() {
      return this.addQuiz.controls;
  }

  onSubmit() {
      const formData = new FormData();
      formData.append('image', this.f.image.value);
      formData.append('quizTitle', this.f.quizTitle.value);
      formData.append('quizContent', this.f.quizContent.value);
      formData.append('uri', this.f.uri.value);
      this.QuizService.addQuiz(formData).subscribe((res: any) => {
          if (res.messageID == 200 && res.data) {
            
          
          } else {
              this.toastr.error(res.message, "Error")
          }
      })
  }


}
