import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Socket, io } from 'socket.io-client';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';

@Component({
    selector: 'app-broadcast-messaging',
    templateUrl: './broadcast-messaging.component.html',
    styleUrls: ['./broadcast-messaging.component.scss']
})
export class BroadcastMessagingComponent implements OnInit {
    broadcastMessageFormGroup!: FormGroup | any;
    userType: any = [2, 3];
    userId: any;
    socket: any;

    constructor(
        private fb: FormBuilder,
        public authService: AuthServiceService,
        private toastr: ToastrService,
        private router: Router) {
        this.userId = localStorage.getItem('_id');
    }

    ngOnInit(): void {
        this.socket = io(environment.baseUrl, { transports: ['websocket', 'polling', 'flashsocket'] });
        if (this.authService.isAuth()) {
            this.socket.emit('connectUser', { userId: localStorage._id, });
        }
        this.broadcastMessageFormGroup = this.fb.group({
            broadcastMessage: ['', [Validators.required]],
        })
    }

    get f() {
        return this.broadcastMessageFormGroup.controls;
    }

    onSubmit() {
        if (this.broadcastMessageFormGroup.invalid) {
            this.toastr.warning("Please enter message")
            return
        }
        let data = {
            sender: this.userId,
            message: this.f.broadcastMessage.value,
            userType: this.userType
        }
        console.log("datadatadatadata",data)
        this.socket.emit('broadcastMessage', data)
        this.ack()
    }
    ack() {
        this.socket.on('brodcastacknowledgement', (res: any) => {
            if (res) {
                this.broadcastMessageFormGroup.reset();
                this.router.navigate(['/admin-broadcast-messaging']);
                this.toastr.success("Broadcast successfully send")
            }
        });

    }
    selectOption(event: any) {
        this.userType = []
        let e = Number(event.target.value)
        if (e == 3) {
            this.userType.push(e)
        } else if (e == 2) {
            this.userType.push(e)
        } else {
            this.userType.push(2, 3)
        }

        console.log("user type", event.target.value, this.userType);
    }

}
