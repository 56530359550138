import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserServiceService } from 'src/app/services/user/user-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-caregiver-profile',
  templateUrl: './caregiver-profile.component.html',
  styleUrls: ['./caregiver-profile.component.scss']
})
export class CaregiverProfileComponent implements OnInit {
  caregiverDetail: any = [];
  city: any = '';
  state: any = '';
  email: any = '';
  phone: any = '';
  baseUrl = environment.baseUrl;
  image: any = '';
  experience: any = ''
  dob: any = '';
  firstName: any = '';
  lastName: any = '';
  constructor(private userService: UserServiceService, private actRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.getCaregiverDetail();
  }

  getCaregiverDetail() {
    let _id = this.actRoute.snapshot.paramMap.get('id');

    console.log("sdsfsdfsd", _id);
    // let _id = "63cf6f7af7fb228f88fff1b2";
    this.userService.usersDetail(_id).subscribe((data: any) => {
      console.log("data,", data.data.docs)

      if (data.data.docs.image) {
        // this.image = `${this.baseUrl}/public/caregiver_picture/${data.data.image}`
        console.log(data.data.docs.image, "use profile image >>>>>>>>")
        this.image = data.data.docs.image
      }

      this.caregiverDetail = data.data.docs;
      this.city = data.data.docs.address.city;
      this.state = data.data.docs.address.state;
      this.email = data.data.docs.email;
      this.phone = data.data.docs.phone;
      this.experience = data.data.docs.experience;
      this.dob = data.data.docs.dob;
      this.firstName = data.data.docs.firstName;
      this.lastName = data.data.docs.lastName;
    })
  }

}