import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminSidebarComponent } from './admin/admin-sidebar/admin-sidebar.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { CaregiversComponent } from './admin/caregivers/caregivers.component';
import { CaregiverProfileComponent } from './admin/caregivers/caregiver-profile/caregiver-profile.component';
import { UsersComponent } from './admin/users/users.component';
import { UserProfileComponent } from './admin/users/user-profile/user-profile.component';
import { AllChatsComponent } from './admin/chat-management/all-chats/all-chats.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SubscribersListComponent } from './admin/subscribers-list/subscribers-list.component';
import { SubscriberInfoComponent } from './admin/subscribers-list/subscriber-info/subscriber-info.component';
import { ContentManagementComponent } from './admin/content-management/content-management.component';
import { SurveyInputsComponent } from './admin/survey-inputs/survey-inputs.component';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './admin/login/login.component';
import { HeaderComponent } from './admin/header/header.component';
import { UserEditComponent } from './admin/users/user-edit/user-edit.component';
import { CaregiverEditComponent } from './admin/caregivers/caregiver-edit/caregiver-edit.component';
import { AddCaregiverComponent } from './admin/caregivers/add-caregiver/add-caregiver.component';
import { FormGroup } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ForgetPasswordComponent } from './admin/forget-password/forget-password.component';
import { VerifyOtpComponent } from './admin/verify-otp/verify-otp.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { ResetPasswordComponent } from './admin/reset-password/reset-password.component';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminProfileComponent } from './admin/admin-profile/admin-profile.component';
import { PageNotFoundComponent } from './admin/page-not-found/page-not-found.component';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { SubscriptionPlansComponent } from './admin/subscription-plans/subscription-plans.component';
import { ConsultationFeedbackComponent } from './admin/consultation-feedback/consultation-feedback.component';
import { EducationalArticleComponent } from './admin/educational-article/educational-article.component';
import { AddEducationalArticleComponent } from './admin/educational-article/add-educational-article/add-educational-article.component';
import { EditEducationalArticleComponent } from './admin/educational-article/edit-educational-article/edit-educational-article.component';
import { BroadcastMessagingComponent } from './admin/broadcast-messaging/broadcast-messaging.component';
import { ConsultationFeedbackDetailsComponent } from './admin/consultation-feedback/consultation-feedback-details/consultation-feedback-details.component';
import { EducationalVideosComponent } from './admin/educational-videos/educational-videos.component';
import { AddEducationalVideoComponent } from './admin/educational-videos/add-educational-video/add-educational-video.component';
import { DetailsEducationalVideoComponent } from './admin/educational-videos/details-educational-video/details-educational-video.component';
import { ChatTestComponent } from './admin/chat-test/chat-test/chat-test.component';
import { MobileAppFeedbackComponent } from './admin/mobile-app-feedback/mobile-app-feedback.component';
import { MobileAppFeedbackDetailsComponent } from './admin/mobile-app-feedback/mobile-app-feedback-details/mobile-app-feedback-details.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MainheaderComponent } from './admin/mainheader/mainheader.component';
import { InterceptorModule } from "./shared/interceptor.module";
import { AuthGuard } from './shared/guard/auth.guard';
import { EditCmsComponent } from './admin/content-management/edit-cms/edit-cms.component';
import { FaqComponent } from './admin/faq/faq.component';
import { EditFaqComponent } from './admin/faq/edit-faq/edit-faq.component';
import { AddFaqComponent } from './admin/faq/add-faq/add-faq.component';
import { MindfullnessSessionComponent } from './admin/mindfullness-session/mindfullness-session.component';
import { AddMindfullnessSessionComponent } from './admin/mindfullness-session/add-mindfullness-session/add-mindfullness-session.component';
import { EditMindfullnessSessionComponent } from './admin/mindfullness-session/edit-mindfullness-session/edit-mindfullness-session.component';
import { AssignedCaregiverComponent } from './admin/users/assigned-caregiver/assigned-caregiver.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BrodcastlistComponent } from './admin/broadcast-messaging/brodcastlist/brodcastlist.component';
import { AdminServiceComponent } from './admin/admin-service/admin-service.component';
import { AddServiceComponent } from './admin/admin-service/add-service/add-service.component';
import { EditServiceComponent } from './admin/admin-service/edit-service/edit-service.component';
import { QuizComponent } from './admin/quiz/quiz.component';
import { AddQuizComponent } from './admin/quiz/add-quiz/add-quiz.component';
import { EditQuizComponent } from './admin/quiz/edit-quiz/edit-quiz.component';
import { StatisPagesComponent } from './admin/statis-pages/statis-pages.component';
import { AboutUsComponent } from './admin/statis-pages/about-us/about-us.component';
import { TermsConditionComponent } from './admin/statis-pages/terms-condition/terms-condition.component';
import { HowWorksComponent } from './admin/statis-pages/how-works/how-works.component';
import { FindQuesAnsComponent } from './admin/statis-pages/find-ques-ans/find-ques-ans.component';
import { UrlComponent } from './admin/url/url.component';
import { EditUrlComponent } from './admin/url/edit-url/edit-url.component';
import { PrivacyPolicyComponent } from './admin/statis-pages/privacy-policy/privacy-policy.component';
import { BabyStagesComponent } from './admin/baby-stages/baby-stages.component';
import { AddStagesComponent } from './admin/baby-stages/add-stages/add-stages.component';
import { EditStagesComponent } from './admin/baby-stages/edit-stages/edit-stages.component';
import { PolicyCaregiverComponent } from './admin/statis-pages/policy-caregiver/policy-caregiver.component';




@NgModule({
    declarations: [
        AppComponent,
        AdminSidebarComponent,
        AdminDashboardComponent,
        CaregiversComponent,
        CaregiverProfileComponent,
        UsersComponent,
        UserProfileComponent,
        AllChatsComponent,
        SubscribersListComponent,
        SubscriberInfoComponent,
        ContentManagementComponent,
        SurveyInputsComponent,
        LoginComponent,
        HeaderComponent,
        UserEditComponent,
        CaregiverEditComponent,
        AddCaregiverComponent,
        ForgetPasswordComponent,
        VerifyOtpComponent,
        ResetPasswordComponent,
        AdminProfileComponent,
        PageNotFoundComponent,
        SubscriptionPlansComponent,
        ConsultationFeedbackComponent,
        EducationalArticleComponent,
        AddEducationalArticleComponent,
        EditEducationalArticleComponent,
        BroadcastMessagingComponent,
        ConsultationFeedbackDetailsComponent,
        EducationalVideosComponent,
        AddEducationalVideoComponent,
        DetailsEducationalVideoComponent,
        ChatTestComponent,
        MobileAppFeedbackComponent,
        MobileAppFeedbackDetailsComponent,
        MainheaderComponent,
        EditCmsComponent,
        FaqComponent,
        EditFaqComponent,
        AddFaqComponent,
        MindfullnessSessionComponent,
        AddMindfullnessSessionComponent,
        EditMindfullnessSessionComponent,
        AssignedCaregiverComponent,
        BrodcastlistComponent,
        AdminServiceComponent,
        AddServiceComponent,
        EditServiceComponent,
        QuizComponent,
        AddQuizComponent,
        EditQuizComponent,
        StatisPagesComponent,
        AboutUsComponent,
        TermsConditionComponent,
        HowWorksComponent,
        FindQuesAnsComponent,
        UrlComponent,
        EditUrlComponent,
        PrivacyPolicyComponent,
        BabyStagesComponent,
        AddStagesComponent,
        EditStagesComponent,
        PolicyCaregiverComponent,
        
       
        
    ],
    imports: [
        BrowserModule,
        RouterModule,
        AppRoutingModule,
        NgxPaginationModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgOtpInputModule,
        CKEditorModule,
        // ToastrModule.forRoot({
        //     positionClass: 'toast-top-right'
        // }),
        ToastrModule.forRoot({
            progressBar: true,
            maxOpened: 1,
            preventDuplicates: true,
        }),
        NgxUiLoaderModule.forRoot({
            bgsColor: "red",
            bgsOpacity: 0.5,
            bgsPosition: "bottom-right",
            bgsSize: 60,
            bgsType: "ball-spin-clockwise",
            blur: 5,
            delay: 0,
            fastFadeOut: true,
            fgsColor: "#ffffff",
            fgsPosition: "center-center",
            fgsSize: 60,
            fgsType: "three-strings",
            gap: 24,
            logoPosition: "center-center",
            logoSize: 120,
            logoUrl: "",
            masterLoaderId: "master",
            overlayBorderRadius: "0",
            overlayColor: "rgba(40, 40, 40, 0.8)",
            pbColor: "red",
            pbDirection: "ltr",
            pbThickness: 3,
            hasProgressBar: false,
            text: "Loading...",
            textColor: "#FFFFFF",
            textPosition: "center-center",
            maxTime: -1,
            minTime: 300,
        }),
        NgxUiLoaderHttpModule.forRoot({
            showForeground: true,
            //excludeRegexp: ["/notification/getnew/gm"],
        }),
        BrowserAnimationsModule,
        InterceptorModule,
        InfiniteScrollModule
    ],
    providers: [InterceptorModule, AuthGuard],
    bootstrap: [AppComponent]
})
export class AppModule { }
