
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3><b>Terms and Conditions</b> </h3><br>
                        </div>
                    </div>
                    <div class="row">
                        <div [innerHTML]="termdata">{{termdata}}</div>
                     </div>
                  
                </div>
            </div>
        </div>
    </section>
</main>




