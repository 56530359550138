import { Component, OnInit } from '@angular/core';
import { ConsultationFeedbackService } from 'src/app/services/consultation-feedback/consultation-feedback.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MobileAppFeedbackService } from 'src/app/services/mobile-app-feedback/mobile-app-feedback.service';

@Component({
  selector: 'app-mobile-app-feedback-details',
  templateUrl: './mobile-app-feedback-details.component.html',
  styleUrls: ['./mobile-app-feedback-details.component.scss']
})
export class MobileAppFeedbackDetailsComponent implements OnInit {
    getMobileAppFeedbackDetails: any = {}


    constructor(
        private mobileAppFeedbackService: MobileAppFeedbackService,
        private actRoute: ActivatedRoute,
  ) { }

    ngOnInit(): void {
        let data = {
            mobileAppFeedbackId: this.actRoute.snapshot.paramMap.get('id')
        };
        this.mobileAppFeedbackDetailsFunction(data);
    }
    
    mobileAppFeedbackDetailsFunction(data: any) {
        this.mobileAppFeedbackService.getMobileAppFeedbackDetails(data).subscribe((data: any) => {
            // console.log(data, "data");
            this.getMobileAppFeedbackDetails = data.data
            console.log(this.getMobileAppFeedbackDetails, "this.getMobileAppFeedbackDetails");
        })
    }

}
