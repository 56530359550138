import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CaregiverServiceService } from 'src/app/services/caregiver/caregiver-service.service';
import { UserServiceService } from 'src/app/services/user/user-service.service';

@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

    totalUsers: number = 0;
    totalCaregivers: number = 0;
    totalConsultation: number = 0;
    totalEarningDaily: number = 0;
    totalEarningWeekly: number = 0;
    totalEarningMonthly: number = 0;

    page: number = 1;
    limit: number = 5;
    filter: any = '';
    users: any = [];
    caregivers: any = [];
    status: any = '';

    constructor(private caregiverService: CaregiverServiceService, private router: Router, private toastr: ToastrService, private userService: UserServiceService) { }

    ngOnInit(): void {

        let data = {
            filter: this.filter,
            status: this.status,
            
            page: this.page,
            limit: this.limit
        };

        this.userList(data);
        this.caregiverList(data);
        this.usersCount();
        this.caregiversCount();
        this.consultationCount();
        this.getTotalEarningDaily();
        this.getTotalEarningWeekly();
        this.getTotalEarningMonthly();
    }

    usersCount() {
        this.userService.userCount().subscribe((res: any) => {
            if (res.messageID == 200 && res.user) {
                this.totalUsers = res.user;
            } else {
                this.totalUsers = 0
            }
        })
    }

    userList(data: any) {
        this.userService.userList(data).subscribe((res: any) => {
            if (res.messageID == 200 && res.data) {
                this.users = res.data.docs;
                console.log("--------------->>>>>>>>>>>>.",this.users)
            } else {
                this.users = 0
            }
        })
    }

    caregiverList(data: any) {
        this.caregiverService.getCaregiverList(data).subscribe((res: any) => {
            if (res.messageID == 200 && res.data) {
                this.caregivers = res.data.docs;

            } else {
                this.caregivers = 0
            }
        })
    }

    caregiversCount() {
        this.userService.caregiverCount().subscribe((res: any) => {
            if (res.messageID == 200 && res.user) {
                this.totalCaregivers = res.user;

            } else {
                this.totalCaregivers = 0
            }
        })
    }

    consultationCount() {
        this.userService.consultationCount().subscribe((res: any) => {
            console.log("->>>>>>>>>>>>>>>",res )
            if (res.messageID == 200 && res.data) {
                this.totalConsultation = res.data;
                
            } else {
                this.totalConsultation = 0
            }

        })
    }

    getTotalEarningDaily() {
        this.userService.totalEarningDailyCount().subscribe((res: any) => {
            // this.totalEarningDaily = res.data;
            if (res.messageID == 200 && res.data) {
                this.totalEarningDaily = res.data;
            } else {
                this.totalEarningDaily = 0
            }
        })
    }

    getTotalEarningWeekly() {
        this.userService.totalEarningWeeklyCount().subscribe((res: any) => {
            if (res.messageID == 200 && res.data) {
                this.totalEarningWeekly = res.data;
            } else {
                this.totalEarningWeekly = 0
            }
        })
    }

    getTotalEarningMonthly() {
        this.userService.totalEarningMonthlyCount().subscribe((res: any) => {
            if (res.messageID == 200 && res.data) {
                this.totalEarningMonthly = res.data;
            } else {
                this.totalEarningMonthly = 0
            }
        })
    }

}
