<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3><b>Appointment Details</b> </h3>
                        </div>
                    </div>
                    <div class="card shadow border-0 mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-6 col-lg-6 mb-3">
                                                <span>User</span>
                                                <p>{{getMobileAppFeedbackDetails.userId.firstName}}
                                                    {{getMobileAppFeedbackDetails.userId.lastName}}</p>
                                            </div>
                                            <div class="col-6 col-lg-6 mb-3">
                                                <span>Stars</span>
                                                <p>{{getMobileAppFeedbackDetails.stars}}</p>
                                            </div>
                                            <div class="col-6 col-lg-6 mb-3">
                                                <span>Comments</span>
                                                <p>{{getMobileAppFeedbackDetails.comments}}</p>
                                            </div>
                                            <div class="col-6 col-lg-6 mb-3">
                                                <span>Date</span>
                                                <p>{{getMobileAppFeedbackDetails.createdAt}}</p>
                                            </div>
                                        </div>
                                        <a class="btn btn-primary dark" routerLink="/admin-mobile-app-feedback">
                                            <i class="fas fa-arrow-left"></i> Back</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>