import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { EducationalArticleService } from 'src/app/services/educational-article/educational-article.service';
@Component({
    selector: 'app-add-educational-video',
    templateUrl: './add-educational-video.component.html',
    styleUrls: ['./add-educational-video.component.scss']
})
export class AddEducationalVideoComponent implements OnInit {
    videoUploadFormGroup!: FormGroup | any;
    video: any
    constructor(
        private fb: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private educationalArticleService: EducationalArticleService,
    ) { }

    ngOnInit(): void {
        this.videoUploadFormGroup = this.fb.group({
            videoTitle: [''],
            videoDescription: ['']
        })
    }

    get f() {
        return this.videoUploadFormGroup.controls;
    }
    onFileSelected(event: any) {
        let file = event.target.files[0];
        this.video = file
        console.log(this.videoUploadFormGroup);
    }

    onSubmit() {
        const formData = new FormData()
        formData.append('video', this.video)
        formData.set('videoTitle', this.f.videoTitle.value)
        formData.set('videoDescription', this.f.videoDescription.value)
        this.educationalArticleService.educationalVideoUpload(formData).subscribe((res: any) => {
            if (res.messageID == 200 && res.data) {
                this.toastr.success(res.message, "Success");
                this.router.navigateByUrl("/admin-educational-videos");
            } else {
                this.toastr.error(res.message)
            }
        })
    }
}
