import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EducationalArticleService } from 'src/app/services/educational-article/educational-article.service';


@Component({
    selector: 'app-educational-videos',
    templateUrl: './educational-videos.component.html',
    styleUrls: ['./educational-videos.component.scss']
})
export class EducationalVideosComponent implements OnInit {
    educationalVideoList: any = []
    page: number = 1;
    limit: number = 5;
    videoTitle: string = "";
    filter: any = '';
    totaldocs: number = 0;
    videoId: any;
    constructor(
        private educationalArticleService: EducationalArticleService,
        private toastr: ToastrService,
        private router: Router
    ) { }

    ngOnInit(): void {
        let data = {
            filter: this.filter,
            page: this.page,
            limit: this.limit,
        }
        this.getEducationalVideoList(data)
    }

    getEducationalVideoList(data: any) {
        this.educationalArticleService.educationalVideoList(data).subscribe((res: any) => {

            if (res.messageID == 200 && res.data) {
                this.educationalVideoList = res.data.docs
                this.totaldocs = res.data.totalDocs;
                this.page = res.data.page
                this, this.toastr.success(res.message)
            } else {
                this.toastr.error(res.message)
            }
        })
    }

    searchArticle(event: any) {
        let data = {
            page: this.page,
            filter: event.target.value,
            limit: this.limit
        }
        this.getEducationalVideoList(data)
    }
    pageChange(page: any) {
        let data = {
            filter: this.filter,
            page: page,
            limit: this.limit
        };
        this.getEducationalVideoList(data)

    }
    getVideoStatus(data: any) {
        this.videoId = data._id;
    }
    closeModal() {
        let data = {
            filter: this.filter,
            page: this.page,
            limit: this.limit
        };

        this.getEducationalVideoList(data)
    }
    deleteVideo() {
        let dataa = {
            filter: this.filter,
            page: this.page,
            limit: this.limit
        };
        let data = {
            _id: this.videoId,
            isDelete: true
        };

        this.educationalArticleService.deleteEducationalVideo(data).subscribe((res: any) => {
            if (res.messageID == 200) {
                this.toastr.success(res.message, res.status)
                let element: HTMLElement = document.getElementById('closeUserDeleteModal') as HTMLElement;
                element.click();
                this.getEducationalVideoList(dataa)
            } else {
                this.toastr.error(res.message)
            }
        })
    }
    // onDelete(videoId: any) {
    //     const confirmDelete = confirm("Are you sure want to delete this data?")
    //     if (confirmDelete) {
    //         let data = {
    //             videoId: videoId,
    //         };
    //         this.educationalArticleService.deleteEducationalVideo(data).subscribe((data: any) => {
    //             this.toastr.success(data.message, "Success");
    //             let list = {
    //                 videoTitle: this.videoTitle
    //             };
    //             this.getEducationalVideoList(list)
    //             this.router.navigateByUrl("/admin-educational-videos");
    //         })
    //     }
    // }


}
