import { Component, OnInit } from '@angular/core';
import { CaregiverServiceService } from 'src/app/services/caregiver/caregiver-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { io } from "socket.io-client";
import { ChatServiceService } from 'src/app/services/chat-service/chat-service.service';
import { environment } from 'src/environments/environment';




@Component({
    selector: 'app-chat-test',
    templateUrl: './chat-test.component.html',
    styleUrls: ['./chat-test.component.scss']
})
export class ChatTestComponent implements OnInit {
    // socket = io('http://localhost:4000');
    socket = io(environment.baseUrl);

    chatMessageFormGroup!: FormGroup | any;

    page: number = 1;
    limit: number = 6;
    filter: any = '';
    caregiverlist: any = [];
    caregiverId: any = '';
    isBlocked: boolean = false;
    caregiverName: any = '';
    chatData: any = [];
    baseUrl = environment.baseUrl;
    profilePicUrl: any = `${this.baseUrl}/public/caregiver_picture/`

    status: any = '';
    totaldocs: any = 0;

    selectedUsersId: any = []

    constructor(
        private caregiverService: CaregiverServiceService,
        private chatService: ChatServiceService,
        private fb: FormBuilder
    ) { }

    ngOnInit(): void {
        this.chatMessageFormGroup = this.fb.group({
            message: [''],
        })
        let data = {
            filter: this.filter,
            status: this.status,
            page: this.page,
            limit: this.limit
        };
        this.caregiversList(data);
    }

    get f() {
        return this.chatMessageFormGroup.controls;
    }

    caregiversList(data: any) {
        this.caregiverService.getCaregiverList(data).subscribe((data: any) => {
            this.caregiverlist = data.data.docs;
            this.totaldocs = data.data.totalDocs;
        })
    }

    caregiversDetails(data: any) {
        this.caregiverService.getCaregiverDetails(data).subscribe((data: any) => {
            this.caregiverName = `${data.data.firstName} ${data.data.lastName}`
        })
    }

    public chatMessageSend() {
        let data = {
            senderId: localStorage.getItem("_id"),
            receiverId: this.caregiverId,
            message: this.f.message.value,
        }
        this.socket.emit('message', data);
        this.chatMessageFormGroup = this.fb.group({
            message: [''],
        })
        let chatParams = {
            senderId: localStorage.getItem("_id"),
            receiverId: this.caregiverId
        }
        this.getChatData(chatParams)
    }

    selectUserForChat(data: any) {
        this.caregiverId = data
        let id = {
            _id: data
        }
        this.caregiversDetails(id)
        let chatParams = {
            senderId: localStorage.getItem("_id"),
            receiverId: data
        }
        this.getChatData(chatParams)
    }

    getChatData(data: any) {
        this.chatService.getChatData(data).subscribe((data: any) => {
            this.chatData = data.data
        })
    }

    searchUser(event: any) {
        this.filter = event.target.value
        let data = {
            filter: this.filter,
            status: this.status,
            page: this.page,
            limit: this.limit
        };
        this.caregiversList(data);
    }

    selectUser(event: any) {
        if (event.target.checked == true) {
            this.selectedUsersId.push(event.target.value)
        } else {
            const index = this.selectedUsersId.indexOf(event.target.value)
            this.selectedUsersId.splice(index, 1)
        }
    }

    createGroup() {
        let data = {
            sender: localStorage.getItem("_id"),
            receivers: this.selectedUsersId,
            groupName: "My Group"
        }
        this.socket.emit('create-group', data);
    }

}
