import { HttpClient , HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from '../auth/auth-service.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root'
})
export class CaregiverServiceService {

  titlle = environment.title;
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, private authService: AuthServiceService) { }
  caregiverSignup(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/caregiver/signup`, data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  blockCaregiver(data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/api/v1/update/status`, data, httpOptions)
      .pipe(catchError(this.handleError('getUser', [])));
  }
  getCaregiverList(data: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/caregiver/list?page=${data.page}&limit=${data.limit}&filter=${data.filter}&status=${data.status}`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  caregiverEdit(data: any): Observable<any> {
    console.log(data, "uppdated_data");
    return this.http.put(`${this.baseUrl}/api/v1/caregiver/profile-update`, data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  getCaregiverDetails(data: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/user/details?_id=${data._id}`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }
  getCaregiverListChat(data: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/caregiver/list-chat?page=${data.page}&limit=${data.limit}&filter=${data.filter}&status=${data.status}`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("error", error.error, result); // log to console instead
      if (error.error.messageID == 401) {
        this.authService.logout()
        return of(error.error)
      }
      if (error.error.messageID == 403) {
        this.authService.logout()
        return of(error.error)
      }
      return of(error.error);
    };
  }
}
