<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <div class="container-fluid mb-4">
        <div class="row">
            <div class="col-12 col-md-5 col-lg-3 text-start">
                <h4 class="fs-28 fw-bold">Quiz
                </h4>
            </div>
            <div class="col-12 col-md-5 col-lg-3">
                <button [routerLink]="['/add-Quiz']" class="btn btn-primary dark ">
                    <i class="fas fa-plus"></i>Add
                    Quiz</button>
            </div>
           
        </div>
    </div>
    <div class="card border-0 shadow">
        <div class="card-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 table-responsive">
                        <table class="table">
                            <thead class="d-lg-block-thead">
                                <tr>
                                    <th>Quiz Title
                                    </th>
                                    <th> Quiz Content
                                    </th>
                                    <th>
                                        uri
                                    </th>
                                    <th>
                                        createdAt
                                    </th>
                                    <th>
                                        Actions
                                    </th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="QuizList.length > 0; else nodata">
                                    
                                    <tr *ngFor="let QuizLists of QuizList ">
                                        <td data-label=" quizTitle">{{QuizLists.quizTitle}}</td>
                                        <td data-label="quizContent">{{QuizLists.quizContent}}</td>
                                        <td data-label="uri"> <a [href]="QuizLists.uri" target="_blank">{{ QuizLists.uri }}</a></td>
                                        <td data-label="createdAt">{{formatDate(QuizLists.createdAt)}}</td>
                                     

                                        <td data-label="Action">
                                            <p class="d-flex justify-content-end justify-content-lg-start">
                                                <button class="btn btn-primary rounded-circle btn-rounded shadow me-2" routerLink="/edit-Quiz/{{QuizLists._id}}"
                                                   >
                                                   
                                                    <i class="bi bi-pencil" title="Edit"></i>
                                                </button>
                                                <button
                                                    class="btn btn-danger rounded-circle btn-rounded shadow me-2 text-white" (click)="deleteQuizs(QuizLists._id)"
                                                   >

                                                  
                                                    <i class="bi bi-trash" title="Delete"></i>
                                                </button>


                                      </td>
                                        </tr>


                                </ng-container>
                                <ng-template #nodata>
                                    <tr>
                                        <td colspan="6" class="text-center">No Data Found</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                        <div class="paginator" style="float: right;">
                            <pagination-controls  id="second"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Delete Modal -->
    <div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"><b>Confirmation</b></h5>
                    <button type="button"  id="closeUserDeleteModal" class="close"
                        data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    Are you sure you want to Delete?
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" 
                        data-dismiss="modal">No</button>
                    <button type="button" class="btn btn-primary"  >Yes</button>
                </div>
            </div>
        </div>
    </div>
</main>
