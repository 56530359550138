<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3><b>Update Caregiver</b></h3>
                        </div>
                    </div>
                    <div class="card shadow border-0 mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-lg-3 text-center mt-3">
                                    <div class="profile_pic">
                                        <img *ngIf="!image" src={{image}} width="170" height="170"
                                            class="rounded m-auto " />
                                        <img *ngIf="image" [src]="image" width="170" height="170"
                                            class="rounded m-auto  " />
                                    </div>

                                    <div class="m-width-50">
                                        <div class="btn upload_btn btn-black btn-block form-group text-white"><i
                                                class="fa fa-upload" aria-hidden="true"></i>
                                            <input class="form-control form-control-lg" (change)="fileProgress($event)"
                                                id="formFileLg" type="file" />
                                            Upload
                                        </div>


                                    </div>


                                </div>
                                <div class="col-12 col-md-9">
                                    <div class="container-fluid">
                                        <form [formGroup]="caregiverForm" (submit)="onSubmit()">
                                            <div class="row">
                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>First Name</span>
                                                    <input type="text" class="form-control" formControlName="firstName"
                                                        placeholder="First Name" value={{caregiverForm.firstName}}>
                                                </div>

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>Last Name</span>
                                                    <input type="text" class="form-control" formControlName="lastName"
                                                        placeholder="Last Name" value={{caregiverForm.lastName}}>
                                                </div>

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>Date Of Birth</span>
                                                    <input type="date" class="form-control" formControlName="dob"
                                                        placeholder="D.O.B" value={{caregiverForm.dob}}>
                                                </div>

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>Email</span>
                                                    <input type="text" class="form-control" formControlName="email"
                                                        placeholder="Email"
                                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                        value={{caregiverForm.email}}>
                                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                        <div *ngIf="f.email.errors.required">
                                                            Email is required
                                                        </div>
                                                        <div *ngIf="f.email.errors.email">
                                                            Email must be a valid email address
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- <div class="col-12 col-lg-6 mb-3">
                                        <span>Password</span>
                                        <input type="password" class="form-control" placeholder="Password">
                                    </div>

                                    <div class="col-12 col-lg-6 mb-3">
                                        <span>Confirm Password</span>
                                        <input type="password" class="form-control"
                                            placeholder="Confirm Password">
                                    </div> -->

                                                <div class="col-12 col-lg-4 mb-3">
                                                    <span>Mobile</span>
                                                    <input type="number" class="form-control" formControlName="phone"
                                                        placeholder="Mobile" value={{caregiverForm.Mobile}}>
                                                </div>
                                                <div class="col-12 col-lg-4 mb-3">
                                                    <span>Experience</span>
                                                    <input type="number" class="form-control"
                                                        formControlName="experience" placeholder="Experience"
                                                        value={{caregiverForm.experience}} min="1">
                                                </div>
                                                <div class="col-12 col-lg-4 mb-3">
                                                    <span>Designation</span>
                                                    <!-- <input type="number" class="form-control"
                                                        formControlName="experience" placeholder="Experience"
                                                        value={{caregiverForm.experience}} min="1"> -->
                                                        <select  formControlName="designation" class="form-control">
                                                            <option value="Nurse">Nurse</option>
                                                            <option value="Doula">Doula</option>
                                                            <option value="Midwife">Midwife</option>
                                                        </select>
                                                </div>
                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Bio</span>
                                                    <input type="text" class="form-control"
                                                        formControlName="description" placeholder="Bio"
                                                        value={{caregiverForm.description}}>
                                                </div>

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>City</span>
                                                    <input type="text" class="form-control" formControlName="city"
                                                        placeholder="City" value={{caregiverForm.city}}>
                                                </div>

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>State</span>
                                                    <input type="text" class="form-control" formControlName="state"
                                                        placeholder="State" value={{caregiverForm.state}}>
                                                </div>

                                            </div>
                                            <button class="save_btnn">
                                                Update
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>