<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3><b>Video Details</b> </h3>
                        </div>
                    </div>
                    <div class="card shadow border-0 mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="col-6 col-lg-6 mb-3">
                                                    <video width="320" height="240" controls *ngIf="videoURL !='' ">
                                                        <source [src]='videoURL' type="video/mp4">
                                                    </video>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="col-6 col-lg-6 mb-3">
                                                    <span>Video Title</span>
                                                    <p>{{videoDetails.videoTitle}}</p>
                                                </div>
                                                <div class="col-6 col-lg-6 mb-3">
                                                    <span>Video Description</span>
                                                    <p>{{videoDetails.videoDescription}}</p>
                                                </div>
                                                <div class="col-6 col-lg-6 mb-3">
                                                    <span>Date</span>
                                                    <p>{{ formatDate(videoDetails.createdAt) }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="btn btn-primary dark" routerLink="/admin-educational-videos">
                                            <i class="fas fa-arrow-left"></i> Back</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>