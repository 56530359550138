import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlanService {
    accessToken: any = localStorage.getItem('Token');
    titlle = environment.title;
    baseUrl = environment.baseUrl;

    constructor(private http: HttpClient) { }
    
    subscriptionPlanList(data: any) {
        return this.http.get(`${this.baseUrl}/api/v1/total-products`);
    }
}
