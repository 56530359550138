import { Injectable, NgModule } from "@angular/core";
import { Observable } from "rxjs";
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from "@angular/common/http";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpResponseIntercepterService } from "src/app/shared/inceptorresponse/http-response-intercepter.service";

@Injectable({
    providedIn: "root"
})
export class RequestInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let currentUser;
        // = JSON.parse(localStorage.getItem('admin'));
        try {
            currentUser = localStorage.getItem('Token')
        }
        catch (err) {
            currentUser = {}
        }
        if (req.url.indexOf('uploads') == -1) {
            if (currentUser) {
                const jsonReq = req.clone({
                    setHeaders: {
                        // "Content-Type": "multipart/form-data",
                        Authorization: "Bearer " + currentUser
                    }
                });
                return next.handle(jsonReq);
            } else {
                const jsonReq = req.clone({
                    headers: req.headers.set("Content-Type", "application/json"),
                    setHeaders: { "Content-Type": "application/json" }
                });
                return next.handle(jsonReq);
            }
        } else {
            const jsonReq = req.clone({
                setHeaders: {
                    Authorization: "Bearer " + currentUser
                }
            });
            return next.handle(jsonReq);
        }
    }
}
@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpResponseIntercepterService,
            multi: true
        }
    ],
    declarations: []
})
export class InterceptorModule { }

