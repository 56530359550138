import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { StaticPagesService } from "src/app/services/static/static-pages.service";

@Component({
  selector: 'app-policy-caregiver',
  templateUrl: './policy-caregiver.component.html',
  styleUrls: ['./policy-caregiver.component.scss']
})
export class PolicyCaregiverComponent implements OnInit {

  termdata: any = [];
  constructor(
    private actRoute: ActivatedRoute,
    private StaticPagesService: StaticPagesService,
    private router: Router,
    private toastr: ToastrService
  ) {}


  ngOnInit(): void {
    this.getPrivacyPolicy();
  }

  getPrivacyPolicy() {
    this.StaticPagesService.getCaregiverPrivacyPolicy().subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.termdata = res.data.docs;
        
        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(
          this.termdata.description,
          "text/html"
        );

        if (parsedDocument && parsedDocument.body) {
          this.termdata = parsedDocument.body.innerHTML;
        } else {
          this.termdata = "Error parsing the HTML content.";
        }
     
      } else {
        this.toastr.error(res.message);
      }
    });
  }

}
