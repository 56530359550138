import { Component, OnInit } from '@angular/core';
import { MobileAppFeedbackService } from 'src/app/services/mobile-app-feedback/mobile-app-feedback.service';

@Component({
  selector: 'app-mobile-app-feedback',
  templateUrl: './mobile-app-feedback.component.html',
  styleUrls: ['./mobile-app-feedback.component.scss']
})
export class MobileAppFeedbackComponent implements OnInit {
    mobileAppFeedbackList: any = []
    page: number = 1;
    limit: number = 5;

    constructor(
        private mobileAppFeedbackService: MobileAppFeedbackService,
  ) { }

    ngOnInit(): void {
        let data = {
            page: this.page,
            limit: this.limit
        };
        this.mobileAppFeedbackListFunction(data)
  }
    
    mobileAppFeedbackListFunction(data: any) {
        this.mobileAppFeedbackService.getMobileAppFeedbackList(data).subscribe((data: any) => {
            // console.log(data, "data");
            this.mobileAppFeedbackList = data.data
            console.log(this.mobileAppFeedbackList, "this.mobileAppFeedbackList");
        })
    }

}
