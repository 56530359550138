<section class="login_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="login_card">
                    <div class="login_form">
                        <ul>
                            <li class="active">Change Password</li>
                        </ul>
                        <form [formGroup]="changePass" (submit)="onSubmit()">
                            <div class="form-group input-icon">
                                <input type="text" class="form-control" formControlName="newPassword"
                                    placeholder="New password"
                                    [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }" />
                                <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                                    <div *ngIf="f.newPassword.errors.required">New Password is required</div>
                                    <div *ngIf="f.newPassword.errors?.['pattern']">
                                        Password must contain at least one number, one uppercase and a
                                        lowercase letter and at least 8 characters<br />Password cannot
                                        contain whitespace
                                    </div>
                                </div>
                                <em class="fa fa-envelope-o"></em>
                            </div>
                            <div class="form-group input-icon">
                                <input type="text" class="form-control" formControlName="confirmPasswword"
                                    placeholder="Confirm New Password"
                                    [ngClass]="{ 'is-invalid': submitted && f.confirmPasswword.errors }" />
                                <div *ngIf="submitted && f.confirmPasswword.errors" class="invalid-feedback">
                                    <div *ngIf="f.confirmPasswword.errors.required">Confirm Password is required</div>
                                </div>
                                <div *ngIf="f.confirmPasswword.errors?.['confirmedValidator']" style="color: red;">
                                    Passwords did not match
                                </div>
                                <em class="fa fa-envelope-o"></em>
                            </div>

                            <div class="form-group"></div>
                            <button class="btn btn-primary">Change Password</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>