import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

import { UserServiceService } from 'src/app/services/user/user-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss']
})
export class AdminProfileComponent implements OnInit {

  adminProfileForm!: FormGroup | any;
  submitted: boolean = false;

  firstName: any = '';
  _id: any = '';
  fileData: any = '';
  default: boolean = false;
  image: any = '';
  baseUrl = environment.baseUrl;
  removeurl = ''
  imageurl = ''

  constructor(private actRoute: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private userService: UserServiceService,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.adminProfileForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      image: [''],
      city: [''],
      state: ['']
    })
    this.getAdminInfo();
  }

  get f() {
    return this.adminProfileForm.controls;
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.adminProfileForm.get('image').setValue(fileInput.target.files[0]);
    this.default = true;
    this.preview();
    if (this.imageurl) {
      this.removeurl = this.imageurl;
    }
  }

  preview() {
    // Show preview
    var mimeType = this.fileData.type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.image = reader.result;

    };
  }

  getAdminInfo() {
    this._id = this.actRoute.snapshot.paramMap.get('id');
    this.userService.adminInfo().subscribe((data: any) => {

      if (data.data.image) {
        this.imageurl = data.data.image
        this.image = data.data.image
        // this.imageurl = `/public/admin_picture/${data.data.image}`
        // this.image = `${this.baseUrl}/public/admin_picture/${data.data.image}`
      }
      this.adminProfileForm.patchValue({
        firstName: data.data.firstName,
        lastName: data.data.lastName,
        phone: data.data.phone,
        email: data.data.email,
        city: data.data.address.city,
        state: data.data.address.state,
      })

    })
  }

  removeImage() {
    this.removeurl = this.imageurl;
    this.image = '';

  }

  onSubmit() {
    this.submitted = true;
    if (this.adminProfileForm.invalid) {
      return
    } else {
      const formData = new FormData();

      console.log(this._id, "idddd");


      // formData.append('_id', this._id);
      formData.append('image', this.f.image.value);
      formData.append('firstName', this.f.firstName.value);
      formData.append('lastName', this.f.lastName.value);
      formData.append('email', this.f.email.value);
      formData.append('phone', this.f.phone.value);
      formData.append('address_city', this.f.city.value);
      formData.append('address_state', this.f.state.value);
      formData.append('removeurl', this.removeurl);


      this.userService.updateAdminProfile(formData).subscribe((res: any) => {
        if (res.messageID == 200 && res.data) {
          this.toastr.success(res.message, res.status)
          this.getAdminInfo();
          this.router.navigate(['']);

        } else {
          this.toastr.error(res.message)
        }
      })
    }
  }

}
