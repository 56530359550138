import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AdminServiceService } from "src/app/services/admin-service/admin-service.service";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-add-service",
  templateUrl: "./add-service.component.html",
  styleUrls: ["./add-service.component.scss"],
})
export class AddServiceComponent implements OnInit {
  serviceAdd!: FormGroup | any;
  submitted: boolean = false;
  fileData: any = false;
  default: boolean = false;
  isLocalCaregiver: boolean = false;
  image: any = "";
  constructor(
    private fb: FormBuilder,
    private AdminServiceService: AdminServiceService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.serviceAdd = this.fb.group({
      name: [""],
      description: [""],
      product_type:[""],
      unit_amount_decimal: [""],
      starting_amount_decimal: [""],
      interval: [""],
      intervalCount: [""],
    });
  }
  get f() {
    return this.serviceAdd.controls;
  }
  onSubmit() {
    console.log("log reports >>>")
    this.submitted = true;

    if (this.serviceAdd.invalid) {
      return;
    } else {
      const userEnteredName = this.serviceAdd.get("name").value;
      const userEnteredDescription = this.serviceAdd.get("description").value;
      const product_type = this.serviceAdd.get("product_type").value;
      const userEnteredSturtingUnitAmount = this.serviceAdd.get(
        "starting_amount_decimal"
      ).value;
      const userEnteredUnitAmount = this.serviceAdd.get(
        "unit_amount_decimal"
      ).value;
      const userEnteredInterval = this.serviceAdd.get("interval").value;

      const obj = {
        name: userEnteredName,
        type: product_type,
        description: [userEnteredDescription],
        active: true,
        default_price_data: {
          currency: "USD",
          unit_amount: userEnteredUnitAmount,
          recurring: {
            interval: userEnteredInterval,
          },
        },
        starting_price_data: {
          currency: "USD",
          unit_amount: userEnteredSturtingUnitAmount,
          recurring: {
            interval: userEnteredInterval,
          },
        },
      };

      console.log("=============", obj);
      this.AdminServiceService.serviceAdd(obj).subscribe((res: any) => {
        if (res.messageID == 200 && res.data) {
          this.toastr.success(res.message);
          this.toastr.success(res.message, res.status);
          this.router.navigate(["/admin-service"]);
        } else {
          this.toastr.error(res.message);
        }
      });
    }
  }
}
