
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3><b>About Us</b> </h3><br>
                        </div>
                    </div>
                    <div class="row">
                       <div [innerHTML]="aboutdata">{{aboutdata}}</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

