<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3><b>Admin Profile</b></h3>
                        </div>
                    </div>
                    <div class="card shadow border-0 mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-lg-3 text-center mt-3">

                                    <div class="profile_pic">
                                        <img *ngIf="!image" src="../../../../assets/images/user_preg.jpeg" width="170"
                                            height="170" class="rounded m-auto " />
                                        <img *ngIf="image" [src]="image" width="170" height="170"
                                            class="rounded m-auto  " />
                                    </div>

                                    <div class="m-width-50">
                                        <div class="btn upload_btn btn-black btn-block form-group text-white"><i
                                                class="fa fa-upload" aria-hidden="true"></i>
                                            <input class="form-control form-control-lg" (change)="fileProgress($event)"
                                                id="formFileLg" type="file" />
                                            Upload
                                        </div>

                                        <button class="btn btn-white btn-block" (click)="removeImage()">
                                            <i class="fa fa-times" aria-hidden="true"></i> Remove
                                        </button>
                                    </div>

                                </div>
                                <div class="col-12 col-md-9">
                                    <div class="container-fluid">
                                        <form [formGroup]="adminProfileForm" (submit)="onSubmit()">
                                            <div class="row">

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>First Name</span>
                                                    <input type="text" class="form-control" formControlName="firstName"
                                                        placeholder="First Name">
                                                </div>

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>Last Name</span>
                                                    <input type="text" class="form-control" formControlName="lastName"
                                                        placeholder="Last Name">
                                                </div>

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>Email</span>
                                                    <input type="text" class="form-control" formControlName="email"
                                                        placeholder="Email"
                                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                        <div *ngIf="f.email.errors.required">
                                                            Email is required
                                                        </div>
                                                        <div *ngIf="f.email.errors.email">
                                                            Email must be a valid email address
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>Contact Number </span>
                                                    <input type="number" class="form-control" formControlName="phone"
                                                        placeholder="Mobile">
                                                </div>


                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>City</span>
                                                    <input type="text" class="form-control" formControlName="city"
                                                        placeholder="City">
                                                </div>

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>State</span>
                                                    <input type="text" class="form-control" formControlName="state"
                                                        placeholder="State">
                                                </div>

                                            </div>
                                            <button class="save_btnn">
                                                Update
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>