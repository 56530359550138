import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CaregiverServiceService } from 'src/app/services/caregiver/caregiver-service.service';
import { UserServiceService } from 'src/app/services/user/user-service.service';

@Component({
  selector: 'app-caregivers',
  templateUrl: './caregivers.component.html',
  styleUrls: ['./caregivers.component.scss']
})
export class CaregiversComponent implements OnInit {

  page: number = 1;
  limit: number = 6;
  filter: any = '';
  caregiverlist: any = [];
  caregiver_id: any = '';
  isBlocked: boolean = false;
  caregiverName: any = '';

  status: any = '';
  totaldocs: any = 0;

  constructor(private caregiverService: CaregiverServiceService,
    private userService: UserServiceService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    let data = {
      filter: this.filter,
      status: this.status,
      page: this.page,
      limit: this.limit
    };
    this.caregiversList(data);
  }

  caregiversList(data: any) {
    this.caregiverService.getCaregiverList(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.toastr.success(res.message);
        this.caregiverlist = res.data.docs;
        this.totaldocs = res.data.totalDocs;
      } else {
        this.toastr.error(res.message);
      }
    })
  }


  searchUser(event: any) {
    const searchTerm = event.target.value.trim().toLowerCase(); 
  
    // Perform a new search request to fetch caregivers that match the search criteria
    let data = {
      filter: searchTerm, // Use the search term as the filter
      status: this.status,
      page: 1, // Start from the first page
      limit: 1000 // Set a high limit to fetch all caregivers (adjust as needed)
    };
  
    this.caregiverService.getCaregiverList(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.toastr.success(res.message);
  
        // Combine caregivers from all pages into a single array
        const allCaregivers = res.data.docs;
  
        if (searchTerm.length === 0) {
          // If the search input is empty, show all caregivers without filtering
          this.caregiverlist = allCaregivers;
        } else {
          // Filter caregivers based on the search term 
          const filteredCaregivers = allCaregivers.filter((caregiver: any) =>
            caregiver.firstName.toLowerCase().startsWith(searchTerm)
          );
  
          // Sort the filtered caregivers in ascending order based on first name
          filteredCaregivers.sort((a: any, b: any) => {
            const firstNameA = a.firstName.toLowerCase();
            const firstNameB = b.firstName.toLowerCase();
            return firstNameA.localeCompare(firstNameB);
          });
  
          this.caregiverlist = filteredCaregivers;
        }
      } else {
        this.toastr.error("Data Not found");
      }
    });
  }

  selectOption(value: any) {
    this.status = (value.target as HTMLInputElement).value;
    console.log(this.status, "check");
    let data = {
      filter: this.filter,
      status: this.status,
      page: this.page,
      limit: this.limit
    };
    this.caregiversList(data);
  }


  pageChange(page: any) {
    let data = {
      filter: this.filter,
      status: this.status,
      page: page,
      limit: this.limit
    };
    this.caregiverService.getCaregiverList(data).subscribe((res: any) => {
      if (res.status === "Success" && res.messageID === 200) {
        this.caregiverlist = res.data.docs;
        console.log("insideIfff");

        this.totaldocs = res.data.totalDocs;
        this.page = page
      } else {
        console.log("ousideIfff", res);

      }
    });
  }


  getCaregiverStatus(data: any) {
    console.log("data", data);

    this.caregiverName = data.firstName + " " + data.lastName
    this.caregiver_id = data._id;
    this.isBlocked = data.isBlocked;
    if (this.isBlocked == true) {
      this.isBlocked = false
    } else {
      this.isBlocked = true;
    }
  }

  blockUser() {
    let data = {
      _id: this.caregiver_id,
      isBlocked: this.isBlocked
    };

    let dataa = {
      filter: this.filter,
      status: this.status,
      page: this.page,
      limit: this.limit
    };

    this.userService.blockUser(data).subscribe((data: any) => {
      this.toastr.success(data.message, data.status)
      let element: HTMLElement = document.getElementById('closeCaregiverBlockModal') as HTMLElement;
      element.click();
      this.caregiversList(dataa);
    })
  }

  closeModal() {
    let data = {
      filter: this.filter,
      status: this.status,
      page: this.page,
      limit: this.limit
    };

    this.caregiversList(data);
  }
  deleteUser() {
    let dataa = {
      filter: this.filter,
      status: this.status,
      page: this.page,
      limit: this.limit
    };
    let data = {
      _id: this.caregiver_id,
      isDelete: true
    };

    this.userService.deteleUser(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.toastr.success(res.message, res.status)
        let element: HTMLElement = document.getElementById('closeUserDeleteModal') as HTMLElement;
        element.click();
        this.caregiversList(dataa);
      } else {
        this.toastr.error(res.message)
      }

    })
  }
}
