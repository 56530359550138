<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3><b>Add Subscription</b> </h3>
                        </div>
                    </div>
                    <div class="card shadow border-0 mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-lg-3 text-center mt-3">
                                    <div class="profile_pic">
                                        <img *ngIf="!image" src="../../../../assets/images/user_preg.jpeg" width="170"
                                            height="170" class="rounded m-auto " />
                                        <img *ngIf="image" [src]="image" width="170" height="170"
                                            class="rounded m-auto  " />
                                    </div>

                                 

                                </div>

                                <div class="col-12 col-md-9">
                                    <div class="container-fluid">
                                        <form [formGroup]="serviceAdd" (submit)="onSubmit()">
                                            <div class="row">

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>Name</span>
                                                    <input type="text" class="form-control" formControlName="name"
                                                        placeholder="Name">
                                                </div>

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>type</span>
                                                    <input type="text" class="form-control" formControlName="product_type"
                                                        placeholder="Product Type">
                                                </div>

                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Description</span>
                                                    <input type="text" class="form-control" formControlName="description"
                                                        placeholder="Description">
                                                </div>

                                 

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <label for="currency">Currency</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        formControlName="currency"
                                                        id="currency"
                                                        placeholder="USD"
                                                        value="USD"
                                                        readonly
                                                    >
                                                </div>


                                                <div class="col-12 col-lg-6 mb-3">
                                                    <label for="description">Active</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        formControlName="description"
                                                        id="description"
                                                        placeholder="true"
                                                        value="true"
                                                        readonly
                                                    >
                                                </div>

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>Amount</span>
                                                    <input type="text" class="form-control" formControlName="unit_amount_decimal"
                                                        placeholder="amount">
                                                </div>

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <span>Initial Amount</span>
                                                    <input type="text" class="form-control" formControlName="starting_amount_decimal"
                                                        placeholder="Initial amount">
                                                </div>

                                                <div class="col-12 col-lg-6 mb-3">
                                                    <select class="form-select me-3" formControlName="interval" >
                                                        <option value="">Interval</option>
                                                        <option value="month">monthly</option>
                                                        <option value="year">yearly</option>
                                                    </select>
                                                </div>
                                            </div>


                                            <button type= "submit" class="save_btnn">
                                                ADD
                                            </button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>