<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <div class="container-fluid mb-4">
        <div class="row">
            <div class="col-12 col-md-5 col-lg-3 text-start">
                <h4 class="fs-28 fw-bold"> Educational Videos
                </h4>
            </div>
            <!-- <div class="col-12 col-md-5 col-lg-3 text-start"> <button class="btn btn-primary dark" data-toggle="modal"
                    data-target="#exampleModal" (click)="planDetail()"> Update Plan Price</button> </div> -->
            <!-- filter -->
            <div class="col-12 col-md-5 col-lg-3">
                <button [routerLink]="['/admin-add-educational-video']" class="btn btn-primary dark ">
                    <i class="fas fa-plus"></i>Add
                    Video</button>
            </div>
            <div class="col-12 col-md-7 col-lg-3 text-end">
                <div class="input-group"> <input type="text" class="form-control border-end-0 form-control-lg"
                        placeholder="Search" (keyup)="searchArticle($event)" style="font-size: 13px" /> <span
                        class="input-group-text bg-white border-start-0"><i class="bi bi-search curser"></i> </span>
                </div>
            </div>
        </div>
    </div>
    <div class="card border-0 shadow">
        <div class="card-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 table-responsive">
                        <table class="table">
                            <thead class="d-lg-block-thead">
                                <tr>
                                    <th>Video Title
                                    </th>
                                    <th> Video Description
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </thead> <!-- [routerLink]="['/admin-user-profile']" -->
                            <tbody>
                                <ng-container *ngIf="educationalVideoList.length > 0;else nodata">
                                    <tr *ngFor="let educationalVideo of educationalVideoList  | paginate
                                    : {itemsPerPage: limit,currentPage: page,id: 'second',totalItems: totaldocs}">
                                        <!-- <ng-container>
                                                                            <tr> -->
                                        <td data-label=" First Name">{{educationalVideo.videoTitle}}</td>
                                        <td data-label="Contact No.">{{educationalVideo.videoDescription}}</td>
                                        <td data-label="Action">
                                            <p class="d-flex justify-content-end justify-content-lg-start">
                                                <button class="btn btn-primary rounded-circle btn-rounded shadow me-2"
                                                    routerLink="/admin-details-educational-videos/{{educationalVideo._id}}">
                                                    <i class="bi bi-eye" title="View"></i>
                                                </button>
                                                <!-- <button
                                                    class="btn btn-danger rounded-circle btn-rounded shadow me-2 text-white"
                                                    (click)="onDelete(educationalVideo._id)">
                                                    <i class="bi bi-trash" title="Delete"></i>
                                                </button> -->
                                                <button
                                                    class="btn btn-danger rounded-circle btn-rounded shadow me-2 text-white">
                                                    <i class="bi bi-trash" title="Delete"
                                                        (click)="getVideoStatus(educationalVideo)" data-target="#delete"
                                                        data-toggle="modal"></i>
                                                </button>
                                            </p>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-template #nodata>
                                    <tr>
                                        <td colspan="6" class="text-center">No Data Found</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                        <div class="paginator" style="float: right;">
                            <pagination-controls (pageChange)="pageChange($event)" id="second"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Delete Modal -->
    <div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"><b>Confirmation</b></h5>
                    <button type="button" (click)="closeModal()" id="closeUserDeleteModal" class="close"
                        data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    Are you sure you want to Delete?
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="closeModal()"
                        data-dismiss="modal">No</button>
                    <button type="button" class="btn btn-primary" (click)="deleteVideo()">Yes</button>
                </div>
            </div>
        </div>
    </div>
</main>