<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <div class="container-fluid mb-4">
        <div class="row">
            <div class="col-12 col-md-5 col-lg-3 text-start">
                <h4 class="fs-28 fw-bold">
                    Subscription List
                </h4>
            </div>
        

    
                <div class="button-container">
                    <button [routerLink]="['/add-service']" class="btn btn-primary dark">
                        <i class="fas fa-plus"></i> Add Subscription
                    </button>
                </div>
           
        </div>
    </div>

    <div class="card border-0 shadow">
        <div class="card-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 table-responsive">
                        <table class="table">
                            <thead class="d-lg-block-thead">
                                <tr>
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Active
                                    </th>
                                    <th>

                                        Currency

                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Interval
                                    </th>

                                    <th>
                                        Actions
                                    </th>
 
                                </tr>
                            </thead>
                            <tbody>

                                <ng-container *ngIf="ServiceList.length > 0; else nodata">

                                    <tr *ngFor="let Serve of ServiceLists">

                                        <td data-label="Name">{{Serve.name}}</td>
                                        <td data-label="Description">{{Serve.description}}</td>


                                        <td data-label="Description">{{Serve.active}}</td>

                                        <td data-label="currency">{{Serve.default_price_data.currency}}</td>

                                        <td data-label="currency">{{Serve.default_price_data.unit_amount}}</td>
                                        <td data-label="currency">{{Serve.default_price_data.recurring.interval}}</td>





                                <td data-label="Action">
                                    <p class="d-flex justify-content-end justify-content-lg-start">
                                        <button class="btn btn-primary rounded-circle btn-rounded shadow me-2"
                                            routerLink="/edit-service/{{Serve._id}}">
                                            <i class="bi bi-pencil" title="Edit" ></i>
                                        </button>
                                        <button
                                        class="btn btn-danger rounded-circle btn-rounded shadow me-2 text-white">
                                        <i class="bi bi-trash" title="Delete"
                                            data-target="#delete" (click)="deleteService(Serve.stripeProductId)"
                                            data-toggle="modal"></i>
                                    </button>
                                    </p>
                                </td>
                            </tr>
                            </ng-container>
                            <ng-template #nodata>
                                <tr>
                                    <td colspan="6" class="text-center">No Data Found</td>
                                </tr>
                            </ng-template>
                            </tbody>
                        </table>
                        <div class="paginator" style="float: right;">
                            <pagination-controls id="second"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>