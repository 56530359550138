import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from '../auth/auth-service.service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root'
})
export class StaticPagesService {
  titlle = environment.title;
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, private authService: AuthServiceService) { }

  getAboutus(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/cms/getCMSList?filter=about-us`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  getTnC(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/cms/getCMSList?filter=tearm-and-condition`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  getPrivacyPolicy(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/cms/getCMSList?filter=privacy-policy`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  getCaregiverPrivacyPolicy(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/cms/getCMSList?filter=privacy-policy-caregiver`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  getUrl(data: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/static/pageUrls`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  getUrls(page: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/v1/static/pageUrls/?page=${page}`)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }

  urlEdit(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/static/create/pageUrl`,data)
      .pipe(
        catchError(this.handleError('getUser', []))
      );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("error", error.error); // log to console instead
      if (error.error.messageID == 401) {
        this.authService.logout()
        return of(error.message)
      }
      return of(result as T);
    };
  }

  
}
