import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticPagesService } from 'src/app/services/static/static-pages.service';



@Component({
  selector: 'app-how-works',
  templateUrl: './how-works.component.html',
  styleUrls: ['./how-works.component.scss']
})
export class HowWorksComponent implements OnInit {
    workdata: any = [];
    constructor(
      private actRoute: ActivatedRoute,
      private StaticPagesService: StaticPagesService,
      private router: Router,
      private toastr: ToastrService
    ) {}
  
    ngOnInit(): void {
      this.getHowItworks();
    }
  
    getHowItworks() {
      this.StaticPagesService.getAboutus().subscribe((res: any) => {
        if (res.messageID == 200 && res.data) {
          this.workdata = res.data.docs;
          const parser = new DOMParser();
          const parsedDocument = parser.parseFromString(
            this.workdata.description,
            "text/html"
          );
          if (parsedDocument && parsedDocument.body) {
            this.workdata = parsedDocument.body.innerHTML;
          } else {
            this.workdata = "Error parsing the HTML content.";
          }
  
        } else {
          this.toastr.error(res.message);
        }
      });
    }
}
