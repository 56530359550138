<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <div class="container-fluid mb-4">
        <div class="row">
            <div class="col-12 col-md-5 col-lg-3 text-start">
                <h4 class="fs-28 fw-bold">Change url
                </h4>
            </div>
            </div>
        </div>

        <div class="card border-0 shadow">
            <div class="card-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 table-responsive">
                            <table class="table">
                                <thead class="d-lg-block-thead">
                                    <tr>
                                        <th>Page
                                        </th>
                                        <th>url
                                        </th>
                                        <th> Action
                                        </th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="urlList.length > 0; else nodata">
                                        <tr *ngFor="let urlList of urlList ">
                                            <td data-label=" page">{{urlList.page}}</td>
                                            <td data-label="url">{{urlList.url}}</td>
                                          
                                            <td data-label="Action">
                                                <p class="d-flex justify-content-end justify-content-lg-start">
                                                    <button class="btn btn-primary rounded-circle btn-rounded shadow me-2" routerLink="/edit-url/{{urlList.page}}"
                                                       >                      
                                                        <i class="bi bi-pencil" title="Edit"></i>
                                                    </button>
                                          </td>
                                            </tr>
                                    </ng-container>
                                    <ng-template #nodata>
                                        <tr>
                                            <td colspan="6" class="text-center">No Data Found</td>
                                        </tr>
                                    </ng-template>
                                </tbody>
                            </table>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>