import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BabyStagesService } from 'src/app/services/baby-stages/baby-stages.service';
@Component({
  selector: 'app-baby-stages',
  templateUrl: './baby-stages.component.html',
  styleUrls: ['./baby-stages.component.scss']
})
export class BabyStagesComponent implements OnInit {

  babyStagesLists: any = [];
  page: number = 1;
  limit: number = 20;
  blogTitle: string = "";
  totaldocs: any = 0;
  filter: any = '';
  articleId: any;

  constructor(
    private BabyStagesService: BabyStagesService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    let data = {
      filter: this.filter,
      page: this.page,
      limit: this.limit,
    };
    this.babyStagesList(data);
  }

  formatDate(timestamp:any) {
    const date = new Date(timestamp);
    const month = date.getMonth() + 1; // Months are 0-indexed
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
  }

  babyStagesList(data: any) {
    this.BabyStagesService.babyStagesList(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.toastr.success(res.message);
        this.babyStagesLists = res.data;
        console.log(this.babyStagesLists, "babyStagesList >>>");
     
      } else {
        this.toastr.error(res.message);
      }
    })
  }

  // searchBlog(event: any) {
  //   let data = {
  //     page: this.page,
  //     filter: event.target.value,
  //     limit: this.limit
  //   };
  //   this.getEducationalBlogList(data);
  // }

  pageChange(page: any) {
    let data = {
      filter: this.filter,
      page: page,
      limit: this.limit
    };
    this.babyStagesList(data);
  }

  // getBlogStatus(data: any) {
  //   this.articleId = data._id;
  // }

  // closeModal() {
  //   let data = {
  //     filter: this.filter,
  //     page: this.page,
  //     limit: this.limit
  //   };
  //   this.getEducationalBlogList(data);
  // }

  // deleteBlog() {
  //   console.log("deleteBlog method called");
  //   let dataa = {
  //     filter: this.filter,
  //     page: this.page,
  //     limit: this.limit
  //   };
  //   let data = {
  //     blogId: this.articleId, 
  //     isDelete: true
  //   };

  //   this.educationalBlogService.deleteEducationalBlog(data).subscribe((res: any) => {
  //     console.log("Delete Blog Response:", res); 
  //     if (res.messageID == 200) {
  //       this.toastr.success(res.message, res.status);
  //       let element: HTMLElement = document.getElementById('closeUserDeleteModal') as HTMLElement;
  //       element.click();
  //       this.getEducationalBlogList(dataa);
  //     } else {
  //       this.toastr.error(res.message);
  //     }
  //   });
  // }
}
