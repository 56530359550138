import { Component } from '@angular/core';
import { io } from 'socket.io-client';
import { AuthServiceService } from './services/auth/auth-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'simplybirth';
  constructor(
    public authService: AuthServiceService
  ) { }
  ngOnInit() {
    console.log("localStorage._id", localStorage._id)
  let socket = io(environment.baseUrl,{ transports: ['websocket', 'polling', 'flashsocket'] });
    if (this.authService.isAuth() ) {
      socket.emit('connectUser', {
        userId: localStorage._id,

      });
    }
    socket.on('connectionSuccess', (res) => {
    
    });
  }
}
