<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="dashboard_section">
        <div class="chatBox">
            <div class="toggleChatlist">
                <i class="fa-solid fa-bars"></i>
            </div>
            <div class="chatList">
                <li class="nav-item">
                    <a routerLink="/admin-broadcast-messaging" class="nav-link"
                        [ngClass]="{'active': routerurl=='admin-broadcast-messaging'}">
                        <i class="fa fa-envelope"></i> Broadcast Messaging
                    </a>
                </li>
                <div class="searchbar  mb-2">
                    <input type="text" placeholder="Search" name="name" placeholder="Search by Name"
                        (keyup)="searchUser($event)" />
                    <button class="search">
                        <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                </div>

                <div class="selectUserType container-fluid mb-2">
                    <div class="col-12">
                        <select class="form-select me-3" (change)="selectUserType($event)">
                            <option value="2" selected>All Customer</option>
                            <option value="3">All Caregiver</option>
                        </select>
                    </div>
                </div>
                <div style="text-align: center" *ngIf="userlist.length == 0">
                    <h5>No Records Found</h5>
                </div>

                <ul>
                    <ng-container *ngIf="userlist.length > 0">
                        <li class="active" *ngFor="let user of userlist | paginate
                        : {itemsPerPage: limit,currentPage: page,id: 'second',totalItems: totaldocs}"
                            style="margin-bottom: 5px;">
                            <div class="profile" style="padding-bottom:5px;" (click)="selectUserForChat(user)">
                                <div class="image">
                                    <img
                                        [src]="user.image != null ? user.image : '../../../../assets/images/avatar.jpg'">

                                </div>
                                <div class="text">

                                    <h3>{{user.firstName}} {{user.lastName}}</h3>
                                    <p>
                                        {{user?.lastMessage?.message}}
                                    </p>
                                    <p>
                                        {{user?.lastMessage?.createdAt |date :'shortDate'}}
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </ul>
                <div class="paginator" style="float: right;">
                    <pagination-controls (pageChange)="pageChange($event)" id="second"></pagination-controls>
                </div>
            </div>

            <div class="chat" *ngIf="caregiverId">
                <h3 class="contactName">{{selectedUserName}} </h3>
                <div class="chatMessage" infinite-scroll [infiniteScrollDistance]="1" [infiniteScrollUpDistance]="2"
                    [infiniteScrollThrottle]="500" (scrolled)="onScrollDown()" (scrolledUp)="loadMore()"
                    [scrollWindow]="false">
                    <ng-container *ngIf="chatData[0]?.allDates?.length > 0">
                        <div class="row" *ngFor="let dateData of chatData[0].allDates; index as i">

                            <div class="center-div">
                                {{ dateData }}
                            </div>
                            <div class="row" *ngFor="let chats of chatData[0].recordsByDate[i]; index as i1">

                                <div class="w-100 p-0">
                                    <div
                                        [ngClass]="this.chats?.sender?._id == caregiverId ? 'receivedMessage' : 'sentMessage'">

                                        <div class="image" *ngIf="chats?.sender?._id == caregiverId">
                                            <img src="../../../assets/images/avatar.png" alt="" />
                                        </div>




                                        <p>{{chats?.text}}
                                            <span style="font-size:x-small;">{{chats.createdAt |date
                                                :'shortTime'}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <!-- <div>{{  chatData[0].recordsByDate[0][i] | json }}</div> -->



                            <!-- <div class="w-100 p-0">
                                <div [ngClass]="this.chat?.sender?._id == caregiverId ? 'receivedMessage' : 'sentMessage'">

                                    <div class="image" *ngIf="chat?.sender?._id == caregiverId">
                                        <img src="../../../assets/images/avatar.png" alt="" />
                                    </div>

                                  


                                    <p>{{chat?.text}}
                                        <span style="font-size:x-small;">{{chat.createdAt |date :'shortTime'}}</span>
                                    </p>
                                </div>
                            </div> -->
                        </div>
                    </ng-container>
                    <div #scrollMe></div>
                </div>
                <div class="sendMessage">
                    <form [formGroup]="chatMessageFormGroup" (submit)="chatMessageSend()" class="sendMessage">
                        <input placeholder="Type here.." type="text" formControlName="message" />
                        <button>
                            <i class="fa-solid fa-paper-plane"></i>
                        </button>
                    </form>
                </div>

            </div>
        </div>
    </section>
</main>