import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-quiz',
  templateUrl: './edit-quiz.component.html',
  styleUrls: ['./edit-quiz.component.scss']
})
export class EditQuizComponent implements OnInit {
  addQuiz!: FormGroup | any;
  quizId: any = '';
  title = 'angular';
  public Editor = ClassicEditor;

  fileData: any = '';
  default: boolean = false;
  image: any = '';
  baseUrl = environment.baseUrl;
  removeurl = ''
  imageurl = ''
  constructor(private actRoute: ActivatedRoute,
      private fb: FormBuilder,
      private QuizService: QuizService,
      private toastr: ToastrService,
      private router: Router) { }

  ngOnInit(): void {
      this.addQuiz = this.fb.group({
          quizTitle: [''],
          quizContent: [''],
          uri:[''],
          image: ['']
      })
      this.getQuizInfo();

  }


  fileProgress(fileInput: any) {
      this.fileData = <File>fileInput.target.files[0];
      this.addQuiz.get('image').setValue(fileInput.target.files[0]);
      this.default = true;
      this.preview();
      if (this.imageurl) {
          this.removeurl = this.imageurl;
      }
  }

  preview() {

      var mimeType = this.fileData.type;

      if (mimeType.match(/image\/*/) == null) {
          return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = (_event) => {
          this.image = reader.result;

      };
  }

  removeImage() {
      this.removeurl = this.imageurl;
      this.image = '';
  }


  get f() {
      return this.addQuiz.controls;
  }

  onSubmit() {
      let data = {
        quizId: this.actRoute.snapshot.paramMap.get('id'),
        quizTitle: this.f.quizTitle.value,
        quizContent: this.f.quizContent.value
      }
      const formData = new FormData();
      formData.append('quizId', this.quizId);
      formData.append('image', this.f.image.value);
      formData.append('quizTitle', this.f.quizTitle.value);
      formData.append('quizContent', this.f.quizContent.value);
      formData.append('uri', this.f.uri.value);
      

      this.QuizService.quizEdit(formData).subscribe((res: any) => {
          if (res.messageID == 200 && res.data) {
              this.toastr.success(res.message, res.status);
              this.router.navigateByUrl("/Quiz");
          } else {
              this.toastr.error(res.message, "Error")
          }
      });
  }

  getQuizInfo() {
      this.quizId = this.actRoute.snapshot.paramMap.get('id');
      let data = {
        quizId: this.quizId
      }
      console.log("datat", data)
      this.QuizService.quizDetail(data).subscribe((res: any) => {
          if (res.messageID == 200 && res.data) {
              if (res.data.coverImage) {
                  this.imageurl = `/public/caregiver_picture/${res.data.coverImage}`
                  this.image = `${this.baseUrl}/public/caregiver_picture/${res.data.coverImage}`
                  console.log("image", this.imageurl, this.image)
              }
              this.addQuiz.patchValue({
                quizTitle: res.data.quizTitle,
                quizContent: res.data.quizContent,
                uri: res.data.uri
              })
          } else {
              this.toastr.error(res.message)
          }

      })

  }
}
