import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CmsService } from 'src/app/services/cms-service/cms.service';

@Component({
  selector: 'app-edit-faq',
  templateUrl: './edit-faq.component.html',
  styleUrls: ['./edit-faq.component.scss']
})
export class EditFaqComponent implements OnInit {
  editFaqFormGroup!: FormGroup | any;
  _id: any = '';
  title = 'angular';
  constructor(private actRoute: ActivatedRoute,
    private fb: FormBuilder,
    private cmsService: CmsService,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
    this.editFaqFormGroup = this.fb.group({
      question: [''],
      answer: ['']
    })
    this.getFaqInfo();

  }

  get f() {
    return this.editFaqFormGroup.controls;
  }

  onSubmit() {
    let data = {
      id: this.actRoute.snapshot.paramMap.get('id'),
      question: this.f.question.value,
      answer: this.f.answer.value
    }
    this.cmsService.editFAQ(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.toastr.success(res.message, res.status);
        this.router.navigateByUrl("/faq");
      } else {
        this.toastr.error(res.message, "Error")
      }
    });
  }

  getFaqInfo() {
    let data = {
      id: this.actRoute.snapshot.paramMap.get('id')
    }
    console.log("data", data)
    this.cmsService.faqDetails(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.editFaqFormGroup.patchValue({
          question: res.data.question,
          answer: res.data.answer
        })
      } else {
        this.toastr.error(res.message)
      }

    })

  }

}
