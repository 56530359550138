import { Injectable, Injector } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse
} from "@angular/common/http";
//import { LoginService } from "src/app/shared/services/login/login.service";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { Router } from "@angular/router";


@Injectable({
    providedIn: "root"
})
export class HttpResponseIntercepterService implements HttpInterceptor {
    constructor(
        private injector: Injector,
        private router: Router,

    ) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        const authRequest = request.clone();
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {

                        if (event.body != null) {
                            if (event.body.code === 401) {
                                delete localStorage.admin;
                                delete localStorage.isLoggedin;
                                // this.authService.logout();
                                localStorage.clear();
                                this.router.navigate(['/login']);
                            }
                        }
                    }
                },
                (err: any) => {

                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            delete localStorage.admin;
                            delete localStorage.isLoggedin;
                            this.router.navigate(["/login"]);
                            localStorage.clear();
                            //location.reload();
                        }
                    }
                }
            )
        );
    }
}
