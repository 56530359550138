<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <section class="content px-0 py-2">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h3><b>Add Stages</b> </h3>
                        </div>
                    </div>
                    <div class="card shadow border-0 mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-lg-3 text-center mt-3">
                                    <div class="profile_pic">
                                        <img *ngIf="!image" src="../../../../assets/images/user_preg.jpeg" width="170"
                                            height="170" class="rounded m-auto " />
                                        <img *ngIf="image" [src]="image" width="170" height="170"
                                            class="rounded m-auto  " />
                                    </div>

                                    <div class="m-width-50">
                                        <div class="btn upload_btn btn-black btn-block form-group text-white"><i
                                                class="fa fa-upload" aria-hidden="true"></i>
                                            <input class="form-control form-control-lg" 
                                                id="formFileLg" type="file" />
                                            Upload
                                        </div>

                                      
                                    </div>

                                </div>

                                <div class="col-12 col-md-9">
                                    <div class="container-fluid">
                                        <form [formGroup]="addStages" (submit)="onSubmit()">
                                            <div class="row">
                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Title</span>
                                                    <input type="text" class="form-control" formControlName="blogTitle"
                                                        placeholder="Blog Title">
                                                </div>

                                                <div class="col-12 col-lg-12 mb-3">
                                                    <span>Stages Content</span>
                                                    <!-- <input type="text" class="form-control" formControlName="blogContent"
                                                        placeholder="Blog Content"> -->
                                                    <ckeditor [editor]="Editor" formControlName="blogContent">
                                                    </ckeditor>
                                                    <!-- <textarea formControlName="blogContent" class="form-control" rows="10" cols="30"></textarea> -->
                                                </div>
                                            </div>
                                            <button class="btn btn-primary dark ">
                                                <i class="fas fa-plus"></i>Add</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
