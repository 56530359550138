import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastrService } from "ngx-toastr";
import { MindfullnessService } from "src/app/services/mindfull/mindfullness.service";

@Component({
  selector: "app-add-mindfullness-session",
  templateUrl: "./add-mindfullness-session.component.html",
  styleUrls: ["./add-mindfullness-session.component.scss"],
})
export class AddMindfullnessSessionComponent implements OnInit {
  addMindfullFormGroup!: FormGroup | any;
  public Editor = ClassicEditor;
  fileData: any = false;
  default: boolean = false;
  image: any = "";
  userId: any = "";

  constructor(
    private fb: FormBuilder,
    private mindfullnessService: MindfullnessService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userId =
      localStorage.getItem("id") != null ? localStorage.getItem("id") : "";

    this.addMindfullFormGroup = this.fb.group({
      title: [""],
      description: [""],
      image: [""],
      link: [""],
      startDate: [""],
      startTime: [""],
      duration: [""],
      caregiver: [""],
    });
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    console.log(this.fileData, "image_path");

    this.addMindfullFormGroup.get("image").setValue(fileInput.target.files[0]);
    this.default = true;
    this.preview();
  }

  preview() {
    // Show preview
    var mimeType = this.fileData.type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.image = reader.result;
      console.log(_event, "eventtt");
    };
  }
  removeImage() {
    this.image = "";
    this.f.image.value = this.image;
  }

  get f() {
    return this.addMindfullFormGroup.controls;
  }

  onSubmit() {
    const formData = new FormData();
    formData.append("image", this.f.image.value);
    formData.append("title", this.f.title.value);
    formData.append("description", this.f.description.value);
    formData.append("link", this.f.link.value);
    formData.append("startDate", this.f.startDate.value);
    formData.append("startTime", this.f.startTime.value);
    formData.append("duration", this.f.duration.value);
    formData.append("caregiver", this.userId);

    this.mindfullnessService
      .addMindfullnessSession(formData)
      .subscribe((res: any) => {
        if (res.messageID == 200 && res.data) {
          this.toastr.success(res.message, "Success");
          this.router.navigateByUrl("/mindfullnesslist");
        } else {
          this.toastr.error(res.message, "Error");
        }
      });
  }
}
