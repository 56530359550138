import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  submitted = false;

  constructor(
    private authService: AuthServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {

    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (isLoggedIn) {
      this.router.navigateByUrl('/dashboard');
    }
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }
  get f() {
    return this.loginForm.controls;
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return
    } else {
      let data = {
        email: this.f.email.value,
        password: this.f.password.value,
        role: 1
      }
      this.authService.login(data).subscribe((res: any) => {
        if (res.messageID == 200 && res.data) {
          this.toastr.success(res.message, "Success");
          localStorage.setItem("Name", res.data.name)
          localStorage.setItem("refreshToken", res.data.refreshToken)
          localStorage.setItem("Token", res.data.token)
          localStorage.setItem("Role", res.data.role)
          localStorage.setItem("_id", res.data._id)
          localStorage.setItem("isLoggedIn", "true");
          this.router.navigateByUrl("/dashboard");
        } else {
          console.log("message", res)
          this.toastr.error(res.message, "Errror");
        }

      })
    }
  }


}
