import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserServiceService } from 'src/app/services/user/user-service.service';
import { CaregiverServiceService } from 'src/app/services/caregiver/caregiver-service.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  page: number = 1;
  limit: number = 6;
  filter: any = '';
  userlist: any = [];
  isBlocked: boolean = false;
  user_id: any;
  userName: any = '';
  status: any = '';
  totaldocs: any = 0;

  constructor(private userService: UserServiceService, private toastr: ToastrService ,private caregiverService: CaregiverServiceService) { }

  originalUserlist: any = []; // orginallist

  ngOnInit(): void {
    let data = {
      filter: this.filter,
      status: this.status,
      page: this.page,
      limit: this.limit
    };
    this.usersList(data);

 

  }

 usersList(data: any) {
    this.userService.userList(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.toastr.success(res.message)
        this.userlist = res.data.docs;
        console.log("--->>>>>>>>",this.userlist)
        this.originalUserlist = [...res.data.docs]; // Store the original user list
        this.totaldocs = res.data.totalDocs;
      } else {
        this.toastr.error("Data Not found")
      }
    })
  }
  
  getUserStatus(data: any) {
    console.log("data", data)
    this.userName = data.firstName + " " + data.lastName;

    this.user_id = data._id;
    this.isBlocked = data.isBlocked;
    if (this.isBlocked == true) {
      this.isBlocked = false
    } else {
      this.isBlocked = true;
    }
  }


  blockCaregiver() {
    let data = {
      _id: this.user_id,
      isBlocked: this.isBlocked
    };

    let dataa = {
      filter: this.filter,
      status: this.status,
      page: this.page,
      limit: this.limit
    };

    this.caregiverService.blockCaregiver(data).subscribe((data: any) => {
      this.toastr.success(data.message, data.status)
      let element: HTMLElement = document.getElementById('closeUserBlockModal') as HTMLElement;
      element.click();
      this.usersList(dataa);
    })
  }


  pageChange(page: any) {
    let data = {
      filter: this.filter,
      status: this.status,
      page: page,
      limit: this.limit
    };
    this.userService.userList(data).subscribe((res: any) => {
      this.userlist = res.data.docs;
      if (res.status === "Success" && res.messageID === 200) {
        this.totaldocs = res.data.totalDocs;
        this.page = page
      }
    });
  }

  // search by first name whith accending order 

  searchUser(event: any) {
    const searchTerm = event.target.value.trim().toLowerCase(); 
  
    // Check if the search input is empty or only contains whitespace
    if (!searchTerm || searchTerm.length === 0) {
      // If the search input is empty, restore the original user list
      this.userlist = [...this.originalUserlist];
      return;
    }
  
    // Perform a new search request to fetch users that match the search criteria
    let data = {
      filter: searchTerm, // Use the search term as the filter
      status: this.status,
      page: 1, 
      limit: 1000 // Set a high limit to fetch all users (adjust as needed)
    };
  
    this.userService.userList(data).subscribe((res: any) => {
      if (res.messageID == 200 && res.data) {
        this.toastr.success(res.message);
  
        // Combine users from all pages into a single array
        const allUsers = res.data.docs;
  
        // Filter users based on the search term 
        const filteredUsers = allUsers.filter((user: any) =>
          user.firstName.toLowerCase().startsWith(searchTerm)
        );
  
        // Sort the filtered users in ascending order based on first name
        filteredUsers.sort((a: any, b: any) => {
          const firstNameA = a.firstName.toLowerCase();
          const firstNameB = b.firstName.toLowerCase();
          return firstNameA.localeCompare(firstNameB);
        });
  
        this.userlist = filteredUsers;
      } else {
        this.toastr.error("Data Not found");
      }
    });
  }

  selectOption(value: any) {
    this.status = (value.target as HTMLInputElement).value;
    let data = {
      filter: this.filter,
      status: this.status,
      page: this.page,
      limit: this.limit
    };
    this.usersList(data);
  }

  blockUser() {
    let dataa = {
      filter: this.filter,
      status: this.status,
      page: this.page,
      limit: this.limit
    };
    let data = {
      _id: this.user_id,
      isBlocked: this.isBlocked
    };

    this.userService.blockUser(data).subscribe((data: any) => {
      this.toastr.success(data.message, data.status)
      let element: HTMLElement = document.getElementById('closeUserBlockModal') as HTMLElement;
      element.click();
      this.usersList(dataa);
    })
  }
  closeModal() {
    let data = {
      filter: this.filter,
      status: this.status,
      page: this.page,
      limit: this.limit
    };
    this.usersList(data);
  }

  deleteUser() {
    let dataa = {
      filter: this.filter,
      status: this.status,
      page: this.page,
      limit: this.limit
    };
    let data = {
      _id: this.user_id,
      isDelete: true
    };

    this.userService.deteleUser(data).subscribe((data: any) => {
      this.toastr.success(data.message, data.status)
      let element: HTMLElement = document.getElementById('closeUserDeleteModal') as HTMLElement;
      element.click();
      this.usersList(dataa);
    })
  }
}
