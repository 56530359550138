<app-mainheader></app-mainheader>
<app-admin-sidebar></app-admin-sidebar>
<main id="main" class="main">
    <div class="container-fluid mb-4">
        <div class="row">
            <div class="col-12 col-md-5 col-lg-3 text-start">
                <h4 class="fs-28 fw-bold">Baby-Stages
                </h4>
            </div>
          
            <!-- <div class="col-12 col-md-7 col-lg-3 text-end">
                <div class="input-group"> <input type="text" class="form-control border-end-0 form-control-lg"
                        placeholder="Search" style="font-size: 13px" /> <span
                        class="input-group-text bg-white border-start-0"><i class="bi bi-search curser"></i> </span>
                </div>
            </div> -->
            <!-- <div class="col-12 d-flex justify-content-end">
                <button [routerLink]="['/add-stages']" class="btn btn-primary dark">
                    <i class="fas fa-plus"></i> Add Content
                </button>
            </div> -->
            
        </div>
    </div>
    <div class="card border-0 shadow">
        <div class="card-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 table-responsive">
                        <table class="table">
                            <thead class="d-lg-block-thead">
                                <tr>
                                    <th>  Title
                                    </th>
                                    <th>
                                        week
                                    </th>
                                    <th> Created At
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="babyStagesLists.length > 0; else nodata">
                                    <tr *ngFor="let babyStages of babyStagesLists | paginate
                                    : {itemsPerPage: limit,currentPage: page,id: 'second',totalItems: totaldocs}">
                                        <td data-label=" First Name">{{babyStages.heading}}</td>
                                        <td data-label="week">{{babyStages.week}}</td>
                                        <td data-label="createdAt">{{ formatDate(babyStages.createdAt)}}</td>
                                        <td data-label="Action">
                                            <p class="d-flex justify-content-end justify-content-lg-start">
                                                <button class="btn btn-primary rounded-circle btn-rounded shadow me-2"
                                                    routerLink="/edit-stages/{{babyStages.week}}">
                                                    <i class="bi bi-pencil" title="Edit"></i>
                                                </button>
                                             
                                                <!-- <button
                                                    class="btn btn-danger rounded-circle btn-rounded shadow me-2 text-white">
                                                    <i class="bi bi-trash" title="Delete"
                                                       data-target="#delete"
                                                        data-toggle="modal"></i>
                                                </button> -->
                                            </p>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-template #nodata>
                                    <tr>
                                        <td colspan="6" class="text-center">No Data Found</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                        <!-- <div class="paginator" style="float: right;">
                            <pagination-controls  id="second"></pagination-controls>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Delete Modal -->
    <div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"><b>Confirmation</b></h5>
                    <button type="button"  id="closeUserDeleteModal" class="close"
                        data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    Are you sure you want to Delete?
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary "
                        data-dismiss="modal">No</button>
                    <button type="button" class="btn btn-primary">Yes</button>
                </div>
            </div>
        </div>
    </div>
</main>
